@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.template-disable-popover {
  width: 230px !important;
  inset: 180px auto auto 240px !important;
  .ant-popover-inner-content {
    text-align: center !important;
    font-size: 14px !important;
    padding: 0 8px !important;
  }
}

.archi {
  .react-switch-Undragable {
    pointer-events: none;
  }
  position: fixed;
  width: 232px;
  left: 0;
  top: 48px;
  margin: 12px;
  height: calc(var(--vh, 1vh) * 100 - 48px - 24px);
  background: $primary_white;
  border: 1px solid $primary_grey;
  border-radius: 8px;
  z-index: 1;
  &__container {
    height: 100%;
    @include flexcolumn;
  }
  &__container.disable {
    cursor: not-allowed;
    opacity: 0.2;
    pointer-events: none;
  }
  &__sections {
    height: calc(100% - 40px);
    @include flexcolumn;
    justify-content: space-between;
    padding: 20px 0px 20px 0px;
  }
  &__section {
    padding: 0 15px;
    overflow: auto;
    height: 100%;
    @include flexcolumn;
    &__main-new {
      height: auto;
      display: flex;
      flex-direction: column;
    }
    &__main {
      width: 100%;
      @include flexColumnACenter;
      position: relative;
      gap: 8px;
      &__templates {
        position: relative;
        &__loading {
          width: 100%;
          position: absolute;
          height: 90px;
          top: 0px;
          background: #ffffffa6;
          @include flexCenter;
          img {
            width: 60px;
          }
        }
      }
      &__smart-prompt {
        padding: 0 0 12px 0;
        margin: 0 0 12px 0;
        border-bottom: 1px solid $primary_grey;
        &__container {
          padding: 10px;
          background: $bg_grey_backround;
          border: 1px solid $primary_grey;
          border-radius: 8px;
        }
        &__heading {
          @include flexAcenter;
          justify-content: space-between;
          cursor: pointer;
          &.disable {
            cursor: default;
          }
          &__left {
            @include flexAcenter;
            &.disable {
              opacity: 0.4;
            }
            h4 {
              font-size: 14px;
              font-weight: 500;
              text-align: start;
            }
            .tooltip-top {
              @include flexAcenter;
              margin: 0 4px;
              img {
                width: 14px;
                height: 14px;
              }
            }
            span {
              font-size: 12px;
              line-height: 16px;
              padding: 0 4px;
              border-radius: 4px;
              color: $primary_white;
              background: #f4c135;
            }
          }
          &__right {
            width: auto;
            display: flex;
            align-items: center;
            .small-on {
              .react-switch-handle {
                transform: translate(15px) !important;
              }
            }
            .small-off {
              .react-switch-handle {
                transform: translate(2px) !important;
              }
            }
          }
        }
        &__loading {
          width: calc(100% - 18px);
          min-height: 68px;
          padding: 12px 8px;
          margin: 10px 0;
          border: 1px solid $primary_grey;
          background: $primary_white;
          border-radius: 4px;
          &__box {
            @include flexColumnACenter;
            gap: 8px;
            img {
              width: 36px;
            }
            p {
              font-size: 12px;
              line-height: 16px;
              font-weight: 400;
              text-align: center;
            }
          }
        }
        &__textarea {
          padding: 10px 0;
          position: relative;
          img {
            position: absolute;
            width: 50px;
            height: 20px;
            margin: 8px 0 0 12px;
          }
          &:focus {
            img {
              display: none;
            }
          }
          textarea {
            width: calc(100% - 20px);
            border: 1px solid $primary_grey;
            padding: 12px 9px;
            border-radius: 8px;
            outline: none;
            min-height: 88px;
            max-height: 160px;
            &:focus {
              width: calc(100% - 26px);
              padding: 12px !important;
            }
            &.empty {
              padding-top: 32px;
            }
          }
          p {
            font-size: 12px;
            padding: 4px 0 0 0;
            // text-align: end;
            span {
              font-size: 12px;
            }
          }
        }
        &__buttons {
          @include flexbetween;
          align-items: center;
          gap: 12px;
          &__left {
            width: 24px;
            height: 24px;
            cursor: pointer;
            img {
              width: 24px;
              height: 24px;
            }
            &.disable {
              cursor: default;
              opacity: 0.5;
            }
          }
          &__right {
            width: -webkit-fill-available;
            button {
              cursor: pointer;
              width: 100%;
              font-size: 13px;
              color: $primary_white;
              font-weight: 500;
              height: 24px;
              background: $primary_blue;
              border: 1px solid $primary_grey;
              border-radius: 28px;
              padding: 0;
              &:hover {
                opacity: 0.7;
              }
              &.disabled {
                background: $grey_input;
                cursor: default;
                &:hover {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
      &__heading {
        width: 100%;
        @include flexColumnACenter;
        padding: 0 0 12px 0;
        h4 {
          font-size: 16px;
          font-weight: 500;
          text-align: start;
          padding: 0 4px 0 0;
        }
      }
      &__sub {
        width: 100%;
        // @include flexwrap;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 6px;
        justify-content: space-between;
        margin: 0 0 8px 0;
        &__main {
          width: 100%;
        }
        &__heading.off {
          opacity: 0.4;
        }
        &__heading {
          display: flex;
          justify-content: space-between;
          margin: 0 0 12px 0;
          cursor: pointer;
          &__left {
            display: flex;
            h4 {
              font-size: 14px;
              font-weight: 500;
              text-align: start;
              padding: 0 4px 0 0;
            }
            .tooltip-top {
              img {
                width: 14px;
                height: 14px;
              }
            }
          }
          &__right {
            display: flex;
            align-items: center;
            .small-on {
              .react-switch-handle {
                transform: translate(15px) !important;
              }
            }
            .small-off {
              .react-switch-handle {
                transform: translate(2px) !important;
              }
            }
          }
        }
        &__sec {
          height: 46px;
          @include flexCenter;
          background: $bg_grey_backround;
          border-radius: 8px;
          border: 1px solid $primary_grey;
          opacity: 0.6;
          cursor: pointer;
          img {
            width: 18px;
          }
          a {
            font-size: 14px;
            padding: 0 0 0 8px;
            color: $primary_black;
            font-weight: 400;
            text-decoration: none;
          }
        }
        &__sec.active {
          height: 44px;
          opacity: 1;
          border: 1px solid black;
        }
        &__sec:hover {
          a {
            color: $primary_black;
          }
        }
      }
      &__image {
        @include flexCenter;
        width: 100%;
        position: relative;
        img {
          width: 200px;
          height: 88px;
          object-fit: cover;
          border-radius: 8px;
          border: 1px solid #ededed;
        }
        .icon {
          position: absolute;
          width: 24px;
          height: auto;
          border: none;
        }
      }
      &__content {
        width: 100%;
        height: 100%;
        position: absolute;
        @include flexcolumn;
        justify-content: flex-end;
        align-items: center;
        // padding: 0 0 0 16px;
        &__sec {
          padding: 0 0 8px 0;
          span {
            padding: 4px 8px;
            font-size: 12px;
            font-weight: 400;
            color: $primary_white;
            background: #00000040;
            border-radius: 4px;
          }
        }
      }
      &__sec1 {
        width: 100%;
        @include flexcolumn;
        gap: 8px;
      }
      &__camera {
        display: flex;
        &__heading {
          border-radius: 8px;
          width: 50%;
          @include flexAcenter;
          h4 {
            font-size: 12px;
            font-weight: 500;
            text-align: start;
          }
          .tooltip-top {
            @include flexAcenter;
            img {
              width: 14px;
              padding: 0 0 0 6px;
            }
            .tooltip-show {
              display: none;
            }
          }
          .tooltip-top:hover {
            .tooltip-show {
              display: block;
            }
          }
        }
        &__section {
          width: 50%;
          .options {
            font-size: 14px;
            color: black;
          }
          select {
            width: 100%;
          }
          .css-b62m3t-container {
            outline: none;
          }
          .css-13cymwt-control {
            border-radius: 8px;
            background: $bg_light_grey;
            border: none;
            cursor: pointer;
          }
          .css-t3ipsp-control {
            border: none !important;
            border-color: $primary_white !important;
            background-color: $bg_light_grey;
            box-shadow: none !important;
          }
          .css-1u9des2-indicatorSeparator {
            display: none !important;
          }
          .select-option {
            span {
              width: 100% !important;
              text-overflow: ellipsis;
            }
          }
          .css-1v50njv-singleValue {
            span {
              width: 100% !important;
              text-overflow: ellipsis;
            }
          }
        }
      }
      &__atmosphere {
        display: flex;
        margin: 0 0 12px 0;
        &__heading {
          width: 50%;
          @include flexrow;
          align-items: center;
          h4 {
            font-size: 12px;
            font-weight: 500;
            text-align: start;
          }
          .tooltip-top {
            @include flexAcenter;
            img {
              width: 14px;
              padding: 0 0 0 6px;
              opacity: 1;
            }
            .tooltip-show {
              display: none;
            }
          }
          .tooltip-top:hover {
            .tooltip-show {
              display: block;
            }
          }
        }
        &__sec {
          width: 50%;
          &__sub {
            &__section {
              &__atom {
                @include flexAcenter;
                width: calc(100% - 18px);
                background: $bg_grey_backround;
                padding: 8px;
                border-radius: 8px;
                border: 1px solid black;
                cursor: pointer;
                img {
                  width: 18px;
                  height: auto;
                }
                span {
                  font-size: 12px;
                  padding: 0 0 0 8px;
                }
              }
            }
          }
        }
      }
      &__atmosphere1 {
        width: 100%;
        @include flexColumnACenter;
        margin: 0 0 12px 0;
        gap: 8px;
        .off {
          opacity: 0.4;
        }
        &__heading {
          width: 100%;
          @include flexAcenter;
          justify-content: space-between;
          cursor: pointer;
          &__left {
            display: flex;
            align-items: center;
            width: 80%;
            h4 {
              font-size: 14px;
              font-weight: 500;
              text-align: start;
            }
            .tooltip-top {
              @include flexAcenter;
              img {
                width: 14px;
                padding: 0 0 0 6px;
                opacity: 1;
              }
              .tooltip-show {
                display: none;
              }
            }
            .tooltip-top:hover {
              .tooltip-show {
                display: block;
              }
            }
          }
          &__right {
            display: flex;
            align-items: center;
            .small-on {
              .react-switch-handle {
                transform: translate(15px) !important;
              }
            }
            .small-off {
              .react-switch-handle {
                transform: translate(2px) !important;
              }
            }
          }
        }
        &__sec {
          width: 100%;
          &__sub {
            &__section {
              &__atom {
                @include flexAcenter;
                width: calc(100% - 18px);
                background: $bg_grey_backround;
                padding: 8px;
                gap: 10px;
                border-radius: 8px;
                border: 1px solid black;
                cursor: pointer;
                img {
                  width: 18px;
                  height: auto;
                }
                span {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
      &__graphic {
        display: flex;
        &__heading {
          width: 50%;
          @include flexrowacenter;
          h4 {
            font-size: 12px;
            font-weight: 500;
            text-align: start;
          }
          .tooltip-top {
            @include flexAcenter;
            img {
              width: 14px;
              padding: 0 0 0 6px;
              opacity: 1;
            }
            .tooltip-show {
              display: none;
            }
          }
          .tooltip-top:hover {
            .tooltip-show {
              display: block;
            }
          }
        }
        &__section {
          width: 50%;
          .options {
            font-size: 14px;
            color: black;
          }
          select {
            width: 100%;
          }
          .css-b62m3t-container {
            outline: none;
          }
          .css-13cymwt-control {
            border-radius: 8px;
            background: $bg_light_grey;
            border: none;
            cursor: pointer;
          }
          .css-t3ipsp-control {
            border: none !important;
            border-color: $primary_white !important;
            background-color: $bg_light_grey;
            box-shadow: none !important;
          }
          .css-1u9des2-indicatorSeparator {
            display: none !important;
          }
          .select-option {
            span {
              width: 100% !important;
              text-overflow: ellipsis;
            }
          }
          .css-1v50njv-singleValue {
            span {
              width: 100% !important;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
    &__atmosphere {
      span {
        width: 100%;
      }
      width: 100%;
      @include flexColumnACenter;
      border-top: 1px solid $secondary_grey;
      .off {
        opacity: 0.4;
      }
      &__heading {
        width: 100%;
        @include flexAcenter;
        justify-content: space-between;
        margin: 12px 0 12px 0;
        cursor: pointer;
        &__left {
          @include flexAcenter;
          width: 80%;
          h4 {
            font-size: 14px;
            font-weight: 500;
            text-align: start;
          }
          .tooltip-top {
            @include flexAcenter;
            img {
              width: 14px;
              padding: 0 0 0 6px;
              opacity: 1;
            }
            .tooltip-show {
              display: none;
            }
          }
          .tooltip-top:hover {
            .tooltip-show {
              display: block;
            }
          }
        }
        &__right {
          width: auto;
          display: flex;
          align-items: center;
          .small-on {
            .react-switch-handle {
              transform: translate(15px) !important;
            }
          }
          .small-off {
            .react-switch-handle {
              transform: translate(2px) !important;
            }
          }
        }
      }
      &__image {
        width: 100%;
        @include flexbetween;
        align-items: center;
        padding: 0 0 8px 0;
        &__base {
          width: 50%;
          .card {
            min-width: 96px;
            max-width: 100%;
            height: 96px;
            border-radius: $font_xverysmall;
            border: 1px solid $primary_grey;
            background: $bg_grey_backround;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }

          label {
            width: 100%;
            height: 100%;
            @include flexCenter;
            overflow: hidden;
            cursor: pointer;
          }

          .card-content {
            position: relative;
            @include flexCenter;
            flex-direction: column;
            width: 100%;
            height: 100%;
            gap: 6px;
            height: -webkit-fill-available;
            .ref-delete {
              position: absolute;
              width: 24px;
              height: 24px;
              right: 5px;
              top: 5px;
              // border-radius: 50%;
              // background: $primary_white;
              @include flexCenter;
              img {
                opacity: 1;
                width: 24px;
                height: 24px;
              }
            }
            img {
              display: block;
              width: 100%;
              height: auto;
              border-radius: $font_xverysmall;
            }
            .icon {
              width: 24px;
              height: 24px;
            }
            .gradient-mask {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: linear-gradient(to top, #000000cc, #00000000);
              backdrop-filter: blur(5px);
              pointer-events: none;
            }

            .text-display {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-size: 12px;
              color: white;
              &.fr {
                word-break: break-all;
              }
            }
            p {
              font-size: 12px;
              line-height: 16px;
              font-weight: 400;
              color: $onhover_grey;
              padding: 0 12px;
              text-align: center;
            }
            button {
              font-size: 12px;
              cursor: pointer;
              border-radius: 8px;
              border: 1px solid $primary_blue;
              height: 20px;
            }
          }

          .card-content img {
            opacity: 0.4;
          }

          .uploaded {
            width: 100%;
            opacity: 1 !important;
            overflow: hidden;
            object-fit: contain;
          }

          .card-content span {
            margin-top: 10px;
            font-size: 18px;
          }
        }
        &__weight {
          span {
            width: auto;
          }
          width: 45%;
          padding: 4px 0 0 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          .ant-slider {
            width: 100% !important;
          }
          &__sec {
            display: flex;
            justify-content: center;
            width: 50%;
            padding: 0 0 0 4px;
            &__sub {
              width: 100%;
              @include textc;
              .ant-slider-horizontal {
                margin: 10px 5px 0 5px !important;
              }
            }
            .span {
              width: 100%;
              display: flex;
              justify-content: center;
              opacity: 0.6;
              font-size: 12px;
            }
          }
        }
      }
      &__image:last-child {
        padding: 0 0 12px 0;
      }
      &__images {
        display: flex;
        gap: 8px;
      }
      &__image1 {
        width: 100%;
        @include flexcolumn;
        padding: 0 0 8px 0;
        &__base {
          width: 100%;
          .card {
            min-width: 96px;
            max-width: 100%;
            height: 96px;
            border-radius: $font_xverysmall;
            border: 1px solid $primary_grey;
            background: $bg_grey_backround;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }

          label {
            width: 100%;
            height: 100%;
            @include flexCenter;
            overflow: hidden;
            cursor: pointer;
          }

          .card-content {
            position: relative;
            @include flexCenter;
            flex-direction: column;
            width: 100%;
            height: 100%;
            gap: 6px;
            height: -webkit-fill-available;
            .ref-delete {
              position: absolute;
              width: 24px;
              height: 24px;
              right: 5px;
              top: 5px;
              // border-radius: 50%;
              // background: $primary_white;
              @include flexCenter;
              img {
                opacity: 1;
                width: 24px;
                height: 24px;
              }
            }
            img {
              display: block;
              width: 100%;
              height: auto;
              border-radius: $font_xverysmall;
            }
            .icon {
              width: 24px;
              height: 24px;
            }
            .gradient-mask {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: linear-gradient(to top, #000000cc, #00000000);
              backdrop-filter: blur(5px);
              pointer-events: none;
            }

            .text-display {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-size: 12px;
              color: white;
              &.fr {
                word-break: break-all;
              }
            }
            p {
              font-size: 12px;
              line-height: 16px;
              font-weight: 400;
              color: $onhover_grey;
              padding: 0 12px;
              text-align: center;
            }
            button {
              font-size: 12px;
              cursor: pointer;
              border-radius: 8px;
              border: 1px solid $primary_blue;
              height: 20px;
            }
          }

          .card-content img {
            opacity: 0.4;
          }

          .uploaded {
            width: 100%;
            opacity: 1 !important;
            overflow: hidden;
            object-fit: contain;
          }

          .card-content span {
            margin-top: 10px;
            font-size: 18px;
          }
        }
        &__weight {
          span {
            width: auto;
          }
          width: 100%;
          padding: 4px 0 0 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          .ant-slider {
            width: 90% !important;
          }
          &__sec {
            display: flex;
            justify-content: center;
            width: 50%;
            padding: 0 0 0 4px;
            &__sub {
              width: 100%;
              @include textc;
              .ant-slider-horizontal {
                margin: 10px 5px 0 5px !important;
              }
            }
            .span {
              width: 100%;
              display: flex;
              justify-content: center;
              opacity: 0.6;
              font-size: 12px;
            }
          }
        }
      }
    }
    &__main1 {
      width: 100%;
      @include flexColumnACenter;
      // border-top: 1px solid $secondary_grey;
      .off {
        opacity: 0.4;
      }
      &__heading {
        width: 100%;
        @include flexAcenter;
        justify-content: space-between;
        margin: 12px 0 12px 0;
        &.disable {
          opacity: 0.4;
        }
        cursor: pointer;
        &__left {
          display: flex;
          align-items: center;
          width: 80%;
          h4 {
            font-size: 14px;
            font-weight: 500;
            text-align: start;
          }
          .tooltip-top {
            @include flexAcenter;
            img {
              width: 14px;
              padding: 0 0 0 6px;
              opacity: 1;
            }
            .tooltip-show {
              display: none;
            }
          }
          .tooltip-top:hover {
            .tooltip-show {
              display: block;
            }
          }
        }
        &__right {
          display: flex;
          align-items: center;
          .small-on {
            .react-switch-handle {
              transform: translate(15px) !important;
            }
          }
          .small-off {
            .react-switch-handle {
              transform: translate(2px) !important;
            }
          }
        }
      }
      &__input {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        overflow: auto;
        padding: 0 0 12px 0;
        // height: 200px;
        textarea {
          border: 1px solid $primary_grey;
          padding: 12px;
          border-radius: 8px;
          outline: none;
          min-height: 130px;
          max-height: 160px;
        }
        .auto-resize-textarea {
          resize: none;

          &.auto-resize {
            height: auto;
            overflow: auto;
          }
        }
        p {
          font-size: 12px;
          padding: 4px 0 0 0;
          text-align: end;
          span {
            font-size: 12px;
          }
        }
      }
    }
    &__main3 {
      width: 100%;
      @include flexColumnACenter;
      border-top: 1px solid $secondary_grey;
      .off {
        opacity: 0.4;
      }
      &__heading {
        width: 100%;
        @include flexAcenter;
        justify-content: space-between;
        margin: 12px 0 8px 0;
        cursor: pointer;
        &__left {
          display: flex;
          align-items: center;
          width: 80%;
          h4 {
            font-size: 14px;
            font-weight: 500;
            text-align: start;
          }
          .tooltip-top {
            @include flexAcenter;
            img {
              width: 14px;
              padding: 0 0 0 6px;
              opacity: 1;
            }
            .tooltip-show {
              display: none;
            }
          }
          .tooltip-top:hover {
            .tooltip-show {
              display: block;
            }
          }
        }
        &__right {
          display: flex;
          align-items: center;
          .small-on {
            .react-switch-handle {
              transform: translate(15px) !important;
            }
          }
          .small-off {
            .react-switch-handle {
              transform: translate(2px) !important;
            }
          }
        }
      }
      &__options {
        display: flex;
        width: 100%;
        &__container {
          width: 100%;
          @include flexrow;
          .css-b62m3t-container {
            width: 100%;
          }
          .css-13cymwt-control {
            background: $bg_grey_backround !important;
            border-radius: 8px !important;
            border: 1px solid $primary_grey !important;
          }
          .css-t3ipsp-control {
            background: $bg_grey_backround !important;
            border: 1px solid $primary_grey !important;
            box-shadow: none;
          }
          .css-t3ipsp-control:hover {
            background: $bg_grey_backround !important;
            border: 1px solid $primary_grey !important;
            box-shadow: none;
          }
          .css-1iu1djs-singleValue {
            background: $bg_grey_backround !important;
            font-size: 14px !important;
          }
          .input-value {
            text-align: center;
            width: 43px;
            height: 38px;
            background: $bg_grey_backround;
            border: 1px solid $primary_grey;
            @include flexCenter;
            border-radius: 8px;
            margin: 0 0 0 4px;
            padding: 0;
          }
          input:focus {
            outline: none;
          }
          h5 {
            opacity: 0.6;
            width: 43px;
            height: 38px;
            background: $bg_grey_backround;
            border: 1px solid $primary_grey;
            @include flexCenter;
            border-radius: 8px;
            margin: 0 0 0 4px;
          }
        }
      }
    }
  }

  // ::-webkit-scrollbar-thumb {
  //   background: #ffffff;
  // }
  // ::-webkit-scrollbar-thumb:hover {
  //   background: #ffffff;
  // }
}

@media screen and (min-width: 768px) and (max-width: 1090px) {
  .archi {
    height: calc(var(--vh, 1vh) * 100 - 36px - 24px);
    .react-switch-Undragable {
      pointer-events: none;
    }
    width: 156px;
    top: 36px;
    border-radius: 6px;
    &__sections {
      height: calc(100% - 24px);
      padding: 12px 0px 12px 0px;
    }
    &__section {
      padding: 0 8px 0 12px;
      &__main {
        gap: 4px;
        &__sec1 {
          gap: 4px;
        }
        &__sub {
          gap: 4px;
          &__heading {
            margin: 0 0 4px 0;
          }
        }
        &__smart-prompt {
          padding: 0 0 8px 0;
          margin: 0 0 8px 0;
          &__heading {
            h4 {
              font-size: 13px;
              line-height: 16px;
            }
          }
          &__loading {
            min-height: 50px;
            max-height: 50px;
            overflow: hidden;
            &__box {
              p {
                width: 90px;
                overflow: hidden;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
          &__textarea {
            padding: 4px 0 0 0;
            textarea {
              min-height: 60px;
              max-height: 90px;
              &.empty:hover {
                background: none;
              }
            }
          }
          &__buttons {
            gap: 6px;
            &__left {
              width: 20px;
              height: 20px;
              img {
                width: 20px;
                height: 20px;
              }
            }
            &__right {
              button {
                font-size: 12px;
                height: 22px;
              }
            }
          }
        }
        &__heading {
          padding: 0 0 8px 0;
          h4 {
            font-size: 14px;
            padding: 0;
          }
        }
        &__sub {
          &__heading {
            h4 {
              font-size: 13px;
            }
            .tooltip-top {
              img {
                width: 12px;
                height: 12px;
              }
            }
          }
          &__sec {
            justify-content: center;
            height: 46px;
            padding: 0;
            img {
              width: 14px;
              height: 14px;
            }
            a {
              font-size: 12px;
              padding: 0 0 0 4px;
            }
          }
        }
        &__image {
          height: 60px;
          img {
            width: 132px;
            height: 60px;
            border-radius: 0;
          }
        }
        &__content {
          width: calc(100% - 12px);
          padding: 0 0 0 12px;
          &__sec {
            padding: 0 0 2px 0;
            border-radius: 6px;
            span {
              padding: 0px 6px;
              font-size: 10px;
              @include flexAcenter;
              width: max-content;
              height: 16px;
            }
          }
        }
        &__graphic {
          flex-direction: column;
          &__heading {
            width: 100%;
          }
          &__section {
            width: 100%;
          }
        }
        &__camera {
          flex-direction: column;
          &__heading {
            width: 100%;
          }
          &__section {
            width: 100%;
          }
        }
        &__atmosphere {
          flex-direction: column;
          margin: 0 0 8px 0;
          &__heading {
            width: 100%;
          }
          &__sec {
            width: 100%;
          }
        }
      }
      &__atmosphere {
        &__heading {
          margin: 8px 0 4px 0;
          &__left {
            h4 {
              font-size: 13px;
            }
          }
        }
        &__image {
          flex-direction: column;
          &__base {
            width: 100%;
            .card {
              height: 80px;
            }
            .card-content {
              justify-content: center;
            }
          }
          &__weight {
            width: 100%;
          }
          &:last-child {
            padding: 0 0 8px 0;
          }
        }
        &__images {
          gap: 4px;
        }
        &__image1 {
          flex-direction: column;
          &__base {
            width: 100%;
            .card {
              min-width: 64px;
              height: 70px;
            }
            .card-content {
              justify-content: center;
              gap: 4px;
              .icon {
                width: 20px;
                height: 20px;
              }
              p {
                padding: 0 0px;
                font-size: 10px;
                line-height: 14px;
              }
            }
          }
          &__weight {
            width: 100%;
          }
          &:last-child {
            padding: 0 0 8px 0;
          }
        }
      }
      &__main1 {
        &__heading {
          h4 {
            font-size: 13px;
          }
          .tooltip-top {
            img {
              width: 12px;
            }
          }
        }
        &__input {
          height: max-content;
          textarea {
            padding: 8px;
            max-width: 100%;
            min-width: 106px;
            min-height: 106px;
            font-size: 12px;
            border-radius: 6px;
          }
          p {
            font-size: 10px;
            span {
              font-size: 10px;
            }
          }
        }
      }
      &__main3 {
        &__heading {
          margin: 8px 0 4px 0;
          h4 {
            font-size: 13px;
          }
          .tooltip-top {
            img {
              width: 12px;
            }
          }
        }
        &__options {
          &__container {
            .css-1iu1djs-singleValue {
              font-size: 12px !important;
            }
            .css-13cymwt-control {
              min-height: 28px !important;
            }
            .css-qbdosj-Input {
              margin: 0 !important;
              padding: 0 !important;
            }
            h5 {
              font-size: 12px;
              width: 34px;
              height: 26px;
            }
            .input-value {
              font-size: 12px;
              width: 34px;
              height: 26px;
            }
            .css-t3ipsp-control {
              min-height: 28px !important;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 767px) {
  .archi {
    height: calc(var(--vh, 1vh) * 100 - 36px - 24px);
    .react-switch-Undragable {
      pointer-events: none;
    }
    width: 22vw;
    top: 36px;
    border-radius: 6px;
    &__sections {
      height: calc(100% - 24px);
      padding: 12px 0px 12px 0px;
    }
    &__section {
      padding: 0 8px 0 12px;
      &__main {
        gap: 4px;
        &__sec1 {
          gap: 4px;
        }
        &__sub {
          gap: 4px;
          &__heading {
            margin: 0 0 4px 0;
          }
        }
        &__smart-prompt {
          padding: 0 0 8px 0;
          margin: 0 0 8px 0;
          &__container {
            padding: 8px;
          }
          &__heading {
            h4 {
              font-size: 13px;
              line-height: 16px;
            }
          }
          &__loading {
            min-height: 50px;
            max-height: 50px;
            overflow: hidden;
            &__box {
              p {
                width: 90px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
          &__textarea {
            padding: 4px 0 0 0;
            textarea {
              min-height: 60px;
              max-height: 90px;
              &.empty:hover {
                background: none;
              }
            }
          }
          &__buttons {
            gap: 6px;
            &__left {
              width: 20px;
              height: 20px;
              img {
                width: 20px;
                height: 20px;
              }
            }
            &__right {
              button {
                font-size: 12px;
                height: 22px;
              }
            }
          }
        }
        &__heading {
          padding: 0 0 8px 0;
          h4 {
            font-size: 14px;
            padding: 0;
          }
        }
        &__sub {
          gap: 2px;
          &__heading {
            align-items: center;
            h4 {
              display: flex;
              align-items: center;
              font-size: 12px;
            }
            .tooltip-top {
              img {
                width: 12px;
                height: 12px;
              }
            }
          }
          &__sec {
            flex-direction: column;
            justify-content: center;
            padding: 0;
            img {
              width: 14px;
              height: 14px;
            }
            a {
              font-size: 12px;
              padding: 2px 0 0 0;
            }
          }
        }
        &__image {
          height: 60px;
          img {
            width: 100%;
            height: 60px;
            border-radius: 0;
          }
        }
        &__content {
          width: calc(100% - 12px);
          padding: 0 0 0 12px;
          &__sec {
            padding: 0 0 2px 0;
            span {
              padding: 0px 6px;
              font-size: 10px;
              @include flexAcenter;
              width: max-content;
              height: 16px;
            }
          }
        }
        &__graphic {
          flex-direction: column;
          &__heading {
            width: 100%;
          }
          &__section {
            width: 100%;
          }
        }
        &__camera {
          flex-direction: column;
          &__heading {
            width: 100%;
          }
          &__section {
            width: 100%;
          }
        }
        &__atmosphere {
          flex-direction: column;
          margin: 0 0 8px 0;
          &__heading {
            width: 100%;
          }
          &__sec {
            width: 100%;
          }
        }
      }
      &__atmosphere {
        &__heading {
          margin: 8px 0 4px 0;
          &__left {
            h4 {
              font-size: 12px;
            }
          }
        }
        &__image {
          flex-direction: column;
          &__base {
            width: 100%;
            .card {
              height: 80px;
            }
            .card-content {
              justify-content: center;
            }
          }
          &__weight {
            width: 100%;
          }
        }
        &:last-child {
          padding: 0 0 8px 0;
        }
        &__images {
          gap: 4px;
        }
        &__image1 {
          flex-direction: column;
          &__base {
            width: 100%;
            .card {
              min-width: 8.8vw;
              height: 12vw;
            }
            .card-content {
              justify-content: center;
              gap: 4px;
              .icon {
                width: 20px;
                height: 20px;
              }
              p {
                padding: 0 0px;
                font-size: 10px;
                line-height: 14px;
              }
            }
          }
          &__weight {
            width: 100%;
          }
          &:last-child {
            padding: 0 0 8px 0;
          }
        }
      }
      &__main1 {
        &__heading {
          h4 {
            font-size: 12px;
          }
          .tooltip-top {
            img {
              width: 12px;
            }
          }
        }
        &__input {
          height: max-content;
          textarea {
            padding: 8px;
            max-width: 100%;
            min-height: 106px;
            font-size: 10px;
          }
          p {
            font-size: 10px;
            span {
              font-size: 10px;
            }
          }
        }
      }
      &__main3 {
        &__heading {
          margin: 8px 0 4px 0;
          h4 {
            font-size: 12px;
          }
          .tooltip-top {
            img {
              width: 12px;
            }
          }
        }
        &__options {
          &__container {
            .css-1iu1djs-singleValue {
              font-size: 12px !important;
            }
            .css-13cymwt-control {
              min-height: 28px !important;
            }
            .css-qbdosj-Input {
              margin: 0 !important;
              padding: 0 !important;
            }
            h5 {
              font-size: 12px;
              width: 34px;
              height: 26px;
            }
            .input-value {
              font-size: 12px;
              width: 34px;
              height: 26px;
            }
            .css-t3ipsp-control {
              min-height: 28px !important;
            }
          }
        }
      }
    }
  }
}
