@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.cropper-modal {
  @include flexColumnACenter;
  h3 {
    color: $primary_white;
    padding: $font_xsmall;
  }
  &__main {
    display: flex;
    width: 404px;
  }
  &__separator {
    border-left: 2px solid #ffffff;
    margin: 0 16px;
    height: 100%;
  }
  &__buttons {
    width: 37%;
    @include flexbetween;
    padding: 16px 0;
    .save {
      width: $font_top6;
      padding: $font_xverysmall 0;
      background: $primary_white;
      // border: 1px solid $primary_black;
      border: none;
      color: $primary_black;
      border-radius: $font_xverysmall;
      cursor: pointer;
    }
    .save:hover {
      background: $grey_input;
      border: none;
      color: $primary_white;
    }
    .cancel {
      width: $font_top6;
      padding: $font_xverysmall 0;
      background: $primary_black;
      color: $primary_white;
      border: none;
      border-radius: $font_xverysmall;
      cursor: pointer;
    }
    .cancel:hover {
      background: $grey_input;
      border: none;
      color: $primary_white;
    }
  }
}
