@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.instruct {
  position: fixed;
  width: 244px;
  min-height: 285px;
  z-index: 999;
  background: white;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  &__container {
    @include flexColumnACenter;
    padding: 12px;
  }
  &__card {
    width: 100%;
    height: 165px;
    background: $grey_button;
    border-radius: 6px;
    img {
      width: 100% !important;
      display: block !important;
    }
  }
  &__element {
    width: 100%;
    max-width: 100%;
  }
  &__content {
    width: 100%;
    padding: 12px 0 0 0;
    height: 100%;
    h4 {
      font-size: 12px;
      font-weight: 600;
      padding: 0 0 4px 0;
      color: $primary_blue;
    }
    p {
      font-size: 12px;
      font-weight: 400;
      color: $primary_blue;
      padding: 4px 0 2px 0;
      min-height: 40px;
    }
  }
}
