@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.ant-table-cell {
  padding: 8px 16px !important;
  img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }
  .view {
    border-radius: 0;
    width: 24px;
    height: auto;
    cursor: pointer;
  }
  .hide {
    border-radius: 0;
    width: 24px;
    height: auto;
  }
}

.ant-modal-content {
  background: $primary_blue !important;
}
.ant-modal-header {
  background: $primary_blue !important;
}
.ant-modal-title {
  color: $primary_white !important;
}
.ant-modal-close-icon {
  svg {
    fill: $primary_white !important;
  }
}

.users-list {
  &__section {
    &__data {
      position: relative;
      &__search {
        position: absolute;
        top: 18px;
        right: 20px;
        z-index: 9;
      }
    }
  }
  &__userportal {
    &__heading {
      padding: 0 0 20px 0;
      button {
        min-width: 100px;
        padding: 8px 16px;
        border-radius: 15px;
        border: none;
        background: $primary_black;
        color: $primary_white;
        cursor: pointer;
        font-size: 16px;
      }
      .button:hover {
        opacity: 0.7;
      }
      .button:active {
        opacity: 0.5;
      }
      .button__disabled {
        background: $grey_input;
        cursor: default;
      }
      .button__disabled:hover {
        opacity: 1;
      }
      .button__disabled:active {
        opacity: 1;
      }
    }
  }
}
