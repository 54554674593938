@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.plan-upgrade {
  background: rgb(0 0 0 / 54%);
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &__container {
    width: 646px;
    background: $primary_white;
    box-shadow: 0 4px 4px 0 #000000a2;
    border-radius: 8px;
    border: 1px solid $primary_grey;
  }
  &__sections {
    padding: 20px 32px;
  }
  &__heading {
    @include flexcolumn;
    gap: 20px;
    padding: 0 0 32px 0;
    border-bottom: 1px solid #d4d6d7;
    &__top {
      @include flexAcenter;
      gap: 20px;
      width: 540px;
      img {
        width: 64px;
        height: 64px;
      }
      h2 {
        font-size: 32px;
        line-height: 48px;
        font-weight: 600;
      }
    }
    &__bottom {
      @include flexrow;
      align-items: flex-start;
      gap: 12px;
      h5 {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }
    }
  }
  &__content {
    margin: 32px 0 20px 0;
    h6 {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      width: -webkit-fill-available;
    }
    h4 {
      font-size: 18px;
      line-height: 27px;
      font-weight: 600;
    }
    ul {
      padding: 0 0 0 28px;
      margin: 8px 0;
      li {
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
      }
    }
  }
  &__buttons {
    @include flexcolumn;
    gap: 12px;
    button {
      width: 100%;
      height: 38px;
      border-radius: 20px;
      border: 1px solid $primary_grey;
      font-size: 14px;
      font-weight: 400;
      background: none;
      cursor: pointer;
      &:hover {
        opacity: 0.6;
      }
    }
    .success {
      background: $primary_blue;
      color: $primary_white;
      border: none;
      height: 40px;
    }
  }
}
