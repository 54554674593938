@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.team-members {
  background: #0000008a;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.5s forwards;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  &__container {
    width: 430px;
    max-height: 80vh;
    background: $primary_white;
    border: 1px solid $primary_grey;
    border-radius: 8px;
  }
  &__section {
    padding: 40px;
    @include flexcolumn;
  }
  &__heading {
    h2 {
      font-size: 16px;
      font-weight: 600;
    }
  }
  &__list {
    padding: 40px 0 0 0;
    max-height: calc(60vh);
    overflow: scroll;
    &__member {
      @include flexrowacenter;
      padding: 0 0 20px 0;
      &:last-child {
        padding: 0;
      }
      &__icon {
        @include flexrowacenter;
        img {
          width: 40px;
          height: 40px;
        }
      }
      &__details {
        padding: 0 0 0 4px;
        h3 {
          font-size: 14px;
          color: $font_grey;
        }
        h4 {
          font-size: 14px;
          color: $font_grey;
        }
      }
    }
    &__loader {
      @include flexJCenter;
      img {
        width: 80px;
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .team-members {
    &__container {
      width: 346px;
    }
    &__section {
      padding: 32px;
    }
    &__heading {
      h2 {
        font-size: 14px;
      }
    }
    &__list {
      padding: 32px 0 0 0;
      &__member {
        padding: 0 0 16px 0;
        &__icon {
          img {
            width: 32px;
            height: 32px;
          }
        }
        &__details {
          h3 {
            font-size: 12px;
          }
          h4 {
            font-size: 12px;
          }
        }
      }
    }
  }
}