@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.bot-tooltip {
  position: absolute;
  width: auto;
  @include flexCenter;
  &__container {
    width: 100%;
    background: $primary_white;
    border-radius: 4px;
    z-index: 1000;
    box-shadow: 3px 2px 4px 0px #00000025, -4px 2px 4px 0px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    ul {
      padding: 8px 12px;
      margin: 0;
      width: auto;
      li {
        font-size: 14px;
        list-style-type: none;
        padding: 0 0 0 4px;
        span {
          font-size: 14px;
        }
      }
    }
  }
  &__section {
    width: 100%;
    @include flexCenter;
  }
  &__arrow {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 7px solid $primary_white;
    transform: rotate(180deg);
    z-index: 10001;
  }
}


@media screen and (min-width: 768px) and (max-width: 1610px) {
  .bot-tooltip {
    &__container {
      ul {
        padding: 6px 10px;
        li {
          font-size: 12px;
        }
      }
    }
  }
}