@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";
@import "../../styles/typography.scss";

.side-nav {
  position: fixed;
  left: 0;
  margin: 12px;
  top: 48px;
  width: 232px;
  z-index: 5;
  height: calc(var(--vh, 1vh) * 100 - 48px - 24px);
  background: $primary_white;
  border: 1px solid $primary_grey;
  border-radius: 8px;
  &__container {
    height: 100%;
    @include flexcolumn;
  }

  &__sections {
    height: 100%;
    @include flexcolumn;
    justify-content: space-between;
    position: relative;
  }

  &__section {
    height: 88%;
    overflow-y: auto;
    margin: 12px 0 0 0;
    &__main:last-child {
      border: none;
    }
    &__main {
      padding: 0 0 12px 0;
      border-bottom: 1px solid #d4d6d7;
      &__border {
        display: none;
        height: 1px; 
        position: relative;
        margin: 0 auto 12px 4px;
      }

      &__border::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 60px;
        height: 100%;
        background-color: #d4d6d7;
      }
      &__heading {
        background: $bg_light_grey;
        @include flexAcenter;
        padding: 12px 0 12px 20px;
        img {
          width: 24px;
          height: 24px;
        }
        h4 {
          font-size: 18px;
          padding: 0 0 0 12px;
          font-weight: 500;
        }
      }

      &__sub {
        &__sec {
          @include flexAcenter;
          padding: 0 0 0 16px;
          position: relative;
          cursor: pointer;
          height: 40px;
          margin: 4px 0 0 0;
          a {
            width: 100%;
            text-decoration: none;
            @include flexAcenter;
            height: 40px;
            .red-dot {
              width: 6px;
              height: 6px;
              background: #ff1115;
              position: absolute;
              top: 6px;
              left: 12px;
              border-radius: 50%;
              animation: blinkBorder 1.2s infinite;
            }
            .new {
              display: flex !important;
              position: absolute;
              top: 4px;
              left: 32px;
              @include flexCenter;
              width: max-content;
              padding: 0 2px;
              height: 12px;
              background: #F4C135;
              border-radius: 2px;
              color: $primary_white;
              font-weight: 400;
            }
            @keyframes blinkBorder {
              0%,
              100% {
                box-shadow: 0 0 0 2px transparent;
              }
              50% {
                box-shadow: 0 0 0 4px #ff4d5054;
              }
            }
            img {
              width: 22px;
              height: 22px;
            }

            span {
              font-size: 14px;
              padding: 0 0 0 12px;
              color: $primary_black;
              font-family: $inter_regular;
              font-weight: 400;
            }
            /*h6 {
              @include flexCenter;
              width: max-content;
              padding: 0 2px;
              height: 14px;
              background: #F4C135;
              border-radius: 2px;
              font-size: 11px;
              color: $primary_white;
              margin: 0 0 0 8px;
              font-weight: 400;
            }*/
          }
          .tooltip-show {
            display: none;
          }
        }
        &__sec:hover {
          background: $secondary_grey;
          .tooltip-show {
            display: block;
          }
        }
        &__sec.active {
          background: $primary_black;
          a {
            span {
              color: $primary_white;
              // font-family: $inter_semibold;
            }
          }
        }
      }
    }
  }

  &__bottom {
    @include flexColumnACenter;
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding: 12px 0;
    background: $primary_white;
    border-radius: 0 0 8px 8px;

    &__icons {
      img {
        width: 14px;
        height: 14px;
        padding: 0 10px 0 0;
      }

      .last-icon {
        padding: 0;
      }
    }

    &__copy {
      p {
        font-size: 14px;
        color: $grey_input;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1090px) {
  .side-nav {
    top: 37px;
    width: 84px;
    border-radius: 6px;
    height: calc(var(--vh, 1vh) * 100 - 37px - 24px);
    &__section {
      height: 92%;
      &__main {
        &__border {
          display: block;
        }
        &__heading {
          padding: 5px 0;
          justify-content: center;
          img {
            width: 24px;
            height: 24px;
          }
          h4 {
            display: none;
          }
        }
        &__sub {
          padding: 0;
          &__sec {
            padding: 0;
            a {
              justify-content: center;
              img {
                width: 18px;
                height: 18px;
                padding: 0;
              }
              span {
                display: none;
              }
            }
          }
        }
      }
    }
    &__bottom {
      display: none;
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 1090px) {
  .side-nav {
    top: 37px;
    width: 10vw;
    height: calc(var(--vh, 1vh) * 100 - 37px - 24px);
    border-radius: 6px;
    &__section {
      height: 92%;
      &__main {
        border: none;
        &__border {
          display: block;
        }
        &__heading {
          padding: 5px 0;
          justify-content: center;
          img {
            width: 24px;
            height: 24px;
          }
          h4 {
            display: none;
          }
        }
        &__sub {
          padding: 0;
          &__sec {
            padding: 0;
            a {
              justify-content: center;
              img {
                width: 18px;
                height: 18px;
                padding: 0;
              }
              span {
                display: none;
              }
              .new{
                left: 5.5vw;
              }
            }
          }
        }
      }
    }
    &__bottom {
      display: none;
    }
  }
}
