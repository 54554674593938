@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.offers {
  @include flexJCenter;
  width: 30.8%;
  margin: 0 0 2vw 0;
  &__container {
    width: 16vw;
    @include flexColumnACenter;
    &__image {
      width: 8vw;
      height: 8vw;
      img {
        width: 100%;
      }
    }
    &__content {
      @include flexColumnACenter;
      text-align: center;
      h3 {
        padding: 0.8vw 0 0.4vw 0;
        color: $primary_white;
        font-size: 1.4vw;
        letter-spacing: 1.5px;
        font-family: $inter_medium;
      }
      p {
        font-size: 0.8vw;
        line-height: 1vw;
        color: $primary_white;
        font-family: $inter_medium;
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 1090px) {
  .offers {
    width: 47%;
    padding: 0 0 12px 0;
    &__container {
      width: auto;
      &__image {
        width: 140px;
        height: 140px;
      }
      &__content {
        h3 {
          font-size: 18px;
        }
        p {
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }
}