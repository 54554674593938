@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.back-to-bottom {
  position: absolute;
  bottom: 28px;
  right: 20px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.back-to-bottom.show {
  opacity: 1;
  visibility: visible;
}

.back-to-bottom button {
  display: flex;
  background-color: $primary_grey;
  color: $primary_black;
  border: none;
  border-radius: 50%;
  // width: $font_xxlarge;
  // height: $font_xxlarge;
  font-size: $font_xsmall;
  cursor: pointer;
  padding: 0;
  transition: background-color 0.3s ease-in-out;
  img {
    width: 20px;
    height: 20px;
    padding: 8px 8px 8px 8px;
    rotate: (-90deg);
  }
}

.back-to-bottom button:hover {
  background-color: #eee;
}

@media screen and (max-width: 1090px) {
  .back-to-bottom { 
    bottom: 8px;
    button {
    width: 24px;
    height: 24px;
    @include flexCenter;
    img {
      width: 14px;
      height: 14px;
      padding: 4px 4px 4px 4px;
    }
  }
}
}
