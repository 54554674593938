@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.contact-airi {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100 - 200px);
  @include flexColumnACenter;
  background: $bg_light_grey;
  &__container {
    @include flexColumnACenter;
    height: calc(100% - 200px);
    margin: 200px 0 0 0;
    position: relative;
  }
  &__sections {
    // width: 510px;
    @include flexbetween;
  }
  &__left {
    width: 288px;
    padding: 0 16px 0 0;
    text-align: center;
    img {
      width: 100%;
    }
    p {
      font-size: 14px;
    }
  }
  &__right {
    width: auto;
    padding: 0 0 0 16px;
    &__content {
      width: 320px;
      h2 {
        font-size: 22px;
        font-weight: 600;
      }
    }
    &__sec {
      padding: 20px 0 0 0;
      h6 {
        font-size: 18px;
        font-weight: 500;
        color: $primary_blue;
        padding: 0 0 12px 0;
      }
      a {
        width: 100%;
        @include flexCenter;
        font-size: 16px;
        color: $primary_white;
        text-decoration: none;
        background: $primary_blue;
        border-radius: 24px;
        height: 40px;
      }
    }
  }
  &__bottom {
    position: fixed;
    bottom: 24px;
    &__section{
      //border-top: 1px solid #d4d6d7;
      margin: 1.2vw 0 0 0;
      padding: 1.2vw 0 0 0;
      @include flexrow;
      h4 {
        font-size: 0.75vw;
        font-weight: 400;
        color: $primary_blue;
        padding: 0 1.2vw 0 0;
      }
      h5 {
        font-size: 0.75vw;
        font-weight: 400;
        color: $primary_blue;
        padding: 0 12px 0 0;
      }
      h5:last-child {
        padding: 0;
      }
    }
    &__section2{
      @include flexrow;
      h4 {
        font-size: 0.75vw;
        font-weight: 400;
        color: $primary_blue;
        padding: 0 1.2vw 0 0;
      }
      h5 {
        font-size: 0.75vw;
        font-weight: 400;
        color: $primary_blue;
        padding: 0 12px 0 0;
      }
      a{
        font-size: 0.75vw;
        font-weight: 400;
        color: $primary_blue;
        padding: 0 12px 0 0;
      }
      h5:last-child {
        padding: 0;
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 767px) {
  .contact-airi {
    padding: 0;
    height: 100%;
    &__container {
      height: 100%;
      width: 100%;
      @include flexJEnd;
      margin: 52px 0 0 0;
      align-items: flex-end;
    }
    &__sections {
      width: 88vw;
      padding: 0 0 60px 0;
      justify-content: center;
    }
    &__left {
      width: 25%;
      img {
        width: 100%;
      }
    }
    &__right {
      width: 60%;
      padding: 0;
      &__content {
        width: 100%;
        h2 {
          font-size: 16px;
        }
      }
      &__sec {
        padding: 12px 0 0 0;
        h6 {
          font-size: 14px;
          padding: 0 0 8px 0;
        }
        a {
          height: 32px;
          font-size: 12px;
        }
      }
    }
    &__bottom {
      width: 88vw;
        &__section {
          h4 {
            font-size: 10px;
          }
          h5 {
            font-size: 10px;
          }
        }
        &__section2 {
          h4 {
            font-size: 10px;
          }
          h5 {
            font-size: 10px;
          }
          a {
            font-size: 10px;
          }
        }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1090px) {
  .contact-airi {
    padding: 0;
    height: 100%;
    &__container {
      height: 100%;
      width: 100%;
      margin: 52px 0 0 0;
    }
    &__sections {
      width: 80%;
      padding: 0 0 60px 0;
      justify-content: center;
    }
    &__left {
      width: 250px;
      img {
        width: 100%;
      }
    }
    &__right {
      width: 250px;
      &__content {
        h2 {
          font-size: 16px;
        }
      }
      &__sec {
        padding: 12px 0 0 0;
        h6 {
          font-size: 14px;
          padding: 0 0 8px 0;
        }
        a {
          height: 32px;
          font-size: 12px;
        }
      }
    }
    &__bottom {
      width: 60vw;
      &__section {
        h4 {
          font-size: 10px;
        }
        h5 {
          font-size: 10px;
        }
      }
      &__section2 {
        h4 {
          font-size: 10px;
        }
        h5 {
          font-size: 10px;
        }
        a {
          font-size: 10px;
        }
      }
    }
  }
}
