@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.backdrop {
  background: rgb(0 0 0 / 54%);
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  :focus-visible{
    outline:none;
  }
}

.image-mask {
  width: 100%;
  height: calc(100% - var(--vh, 1vh) * 4);
  display: flex;
  justify-content: center;
  background: $primary_white;
  border: 1px solid $primary_grey;
  border-radius: $font_verysmall;
  margin: calc(var(--vh, 1vh) * 2);
  @include columnflexcenter;
  .left {
    @include flexAcenter;
    transform: translateX(-40px);
    &__sec {
      @include flexAcenter;
      position: relative;
      padding: 0 12px;
    }
    &__sec:last-child::after {
      background-color: none;
      height: 0%;
      width: 0px;
    }
    &__sec::after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 50%;
      width: 2px;
      background-color: #A8ADAF;
    }
    .tooltip-top {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      button{
        height: 32px;
        padding: 6px 6px 6px 6px;
        margin: 0 4px 0 4px;
        border: none;
        background: transparent;
        cursor: pointer;
      }
      input{
        width: 40px;
        height: 16px;
        padding: 6px 6px 6px 6px;
        margin: 0 4px 0 4px;
        border: 1px solid #A8ADAF; /* define a solid border */
        border-radius: 8px;
      }
      option {
        border: 1px solid #A8ADAF;
        background-color: #F0F0F0;
      }
      img {
        width: 20px;
        height: 20px;
      }
      img:last-child {
        padding: 0;
      }
      .tooltip-show {
        z-index : 999;
        display: none;
      }
      &__sec{
        width: 100px;
        @include textc;
        .ant-slider-horizontal {
          margin: 0px 5px 0 5px !important;
        }
        span {
          width: 100%;
          display: flex;
          justify-content: center;
          opacity: 0.6;
          font-size: 12px;
        }
        span.left {
          justify-content: flex-start;
        }
    
        span.right {
          justify-content: flex-end;
        }
        .minus {
          margin: 0 8px 0 0;
        }
      }
    }
    .tooltip-top:hover {
      .tooltip-show {
        display: block;
      }
      button:hover{
        opacity: 0.5;
      }
    }
  }

  .mySelect__control {
    border: 1px solid #A8ADAF;
    border-radius: 8px;
    height: 32px; /* ensure a minimum height */
    min-height: 32px; 
    box-shadow: none; /* remove default focus border */
  }

  .mySelect__value-container {
    padding: 0 8px;      /* Remove extra vertical padding */
    height: 32px;         /* Ensure the inner container matches the outer height */
    display: flex;       
    align-items: center; /* Vertically center text within the 32px height */
  }
  .mySelect__single-value,
  .mySelect__placeholder {
    line-height: 32px;    /* Make text align nicely in the center */
  }

  .mySelect__indicator{
    padding:6px;
  }

  .mySelect__menu{
    margin:2px;
  }

  .center {
    display: flex;
    justify-content: center;
    padding: 0 0 0 4px;
  }

  .brush_circle {
    & {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    & > div {
      border-radius: 50%;
      border: 1px solid black;
      margin: 0 16px 0 0;
      cursor: pointer;
    }
    .selected {
      background-color: black;
    }
    .first {
      width: 8px;
      height: 8px;
    }
    .second {
      width: 12px;
      height: 12px;
    }
    .third {
      width: 16px;
      height: 16px;
    }
    .fourth {
      width: 18px;
      height: 18px;
    }
  }

  .icon {
    width: 20px;
    height: 20px;
  }

  &__container {
    width: 100%;
    height: 100%;
    @include flexJCenter;
  }
  &__main {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    &__heading {
      @include flexbetween;
      z-index: 9999;
      padding: calc(var(--vh, 1vh) * 4) 3.5vh 0px 3.5vh;
      align-items: center;
      h4 {
        padding: 16px 0;
      }
      img {
        cursor: pointer;
        width: 24px;
        height: 24px;
      }
    }
    &__section {
      height:100%;
      margin: calc(var(--vh, 1vh) * 1.8) 0 calc(var(--vh, 1vh) * 1.8) 0;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      &__images {
        &__background {
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      &__image {
        padding: 10px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 12px;
        }
        .active {
          border: 1px solid $primary_black;
          padding: 1px;
        }
      }
      &__button {
        position: absolute;
        bottom: 1vw;
        right: 1vw;
        padding: 0 $font_xlarge 0 0;
        button {
          width: $font_top5;
          padding: $font_verysmall 0;
          border-radius: $font_large;
          border: none;
          background: $primary_black;
          color: $primary_white;
          cursor: pointer;
        }
        button:disabled {
          cursor: not-allowed;
          background: #d9d9d9;
          color: #d9d9d9;
          border-radius: 0;
        }
      }
    }
  }

  img {
    cursor: pointer;
  }


  .m-0 {
    margin: 0px !important;
  }

  .first {
    cursor: url("../../../assets/images/workspace/icons/first-circle.png") 5
        5,
      auto;
  }

  .second {
    cursor: url("../../../assets/images/workspace/icons/second-circle.png")
        20 20,
      auto;
  }

  .third {
    cursor: url("../../../assets/images/workspace/icons/third-circle.png") 35
        35,
      auto;
  }

  .fourth {
    cursor: url("../../../assets/images/workspace/icons/fourth-circle.png")
        50 50,
      auto;
  }

  .lasso {
    cursor: url("../../../assets/images/workspace/icons/lasso_plus.png")
        0 0,
      auto;
  }

  .lasso_eraise {
    cursor: url("../../../assets/images/workspace/icons/lasso_minus.png")
        0 0,
      auto;
  }

  .lassoP {
    cursor: url("../../../assets/images/workspace/icons/lassoP_plus.png")
        0 0,
      auto;
  }

  .lassoP_eraise {
    cursor: url("../../../assets/images/workspace/icons/lassoP_minus.png")
        0 0,
      auto;
  }

  .magicWand {
    cursor: url("../../../assets/images/workspace/icons/magic_wand plus.png")
        0 0,
      auto;
  }

  .magicWand_eraise {
    cursor: url("../../../assets/images/workspace/icons/magic_wand minus.png")
        0 0,
      auto;
  }

  .rect{
    cursor: url("../../../assets/images/workspace/icons/rectangle_cursor.png")
        16 16,
      auto;
  }
  .drag{
    cursor: grab
  }
  .dragging{
    cursor: grabbing
  }

}

:root {
  --selected-brush-size: 50px; /* Default value */
}

@media screen and (min-width:768px) and (max-width:1090px) {
}

.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url("../../../assets/images/icons/resize_handle.png");
  background-size: 8px 8px;
  background-position: bottom right;
  padding: 0 0px 0px 0;
  background-color: transparent;
}
.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-resizable-handle-w {
  left: 4px;
  transform: rotate(135deg);
}
.react-resizable-handle-e {
  right: 4px;
  transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-resizable-handle-n {
  top: 4px;
  transform: rotate(225deg);
}
.react-resizable-handle-s {
  bottom: 4px;
  transform: rotate(45deg);
}
