@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";
@import "../../styles/typography.scss";

.plugins {
  width: 100%;
  @include flexJCenter;
  &__container {
    width: calc(100% - 240px);
  }
  &__sections {
    @include flexcolumn;
  }
  &__banner {
    @include flexrow;
    gap: 60px;
    padding: 120px 0;
    &__left {
      width: 864px;
      @include flexcolumn;
      gap: 24px;
      h2 {
        font-size: 60px;
        font-weight: 600;
        line-height: 72px;
      }
      p {
        font-size: 24px;
        font-weight: 400;
        line-height: 29px;
      }
      &__buttons {
        @include flexrow;
        gap: 12px;
        padding: 60px 0 0 0;
        button {
          padding: 12px 36px;
          font-size: 18px;
          font-weight: 500;
          border-radius: 24px;
          background: $secondary_grey;
          border: 1px solid $primary_grey;
          cursor: pointer;
          &:hover {
            background: $onhover_grey;
            color: $primary_blue;
          }
          &.active {
            background: $primary_blue;
            color: $primary_white;
            &:hover {
              background: $onhover_grey;
            }
          }
          &.disable {
            opacity: 0.6;
            cursor: default;
            &:hover {
              background: $secondary_grey;
              border: 1px solid $primary_grey;
            }
          }
        }
      }
    }
    &__right {
      width: 758px;
      img {
        margin-top: -24px;
        width: 100%;
      }
    }
  }
  &__content {
    @include flexrow;
    flex-direction: row-reverse;
    gap: 60px;
    margin: 0 0 100px 0;
    display: none;
    &.active {
      display: flex;
    }
    &__left {
      width: 864px;
      @include flexcolumn;
      gap: 24px;
      &__heading {
        @include flexrowacenter;
        gap: 24px;
        img {
          // width: 100%;
        }
        h2 {
          font-size: 24px;
          font-weight: 600;
          line-height: 29px;
        }
      }
      &__content {
        h3 {
          font-size: 16px;
          font-weight: 600;
        }
        p {
          font-size: 14px;
          font-weight: 400;
        }
        h6 {
          font-size: 14px;
          font-weight: 400;
          text-decoration: underline;
          cursor: pointer;
        }
      }
      &__buttons {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 12px;
        padding: 36px 0 0 0;
        button {
          padding: 12px 32px;
          font-size: 18px;
          font-weight: 500;
          border-radius: 50px;
          border: 1px solid $primary_grey;
          cursor: pointer;
          background: $primary_blue;
          color: $primary_white;
          &:hover {
            background: $onhover_grey;
            color: $primary_blue;
          }
          &.active {
            background: $primary_blue;
            color: $primary_white;
            &:hover {
              background: $onhover_grey;
            }
          }
        }
      }
    }
    &__right {
      width: 758px;
      video {
        margin-top: -24px;
        width: 100%;
      }
      &__loader {
        @include flexCenter;
        min-height: 400px;
        border: 1px solid $secondary_grey;
        border-radius: 8px;
        img {
          width: 120px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1250px) {
  .plugins {
    &__container {
      width: 760px;
      max-width: 85%;
    }
    &__banner {
      flex-direction: column;
      padding: 32px 0;
      gap: 40px;
      &__left {
        width: 100%;
        &__buttons {
          padding: 16px 0;
        }
      }
      &__right {
        width: 100%;
        img {
          margin: 0;
        }
      }
    }
    &__content {
      flex-direction: column-reverse;
      padding: 8px 0;
      gap: 40px;
      &__left {
        width: 100%;
        &__buttons {
          padding: 16px 0;
        }
      }
      &__right {
        width: 100%;
        video {
          margin: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 767px) {
  .plugins {
    &__container {
      width: 560px;
    }
    &__banner {
      flex-direction: column;
      padding: 32px 0;
      gap: 40px;
      &__left {
        width: 100%;
        &__buttons {
          padding: 16px 0;
        }
      }
      &__right {
        width: 100%;
        img {
          margin: 0;
        }
      }
    }
    &__content {
      flex-direction: column-reverse;
      padding: 8px 0;
      gap: 40px;
      &__left {
        width: 100%;
        &__buttons {
          padding: 16px 0;
        }
      }
      &__right {
        width: 100%;
        video {
          margin: 0;
        }
      }
    }
  }
}
