@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.tooltipt {
  position: absolute;
  width: 160px;
  top: 20px;
  left: -66px;
  @include flexColumnACenter;
  //   box-shadow: 0px 4px 4px 0px #00000025;
  &__container {
    // width: 100%;
    background: $primary_white;
    border-radius: 4px;
    padding: 6px 8px;
    z-index: 1000;
    box-shadow: 0px 8px 4px 0px #00000025, 0px 1px 4px 0px #00000025;
    box-sizing: border-box;
    p {
      font-size: 14px;
      padding: 0;
    }
  }
  &__section {
    width: 100%;
    @include flexCenter;
  }
  &__arrow {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 7px solid $primary_white;
    transform: rotate(0deg);
    // box-shadow: 0px -4px 4px 0px #00000025;
    z-index: 10001;
  }
}

.tooltiptn {
  position: absolute;
  width: 162px;
  top: 40px;
  left: -62px;
  @include flexColumnACenter;
  &__container {
    // width: 100%;
    background: $primary_white;
    border-radius: 4px;
    padding: 6px 8px;
    z-index: 1000;
    box-shadow: 0px 8px 4px 0px #00000025, 0px 1px 4px 0px #00000025;
    box-sizing: border-box;
    p {
      font-size: 14px;
      padding: 0;
      text-align: center;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1610px) {
  .tooltipt {
    &__container {
      p {
        font-size: 12px;
      }
    }
  }
  .tooltiptn {
    &__container {
      p {
        font-size: 12px;
      }
    }
  }
}
