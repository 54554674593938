@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.admin-header {
  width: 100%;
  position: fixed;
  z-index: 999;
  background: $primary_white;
  border-bottom: 1px solid #ededed;
  &__container {
    width: 100%;
    @include flexJCenter;
  }
  &__nav {
    width: 95%;
    @include flexrowacenter;
    justify-content: space-between;
    &__logo {
      img {
        width: 150px;
      }
    }
    &__links {
      width: 50%;
      ul {
        padding: 0;
        margin: 0;
        @include flexrowacenter;
        li {
          list-style-type: none;
          padding: 0 12px 0 0;
          a {
            font-size: 16px;
            color: $grey_input;
          }
        }
        li:last-child {
          padding: 0;
        }
      }
    }
    &__user {
      @include flexrowacenter;
      &__name {
        padding: 0 12px 0 0;
        p {
          font-size: 14px;
        }
      }
      &__icon {
        @include flexColumnACenter;
        img {
          width: 36px;
          height: 36px;
        }
      }
      button {
        background: none;
        border: none;
        @include flexrowacenter;
        cursor: pointer;
        span {
          font-size: 16px;
          padding: 0 0 0 4px;
        }
      }
      button:hover {
        opacity: 0.6;
      }
    }
  }
}
