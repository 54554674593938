@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.planEnd {
  background: rgb(0 0 0 / 54%);
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &__popup {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &__container {
      width: 480px;
      background: $primary_white;
      padding: 16px;
      border-radius: 8px;
    }
    &__section {
      @include flexColumnACenter;
      &__header {
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        padding: 0 0 12px 0;
        img {
          width: 16px;
          height: 16px;
        }
        h3 {
          font-size: 16px;
          padding: 0 0 0 12px;
        }
        .cross {
          position: absolute;
          right: 0;
          top: 0;
          cursor: pointer;
        }
      }
      &__content {
        padding: 0 0 12px 0;
        p {
          font-size: 14px;
          color: $grey_input;
        }
      }
      &__buttons {
        padding: 12px 0 0 0;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        &__section {
          width: 220px;
          display: flex;
          justify-content: flex-end;
          button {
            min-width: 96px;
            border-radius: 15px;
            padding: 4px 0;
            border: none;
            background: $primary_black;
            color: $primary_white;
            cursor: pointer;
            font-size: 14px;
          }
          button:hover {
            opacity: 0.7;
          }
          button:active {
            opacity: 0.5;
          }
          .cancel {
            background: $primary_white;
            color: $primary_black;
            opacity: 0.5;
          }
          .cancel:hover {
            opacity: 0.7;
          }
          .cancel:active {
            opacity: 1;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 1090px) {
  .planEnd {
    &__popup {
      &__container {
        width: 320px;
        padding: 12px;
      }
      &__section {
        &__header {
          img {
            width: 14px;
            height: 14px;
          }
          h3 {
            font-size: 14px;
            padding: 0 0 0 12px;
          }
        }
        &__content {
          p {
            font-size: 12px;
          }
        }
        &__buttons {
          &__section {
            width: 180px;
            button {
              min-width: 86px;
              font-size: 12px;
              padding: 4px 0;
            }
          }
        }
      }
    }
  }
}
