@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

:where(.ant-slider-track) {
  background: $grey_input !important;
}

:where(.ant-slider-rail) {
  background: $primary_grey !important;
}

:where(.css-dev-only-do-not-override-p7e5j5).ant-slider
  .ant-slider-handle::after {
  box-shadow: 0 0 0 2px $grey_input !important;
}

.react-switch-Undragable {
  pointer-events: none;
}

.plugin-screenshot-popover {
  min-width: 60px !important;
  .ant-popover-inner {
    padding: 4px 8px !important;
  }
}

.cn-upload-popover {
  width: 160px;
  ul {
    padding: 0 12px;
    list-style-type: none;
    li {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
    }
  }
}

.toolset {
  position: fixed;
  width: 232px;
  right: 0;
  top: 48px;
  margin: 12px;
  height: calc(var(--vh, 1vh) * 100 - 48px - 24px);
  background: $primary_white;
  border: 1px solid $primary_grey;
  border-radius: 8px;
  z-index: 1;

  &__container {
    height: 100%;
    @include flexcolumn;
  }

  &__sections {
    height: calc(100% - 40px);
    @include flexcolumn;
    justify-content: space-between;
    padding: 20px 0px 20px 0px;
  }

  &__section {
    overflow: auto;
    padding: 0 15px;
    height: 100%;
    @include flexcolumn;

    &__tools {
      width: 100%;
      height: 100%;
      @include flexColumnACenter;
      position: relative;

      &__heading {
        width: 100%;
        @include flexColumnACenter;
        padding: 0 0 16px 0;

        h4 {
          font-size: 16px;
          font-weight: 500;
          text-align: start;
        }
      }

      &__tabs {
        width: 100%;
        @include flexbetween;
      }

      &__tab {
        width: 100%;
        @include flexColumnACenter;

        img {
          width: 24px;
        }

        span {
          display: none;
          font-size: 14px;
          font-weight: 600;
          padding: 8px 0 0 0;
          color: $primary_blue;
        }
      }

      .react-tabs {
        width: 100%;
        height: 100%;

        &__tab-list {
          padding: 0;
          margin: 0;
          @include flexColumnACenter;
          border: none !important;
        }

        &__tab-panel {
          width: 100%;
          height: max-content;
        }

        &__tab {
          list-style-type: none;
          width: 20%;
          cursor: pointer;
          border: none !important;
          padding: 0 !important;

          img {
            display: none;
          }

          .imgh {
            display: none;
          }

          .imgd {
            display: block;
          }

          span {
            display: none;
            font-size: 14px;
          }

          .updated {
            display: flex !important;
            position: absolute;
            top: -8px;
            right: -12px;
            width: 28px;
            height: 16px;
            background: #f4c135;
            color: $primary_white;
            @include flexCenter;
            border-radius: 2px;
            z-index: 1;
          }
        }

        &__tab:hover {
          img {
            display: none;
          }

          .imgh {
            display: block;
          }

          .imgd {
            display: none;
          }

          .updated {
            display: block;
          }
        }

        &__tab--selected {
          opacity: 1;
          outline: none;

          img {
            display: block;
          }

          .imgh {
            display: none;
          }

          .imgd {
            display: none;
          }

          span {
            display: block;
            opacity: 1;
          }

          .updated {
            display: block;
          }
        }
      }

      &__main {
        display: flex;
        width: 100%;
        height: 90%;

        &__text {
          width: 100%;
          height: 100%;

          &__modal {
            margin: 12px 0 0 0;
            border-top: 1px solid $secondary_grey;

            &__heading {
              width: 100%;
              @include flexAcenter;
              padding: 12px 0 8px 0;
              gap: 8px;

              h4 {
                font-size: 14px;
                font-weight: 500;
                text-align: start;
              }
              h5 {
                width: 28px;
                font-size: 11px;
                color: $primary_white;
                text-align: center;
                background-color: #f4c135;
                border-radius: 3px;
                font-weight: 200;
              }
            }

            &__sec {
              display: grid;
              grid-template-columns: repeat(1, 1fr);
              gap: 8px;

              &__sub {
                @include flexColumnACenter;
                background: $bg_grey_backround;
                border-radius: 8px;
                border: 1px solid $primary_grey;
                opacity: 0.6;
                cursor: pointer;

                span {
                  height: 40px;
                  font-size: 14px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }

                &.active {
                  opacity: 1;
                  border: 1px solid black;
                }
              }
            }
          }
          &__ratio {
            border-top: 1px solid $secondary_grey;
            margin: 12px 0 0 0;

            &__heading {
              width: 100%;
              @include flexAcenter;
              padding: 12px 0 8px 0;

              h4 {
                font-size: 14px;
                font-weight: 500;
                text-align: start;
              }
            }

            &__sec {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              gap: 4px;

              &__sub {
                height: 40px;
                @include flexColumnACenter;
                justify-content: center;
                background: $bg_grey_backround;
                border-radius: 8px;
                border: 1px solid $primary_grey;
                opacity: 0.6;
                cursor: pointer;

                h6 {
                  font-size: 14px;
                  color: $primary_blue;
                  font-weight: 500;
                }

                &.active {
                  opacity: 1;
                  border: 1px solid black;
                }
              }
            }
          }
          &__orientation {
            margin: 12px 0 0 0;
            border-top: 1px solid $secondary_grey;

            &__heading {
              width: 100%;
              @include flexAcenter;
              padding: 12px 0 8px 0;

              h4 {
                font-size: 14px;
                font-weight: 500;
                text-align: start;
              }
              h5 {
                width: 15%;
                margin-left: 10px;
                font-size: 11px;
                color: $primary_white;
                text-align: center;
                background-color: #f4c135;
                border-radius: 3px;
                font-weight: 200;
              }
            }

            &__sec {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              gap: 4px;

              &__sub {
                @include flexColumnACenter;
                background: $bg_grey_backround;
                border-radius: 8px;
                border: 1px solid $primary_grey;
                opacity: 0.6;
                cursor: pointer;
                span {
                  height: 40px;
                  font-size: 14px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }

                &.active {
                  opacity: 1;
                  border: 1px solid black;
                }
              }
            }
          }

          &__image-count {
            width: 100%;
            border-top: 1px solid $secondary_grey;
            margin: 12px 0 0 0;

            &__heading {
              width: 100%;
              @include flexAcenter;
              padding: 12px 0 8px 0;

              h4 {
                font-size: 14px;
                font-weight: 500;
                text-align: start;
              }

              .tooltip-top {
                @include flexAcenter;

                img {
                  width: 14px;
                  padding: 0 0 0 6px;
                }

                .tooltip-show {
                  display: none;
                }
              }

              .tooltip-top:hover {
                .tooltip-show {
                  display: block;
                }
              }
            }

            &__sec {
              width: 100%;
              display: grid;
              grid-template-columns: repeat(4, 1fr);
              gap: 6px;

              &__sub {
                height: 40px;
                @include flexCenter;
                background: $bg_grey_backround;
                border-radius: 8px;
                border: 1px solid $primary_grey;
                opacity: 0.6;
                cursor: pointer;

                h6 {
                  font-size: 14px;
                  color: $primary_blue;
                  font-weight: 500;
                }
              }

              &__sub.active {
                opacity: 1;
                border: 1px solid black;
              }
            }
            &__sec1 {
              display: grid;
              grid-template-columns: repeat(1, 1fr);
            }
          }
        }

        &__design {
          width: 100%;
          height: 100%;
          @include flexColumnACenter;

          &__base-img {
            margin: 12px 0 0 0;
            border-top: 1px solid $secondary_grey;
            width: 100%;

            &__heading {
              width: 100%;
              @include flexAcenter;
              padding: 12px 0 8px 0;

              h4 {
                font-size: 14px;
                font-weight: 500;
                text-align: start;
              }

              .tooltip-top {
                @include flexAcenter;

                img {
                  width: 14px;
                  padding: 0 0 0 6px;
                  opacity: 1;
                }

                .tooltip-show {
                  display: none;
                }
              }

              .tooltip-top:hover {
                .tooltip-show {
                  display: block;
                }
              }
            }

            .card {
              width: 198px;
              height: 198px;
              border-radius: $font_xverysmall;
              border: 1px solid $primary_grey;
              background: $bg_grey_backround;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            }

            .card.focus {
              border: 2px solid $primary_black;
            }

            label {
              width: 100%;
              height: 100%;
              @include flexCenter;
              overflow: hidden;
              cursor: pointer;
              position: relative;
              .refresh-icon {
                position: absolute;
                top: 5px;
                left: 5px;
                cursor: pointer;
                width: 24px;
                height: 24px;
                z-index: 1;
                img {
                  width: 24px;
                  height: 24px;
                }
                .refresh-active {
                  display: none;
                }
                .refresh-hover {
                  display: none;
                }
                &:hover {
                  .refresh-active {
                    display: none;
                  }
                  .refresh-hover {
                    display: block;
                  }
                  .refresh-default {
                    display: none;
                  }
                }
                &:active {
                  .refresh-active {
                    display: block;
                  }
                  .refresh-hover {
                    display: none;
                  }
                  .refresh-default {
                    display: none;
                  }
                }
              }
              .delete {
                position: absolute;
                top: 5px;
                right: 5px;
                cursor: pointer;
                width: 24px;
                height: 24px;
                z-index: 1;
              }
              .delete-icon {
                position: absolute;
                top: 5px;
                right: 5px;
                cursor: pointer;
                width: 24px;
                height: 24px;
                z-index: 1;
                img {
                  width: 24px;
                  height: 24px;
                }
                .delete-active {
                  display: none;
                }
                .delete-hover {
                  display: none;
                }
                &:hover {
                  .delete-active {
                    display: none;
                  }
                  .delete-hover {
                    display: block;
                  }
                  .delete-default {
                    display: none;
                  }
                }
                &:active {
                  .delete-active {
                    display: block;
                  }
                  .delete-hover {
                    display: none;
                  }
                  .delete-default {
                    display: none;
                  }
                }
              }
            }

            .card-content {
              position: relative;
              display: flex;
              align-items: center;
              width: 100%;
              height: 100%;
              flex-direction: column;
              justify-content: center;
              gap: 8px;
              //padding: 0 0 22px 0;
              height: -webkit-fill-available;

              &__sec {
                @include flexColumnACenter;
                justify-content: center;
                gap: 4px;
                width: 154px;
                height: 53px;
                .icon {
                  width: 24px;
                  height: 24px;
                  opacity: 1;
                }
                h5 {
                  color: $font_grey;
                  font-size: 14px;
                  font-weight: 400;
                }
                &.plugin {
                  border: 1px solid $font_grey;
                  border-radius: 8px;
                }
              }

              img {
                display: block;
                width: 100%;
                height: auto;
              }

              // .icon {
              //   width: 40px;
              //   height: 40px;
              // }

              // .icon.focus {
              //   width: 80px;
              //   height: 80px;
              // }

              .gradient-mask {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(to top, #000000cc, #00000000);
                backdrop-filter: blur(5px);
                pointer-events: none;
              }

              .text-display {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 14px;
                color: white;
              }

              p {
                font-size: 12px;
                font-weight: 400;
                color: $onhover_grey;
                padding: 0px 14px 0 14px;
                text-align: center;
              }
            }

            .card-content img {
              opacity: 0.4;
            }

            .uploaded {
              width: 100%;
              opacity: 1 !important;
              overflow: hidden;
              object-fit: contain;
            }

            .card-content span {
              margin-top: 10px;
              font-size: 18px;
            }
          }

          &__fidelity {
            width: 100%;
            padding: 4px 0 0 0;
            display: flex;
            flex-direction: column;

            &__heading {
              width: 100%;
              padding: 8px 0 0px 0;
              @include flexbetween;
              &__left {
                @include flexAcenter;
              }
              &__right {
                @include flexAcenter;
              }

              h4 {
                font-size: 14px;
                font-weight: 500;
                text-align: start;
              }

              .tooltip-top {
                @include flexAcenter;

                img {
                  width: 14px;
                  padding: 0 0 0 6px;
                  opacity: 1;
                }

                .tooltip-show {
                  display: none;
                }
              }

              .tooltip-top:hover {
                .tooltip-show {
                  display: block;
                }
              }
            }

            &__sec {
              display: flex;
              justify-content: center;
              width: 100%;

              &__sub {
                width: 100%;
                @include textc;

                .ant-slider-horizontal {
                  margin: 10px 5px 0 5px !important;
                }
              }

              .span {
                width: 100%;
                display: flex;
                justify-content: center;
                opacity: 0.6;
                font-size: 12px;
              }

              .span.left {
                justify-content: flex-start;
              }

              .span.right {
                justify-content: flex-end;
              }
            }
          }

          &__resolution {
            width: 100%;
            margin: 12px 0 0 0;
            border-top: 1px solid $secondary_grey;
            display: flex;
            flex-direction: column;

            &__heading {
              width: 100%;
              padding: 12px 0 0 0;
              @include flexbetween;
              &__left {
                @include flexAcenter;
              }
              &__right {
                @include flexAcenter;
              }

              h4 {
                font-size: 14px;
                font-weight: 500;
                text-align: start;
              }

              span {
                font-size: 11px;
                background: $primary_blue;
                color: $primary_white;
                width: 28px;
                height: 12px;
                line-height: 12px;
                @include flexCenter;
                border-radius: 2px;
                margin: 0 4px;
              }

              .tooltip-top {
                @include flexAcenter;

                img {
                  width: 14px;
                  padding: 0 0 0 6px;
                  opacity: 1;
                }

                .tooltip-show {
                  display: none;
                }
              }

              .tooltip-top:hover {
                .tooltip-show {
                  display: block;
                }
              }
            }

            &__section {
              width: 100%;
              padding: 8px 0 0 0;
              &__heading {
                padding: 4px;
                border: 1px solid $primary_grey;
                background: #f8f8f9;
                border-radius: 23px;
                @include flexbetween;
                &__sec {
                  padding: 4px;
                  cursor: pointer;
                  h5 {
                    font-size: 12px;
                    font-weight: 400;
                  }
                }
                .ch {
                  padding: 4px 12px;
                }
                &__sec.active {
                  background: $primary_blue;
                  border-radius: 20px;
                  h5 {
                    color: $primary_white;
                  }
                }
              }
              h6 {
                font-size: 12px;
                font-weight: 400;
                padding: 8px 0 0 0;
              }
            }
          }

          .camera-main {
            flex-direction: column;
          }
          .camera-main-heading {
            width: 100%;
            justify-content: flex-start;
          }
          .sec-main {
            padding: 0;
            width: 45%;
          }
          &__camera {
            width: 100%;
            @include flexAcenter;
            justify-content: space-between;
            margin: 8px 0 0 0;

            &__heading {
              width: 50%;
              @include flexAcenter;
              justify-content: space-between;
              padding: 12px 0 8px 0;

              h4 {
                font-size: 12px;
                font-weight: 500;
                text-align: start;
              }

              .tooltip-top {
                @include flexAcenter;

                img {
                  width: 14px;
                  padding: 0 0 0 6px;
                }

                .tooltip-show {
                  display: none;
                }
              }

              .tooltip-top:hover {
                .tooltip-show {
                  display: block;
                }
              }
            }

            &__section {
              &__main {
                width: 100%;
                display: flex;
                justify-content: space-between;
              }
              width: 50%;
            }
          }

          &__imagetype {
            width: 100%;
            &__heading {
              padding: 4px;
              border: 1px solid $primary_grey;
              background: #f8f8f9;
              border-radius: 23px;
              @include flexbetween;
              &__sec {
                padding: 4px 8px;
                cursor: pointer;
                h5 {
                  font-size: 12px;
                  font-weight: 400;
                }
              }
              .ch {
                padding: 4px 24px;
              }
              &__sec.active {
                background: $primary_blue;
                border-radius: 20px;
                h5 {
                  color: $primary_white;
                }
              }
            }
            &__slider {
              text-align: center;
            }
          }

          &__image-count {
            width: 100%;
            border-top: 1px solid $secondary_grey;
            margin: 12px 0 0 0;

            &__heading {
              width: 100%;
              @include flexAcenter;
              padding: 12px 0 8px 0;

              h4 {
                font-size: 14px;
                font-weight: 500;
                text-align: start;
              }

              .tooltip-top {
                @include flexAcenter;

                img {
                  width: 14px;
                  padding: 0 0 0 6px;
                }

                .tooltip-show {
                  display: none;
                }
              }

              .tooltip-top:hover {
                .tooltip-show {
                  display: block;
                }
              }
            }

            &__sec {
              display: grid;
              grid-template-columns: repeat(4, 1fr);
              gap: 6px;

              &__sub {
                height: 40px;
                @include flexCenter;
                background: $bg_grey_backround;
                border-radius: 8px;
                border: 1px solid $primary_grey;
                opacity: 0.6;
                cursor: pointer;

                &.active {
                  opacity: 1;
                  border: 1px solid black;
                }
                &.disable {
                  opacity: 0.4;
                  // border: 1px solid black;
                  cursor: not-allowed;
                }
              }

              h6 {
                font-size: 14px;
                color: $primary_blue;
                font-weight: 500;
              }
            }
          }
        }

        &__imagec {
          width: 100%;
          height: 100%;
          @include flexColumnACenter;

          &__base-img {
            margin: 12px 0 0 0;
            border-top: 1px solid $secondary_grey;
            width: 100%;

            &__heading {
              width: 100%;
              @include flexAcenter;
              padding: 12px 0 8px 0;

              h4 {
                font-size: 14px;
                font-weight: 500;
                text-align: start;
              }

              .tooltip-top {
                @include flexAcenter;

                img {
                  width: 14px;
                  padding: 0 0 0 6px;
                  opacity: 1;
                }

                .tooltip-show {
                  display: none;
                }
              }

              .tooltip-top:hover {
                .tooltip-show {
                  display: block;
                }
              }
            }

            .card {
              width: 198px;
              height: 198px;
              border-radius: $font_xverysmall;
              border: 1px solid $primary_grey;
              background: $bg_grey_backround;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            }

            .card.focus {
              border: 2px solid $primary_black;
            }

            label {
              width: 100%;
              height: 100%;
              @include flexCenter;
              overflow: hidden;
              cursor: pointer;
              position: relative;
              .refresh-icon {
                position: absolute;
                top: 5px;
                left: 5px;
                cursor: pointer;
                width: 24px;
                height: 24px;
                z-index: 1;
                img {
                  width: 24px;
                  height: 24px;
                }
                .refresh-active {
                  display: none;
                }
                .refresh-hover {
                  display: none;
                }
                &:hover {
                  .refresh-active {
                    display: none;
                  }
                  .refresh-hover {
                    display: block;
                  }
                  .refresh-default {
                    display: none;
                  }
                }
                &:active {
                  .refresh-active {
                    display: block;
                  }
                  .refresh-hover {
                    display: none;
                  }
                  .refresh-default {
                    display: none;
                  }
                }
              }
              .delete-icon {
                position: absolute;
                top: 5px;
                right: 5px;
                cursor: pointer;
                width: 24px;
                height: 24px;
                z-index: 1;
                img {
                  width: 24px;
                  height: 24px;
                }
                .delete-active {
                  display: none;
                }
                .delete-hover {
                  display: none;
                }
                &:hover {
                  .delete-active {
                    display: none;
                  }
                  .delete-hover {
                    display: block;
                  }
                  .delete-default {
                    display: none;
                  }
                }
                &:active {
                  .delete-active {
                    display: block;
                  }
                  .delete-hover {
                    display: none;
                  }
                  .delete-default {
                    display: none;
                  }
                }
              }
            }

            .card-content {
              position: relative;
              display: flex;
              align-items: center;
              width: 100%;
              height: 100%;
              flex-direction: column;
              justify-content: center;
              gap: 8px;
              //padding: 0 0 22px 0;
              height: -webkit-fill-available;

              &__sec {
                @include flexColumnACenter;
                justify-content: center;
                gap: 4px;
                width: 154px;
                height: 53px;
                .icon {
                  width: 24px;
                  height: 24px;
                  opacity: 1;
                }
                h5 {
                  color: $font_grey;
                  font-size: 14px;
                  font-weight: 400;
                }
                &.plugin {
                  border: 1px solid $font_grey;
                  border-radius: 8px;
                }
              }

              img {
                display: block;
                width: 100%;
                height: auto;
              }

              // .icon {
              //   width: 40px;
              //   height: 40px;
              // }

              // .icon.focus {
              //   width: 80px;
              //   height: 80px;
              // }

              .gradient-mask {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(to top, #000000cc, #00000000);
                backdrop-filter: blur(5px);
                pointer-events: none;
              }

              .text-display {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 14px;
                color: white;
              }

              p {
                font-size: 12px;
                font-weight: 400;
                color: $onhover_grey;
                padding: 0px 28px 0 28px;
                text-align: center;
              }
            }

            .card-content img {
              opacity: 0.4;
            }

            .uploaded {
              width: 100%;
              opacity: 1 !important;
              overflow: hidden;
              object-fit: contain;
            }

            .card-content span {
              margin-top: 10px;
              font-size: 18px;
            }
          }

          &__selection {
            width: 100%;
            padding: 12px 0 0 0;
            @include flexJCenter;

            &__main {
              width: 100%;
            }

            &__sub {
              width: 100%;
              @include flexbetween;
              align-items: flex-start;
              padding: 0 8px;

              &__sec {
                @include flexColumnACenter;
                border-width: 0px;
                background-color: transparent;
                padding: 4px;
                border-radius: 20px;
                justify-content: center;
                min-width: 64px;
                h5 {
                  font-size: 14px;
                  font-weight: 600;
                  padding: 4px 0 0 0;
                  opacity: 0.4;
                }

                cursor: pointer;

                img {
                  width: 24px;
                  height: 24px;
                  display: none;
                }

                .imgd {
                  display: block;
                }
              }

              &__sec.active {
                .imga {
                  display: block;
                }
                .imgd {
                  display: none;
                }
                h5 {
                  opacity: 1;
                }
              }
              &__sec:hover {
                .imgh {
                  display: block;
                }
                .imgd {
                  display: none;
                }
                .imga {
                  display: none;
                }
                h5 {
                  opacity: 0.7;
                }
              }
            }
          }

          &__fidelity {
            width: 100%;
            padding: 0 0 4px 0;

            &__heading {
              width: 100%;
              padding: 4px;
              align-items: center;
              padding: 8px 0 0px 0;
              @include flexbetween;
              cursor: pointer;

              &__left {
                @include flexAcenter;
              }

              &__right {
                @include flexAcenter;
              }

              h4 {
                font-size: 14px;
                font-weight: 500;
                text-align: start;
              }

              .tooltip-top {
                @include flexAcenter;

                img {
                  width: 14px;
                  padding: 0 0 0 6px;
                  opacity: 1;
                }

                .tooltip-show {
                  display: none;
                }
              }

              .tooltip-top:hover {
                .tooltip-show {
                  display: block;
                }
              }
            }

            &__sec {
              display: flex;
              justify-content: center;
              width: 100%;

              &__sub {
                width: 100%;
                @include textc;

                .ant-slider-horizontal {
                  margin: 10px 5px 0 5px !important;
                }
              }

              .span {
                width: 100%;
                display: flex;
                justify-content: center;
                opacity: 0.6;
                font-size: 12px;
              }

              .span.left {
                justify-content: flex-start;
              }

              .span.right {
                justify-content: flex-end;
              }
            }
          }

          &__CNSwitch {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 0 8px 0;

            &__heading {
              display: flex;

              h4 {
                font-size: 14px;
                font-weight: 500;
              }

              .tooltip-top {
                @include flexAcenter;

                img {
                  width: 14px;
                  padding: 0 0 0 6px;
                  opacity: 1;
                }

                .tooltip-show {
                  display: none;
                }
              }

              .tooltip-top:hover {
                .tooltip-show {
                  display: block;
                }
              }
            }

            &__sec1 {
              display: flex;
              align-items: center;

              &__sub1 {
                display: flex;
                align-items: center;

                span {
                  font-size: 12px;
                  padding: 0 0 0 6px;
                }

                .small-on {
                  .react-switch-handle {
                    transform: translate(15px) !important;
                  }
                }

                .small-off {
                  .react-switch-handle {
                    transform: translate(2px) !important;
                  }
                }
              }
            }
          }

          &__mask {
            width: 100%;

            &__heading {
              border-top: 1px solid #d4d6d7;
              width: 100%;
              @include flexAcenter;
              justify-content: space-between;
              margin-top: 12px;
              padding: 12px 0 8px 0;

              &__leftSide {
                display: flex;
              }

              h4 {
                font-size: 14px;
                font-weight: 500;
                text-align: start;
              }

              button {
                border-radius: 12px;
                background-color: $idle_black;
                padding: 0px 6px;

                h4 {
                  font-size: 12px;
                  color: $primary_white;
                }
              }

              button:hover {
                background-color: $onhover_grey;
              }

              button:active {
                background-color: $clicked_grey;
                /* A more noticeable change for the active state */
              }

              button:disabled {
                background-color: $primary_white;
                border: 1px solid $primary_grey;
                padding: 4px 7px;

                h4 {
                  font-size: 12px;
                  color: $primary_black;
                }
              }

              .tooltip-top {
                @include flexAcenter;

                img {
                  width: 14px;
                  padding: 0 0 0 6px;
                  opacity: 1;
                }

                .tooltip-show {
                  display: none;
                }
              }

              .tooltip-top:hover {
                .tooltip-show {
                  display: block;
                }
              }
            }

            .card {
              width: 198px;
              height: 200px;
              border-radius: $font_xverysmall;
              border: 1px solid $primary_grey;
              background: $bg_grey_backround;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            }

            label {
              width: 100%;
              height: 100%;
              @include flexCenter;
              cursor: pointer;
              overflow: hidden;
              position: relative;
              .refresh-icon {
                position: absolute;
                top: 5px;
                left: 5px;
                cursor: pointer;
                width: 24px;
                height: 24px;
                z-index: 1;
                img {
                  width: 24px;
                  height: 24px;
                }
                .refresh-active {
                  display: none;
                }
                .refresh-hover {
                  display: none;
                }
                &:hover {
                  .refresh-active {
                    display: none;
                  }
                  .refresh-hover {
                    display: block;
                  }
                  .refresh-default {
                    display: none;
                  }
                }
                &:active {
                  .refresh-active {
                    display: block;
                  }
                  .refresh-hover {
                    display: none;
                  }
                  .refresh-default {
                    display: none;
                  }
                }
              }
              .delete-icon {
                position: absolute;
                top: 5px;
                right: 5px;
                cursor: pointer;
                width: 24px;
                height: 24px;
                z-index: 1;
                img {
                  width: 24px;
                  height: 24px;
                }
                .delete-active {
                  display: none;
                }
                .delete-hover {
                  display: none;
                }
                &:hover {
                  .delete-active {
                    display: none;
                  }
                  .delete-hover {
                    display: block;
                  }
                  .delete-default {
                    display: none;
                  }
                }
                &:active {
                  .delete-active {
                    display: block;
                  }
                  .delete-hover {
                    display: none;
                  }
                  .delete-default {
                    display: none;
                  }
                }
              }
            }

            .uploaded {
              width: 100%;
              opacity: 1 !important;
              overflow: hidden;
              object-fit: contain;
            }

            .card-content {
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 100%;
              flex-direction: column;

              img {
                display: block;
                width: 100%;
                height: auto;
              }

              .icon {
                width: 40px;
                height: 40px;
              }

              .gradient-mask {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(to top, #000000cc, #00000000);
                backdrop-filter: blur(5px);
                pointer-events: none;
              }

              .text-display {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 14px;
                color: white;
              }
            }

            .card-content img {
              opacity: 0.4;
            }

            .card-content span {
              margin-top: 10px;
              font-size: 18px;
            }

            &__sec {
              @include flexJCenter;

              &__image {
                width: 100%;

                img {
                  width: 100%;
                }
              }
            }
          }

          &__imagetype {
            width: 100%;
            &__heading {
              padding: 4px;
              border: 1px solid $primary_grey;
              background: #f8f8f9;
              border-radius: 23px;
              @include flexbetween;
              &__sec {
                padding: 4px 8px;
                cursor: pointer;
                h5 {
                  font-size: 12px;
                  font-weight: 400;
                }
              }
              .ch {
                padding: 4px 20px;
              }
              &__sec.active {
                background: $primary_blue;
                border-radius: 20px;
                h5 {
                  color: $primary_white;
                }
              }
            }
            &__slider {
              text-align: center;
            }
          }

          &__fidelity1 {
            width: 100%;
            padding: 4px 0 8px 0;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            &__heading {
              width: 50%;
              @include flexAcenter;
              justify-content: space-between;
              padding: 8px 0 0px 0;

              h4 {
                font-size: 12px;
                font-weight: 500;
                text-align: start;
              }

              .tooltip-top {
                @include flexAcenter;

                img {
                  width: 14px;
                  padding: 0 0 0 6px;
                  opacity: 1;
                }

                .tooltip-show {
                  display: none;
                }
              }

              .tooltip-top:hover {
                .tooltip-show {
                  display: block;
                }
              }
            }

            &__sec {
              display: flex;
              justify-content: center;
              width: 45%;
              padding: 0 0 0 4px;

              &__sub {
                width: 100%;
                @include textc;

                .ant-slider-horizontal {
                  margin: 10px 5px 0 5px !important;
                }
              }

              .span {
                width: 100%;
                display: flex;
                justify-content: center;
                opacity: 0.6;
                font-size: 12px;
              }

              .span.left {
                justify-content: flex-start;
              }

              .span.right {
                justify-content: flex-end;
              }
            }
          }

          &__image-count {
            width: 100%;
            border-top: 1px solid $secondary_grey;
            margin: 8px 0 0 0;

            &__heading {
              width: 100%;
              @include flexAcenter;
              padding: 12px 0 8px 0;

              h4 {
                font-size: 14px;
                font-weight: 500;
                text-align: start;
              }

              .tooltip-top {
                @include flexAcenter;

                img {
                  width: 14px;
                  padding: 0 0 0 6px;
                }

                .tooltip-show {
                  display: none;
                }
              }

              .tooltip-top:hover {
                .tooltip-show {
                  display: block;
                }
              }
            }

            &__sec {
              display: grid;
              grid-template-columns: repeat(4, 1fr);
              gap: 6px;

              &__sub {
                height: 40px;
                @include flexCenter;
                background: $bg_grey_backround;
                border-radius: 8px;
                border: 1px solid $primary_grey;
                opacity: 0.6;
                cursor: pointer;
              }

              h6 {
                font-size: 14px;
                color: $primary_blue;
                font-weight: 500;
              }

              &__sub.active {
                opacity: 1;
                border: 1px solid black;
              }
            }
          }
        }

        &__upscale {
          width: 100%;
          @include flexColumnACenter;

          &__base-img {
            width: 100%;

            &__heading {
              border-top: 1px solid #d4d6d7;
              width: 100%;
              @include flexAcenter;
              margin-top: 12px;
              padding: 12px 0 8px 0;

              h4 {
                font-size: 14px;
                font-weight: 500;
                text-align: start;
              }

              .tooltip-top {
                @include flexAcenter;

                img {
                  width: 14px;
                  padding: 0 0 0 6px;
                  opacity: 1;
                }

                .tooltip-show {
                  display: none;
                }
              }

              .tooltip-top:hover {
                .tooltip-show {
                  display: block;
                }
              }
            }

            .card {
              width: 198px;
              height: 198px;
              border-radius: $font_xverysmall;
              border: 1px solid $primary_grey;
              background: $bg_grey_backround;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            }

            .card.focus {
              border: 2px solid $primary_black;
            }

            label {
              width: 100%;
              height: 100%;
              @include flexCenter;
              cursor: pointer;
              overflow: hidden;
            }

            .uploaded {
              width: 100%;
              opacity: 1 !important;
              overflow: hidden;
              object-fit: cover;
            }

            .image-back {
              @include flexCenter;
              background: #ff4d503d;
              max-width: 50%;
              max-height: 50%;
            }

            .card-content {
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 100%;
              flex-direction: column;

              img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: contain;
              }

              .icon {
                width: 40px;
                height: 40px;
              }

              .icon.focus {
                width: 80px;
                height: 80px;
              }

              p {
                font-size: 12px;
                font-weight: 400;
                color: $onhover_grey;
                padding: 12px 14px 0 14px;
                text-align: center;
              }

              .gradient-mask {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(to top, #000000cc, #00000000);
                backdrop-filter: blur(5px);
                pointer-events: none;
              }

              .text-display {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 14px;
                color: white;
              }
            }

            .card-content img {
              opacity: 0.4;
            }

            .card-content span {
              margin-top: 10px;
              font-size: 18px;
            }
          }

          .ant-slider-mark {
            display: none !important;
          }
          .ant-slider-with-marks {
            margin: 8px 0 !important;
          }

          &__selection {
            width: 100%;
            // margin: 12px 0 0 0;
            padding: 16px 0 0 0;
            // border-top: 1px solid $secondary_grey;
            @include flexJCenter;

            &__main {
              width: 100%;
            }

            &__sub {
              width: 100%;
              display: flex;
              justify-content: space-evenly;
              align-items: flex-start;

              &__sec {
                width: 33%;
                @include flexColumnACenter;
                border-width: 0px;
                background-color: transparent;
                padding: 4px;
                border-radius: 20px;
                justify-content: center;
                h5 {
                  font-size: 14px;
                  font-weight: 600;
                  padding: 4px 0 0 0;
                  opacity: 0.4;
                }
                .updated {
                  display: flex !important;
                  position: relative;
                  top: -30px;
                  right: -12px;
                  width: 28px;
                  height: 12px;
                  background: $primary_blue;
                  color: $primary_white;
                  @include flexCenter;
                  border-radius: 2px;
                  z-index: 1;
                }

                cursor: pointer;

                img {
                  width: 24px;
                  height: 24px;
                  display: none;
                }

                .imgd {
                  display: block;
                }
              }
              &__sec.active {
                .imga {
                  display: block;
                }
                .imgd {
                  display: none;
                }
                h5 {
                  opacity: 1;
                }
              }
              &__sec:hover {
                .imgh {
                  display: block;
                }
                .imgd {
                  display: none;
                }
                .imga {
                  display: none;
                }
                h5 {
                  opacity: 0.7;
                }
              }
            }
          }

          &__hd {
            width: 100%;
            margin: 16px 0 0 0;
            &__options {
              @include flexcolumn;
              gap: 6px;

              &__sec {
                height: 38px;
                border: 1px solid $primary_grey;
                @include flexCenter;
                border-radius: 8px;
                cursor: pointer;
                background: $bg_grey_backround;
                opacity: 0.7;

                &.active {
                  border: 1px solid $primary_blue;
                  opacity: 1;
                }

                h5 {
                  font-size: 12px;
                  font-weight: 400;
                }
              }
            }
          }

          &__extend {
            &__slider {
              width: 100%;
              @include flexColumnACenter;
              .ant-slider {
                width: 100% !important;
              }
              :where(.ant-slider-track) {
                background: transparent !important;
              }

              :where(.ant-slider-rail) {
                background: $primary_grey !important;
              }
              span {
                font-size: 12px;
                text-align: center;
              }
            }
          }
        }

        &__video-ai {
          width: 100%;
          height: 100%;
          @include flexColumnACenter;

          &__base-img {
            margin: 12px 0 0 0;
            border-top: 1px solid $secondary_grey;
            width: 100%;

            &__heading {
              width: 100%;
              @include flexAcenter;
              padding: 12px 0 8px 0;

              h4 {
                font-size: 14px;
                font-weight: 500;
                text-align: start;
              }

              .tooltip-top {
                @include flexAcenter;

                img {
                  width: 14px;
                  padding: 0 0 0 6px;
                  opacity: 1;
                }

                .tooltip-show {
                  display: none;
                }
              }

              .tooltip-top:hover {
                .tooltip-show {
                  display: block;
                }
              }
            }

            .card {
              width: 198px;
              height: 198px;
              border-radius: $font_xverysmall;
              border: 1px solid $primary_grey;
              background: $bg_grey_backround;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            }

            .card.focus {
              border: 2px solid $primary_black;
            }

            label {
              width: 100%;
              height: 100%;
              @include flexCenter;
              overflow: hidden;
              cursor: pointer;
            }

            .card-content {
              position: relative;
              display: flex;
              align-items: center;
              width: 100%;
              height: 100%;
              flex-direction: column;
              justify-content: center;
              //padding: 0 0 22px 0;
              height: -webkit-fill-available;

              img {
                display: block;
                width: 100%;
                height: auto;
              }

              .icon {
                width: 40px;
                height: 40px;
              }

              .icon.focus {
                width: 60px;
                height: 60px;
              }

              .gradient-mask {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(to top, #000000cc, #00000000);
                backdrop-filter: blur(5px);
                pointer-events: none;
              }

              .text-display {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 14px;
                color: white;
              }

              p {
                font-size: 12px;
                font-weight: 400;
                color: $onhover_grey;
                padding: 0 12px;
                text-align: center;
              }
              .first {
                padding: 12px 16px 0 16px;
              }
            }

            .card-content img {
              opacity: 0.4;
            }

            .uploaded {
              width: 100%;
              opacity: 1 !important;
              overflow: hidden;
              object-fit: contain;
            }

            .card-content span {
              margin-top: 10px;
              font-size: 18px;
            }
          }
          p {
            color: red;
            font-size: 12px;
            padding: 12px 0 0 0;
          }

          &__tip {
            width: 100%;
            padding: 16px 0 0 0;
            display: flex;
            gap: 4px;
            img {
              margin: 2px 0 0 0;
              width: 14px;
              height: 14px;
            }
            h5 {
              font-size: 12px;
              font-weight: 400;
            }
          }

          &__camera {
            width: 100%;
            padding: 12px 0 0 0;
            @include flexcolumn;
            gap: 8px;
            &__heading {
              width: 100%;
              @include flexAcenter;
              justify-content: space-between;
              cursor: pointer;
              &__left {
                h4 {
                  font-size: 14px;
                  font-weight: 500;
                  text-align: start;
                }

                .tooltip-top {
                  @include flexAcenter;

                  img {
                    width: 14px;
                    padding: 0 0 0 6px;
                    opacity: 1;
                  }

                  .tooltip-show {
                    display: none;
                  }
                }

                .tooltip-top:hover {
                  .tooltip-show {
                    display: block;
                  }
                }
              }
              &__right {
                @include flexAcenter;
                .small-on {
                  .react-switch-handle {
                    transform: translate(15px) !important;
                  }
                }

                .small-off {
                  .react-switch-handle {
                    transform: translate(2px) !important;
                  }
                }
              }
            }

            &__options {
              @include flexcolumn;
              gap: 4px;
              &__heading {
                width: 100%;
                @include flexAcenter;

                h4 {
                  font-size: 12px;
                  font-weight: 400;
                  text-align: start;
                }

                .tooltip-top {
                  @include flexAcenter;

                  img {
                    width: 14px;
                    padding: 0 0 0 6px;
                    opacity: 1;
                  }

                  .tooltip-show {
                    display: none;
                  }
                }

                .tooltip-top:hover {
                  .tooltip-show {
                    display: block;
                  }
                }
              }
              &__main {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 6px;
              }
              &__sec {
                @include flexCenter;
                background: $bg_grey_backround;
                height: 38px;
                border-radius: 8px;
                border: 1px solid $primary_grey;
                cursor: pointer;
                &.active {
                  border: 1px solid $primary_blue;
                  h5 {
                    color: $primary_blue;
                  }
                }
                &.disable {
                  opacity: 0.6;
                  cursor: default;
                }
                h5 {
                  font-size: 12px;
                  font-weight: 400;
                  color: $clicked_grey;
                }
              }
            }
          }
        }

        &__CNimageSec {
          width: 100%;
          @include flexColumnACenter;

          &__base-img {
            width: 100%;

            &__heading {
              width: 100%;
              @include flexAcenter;
              padding: 0 0 4px 0;

              h4 {
                font-size: 14px;
                font-weight: 500;
                text-align: start;
              }

              .tooltip-top {
                @include flexAcenter;

                img {
                  width: 14px;
                  padding: 0 0 0 6px;
                  opacity: 1;
                }

                .tooltip-show {
                  display: none;
                }
              }

              .tooltip-top:hover {
                .tooltip-show {
                  display: block;
                }
              }
            }

            .card {
              width: 198px;
              height: 198px;
              border-radius: $font_xverysmall;
              border: 1px solid $primary_grey;
              background: $bg_grey_backround;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            }

            .card.focus {
              border: 2px solid $primary_black;
            }

            label {
              width: 100%;
              height: 100%;
              @include flexCenter;
              overflow: hidden;
              cursor: pointer;
              position: relative;
              .refresh-icon {
                position: absolute;
                top: 5px;
                left: 5px;
                cursor: pointer;
                width: 24px;
                height: 24px;
                z-index: 1;
                img {
                  width: 24px;
                  height: 24px;
                }
                .refresh-active {
                  display: none;
                }
                .refresh-hover {
                  display: none;
                }
                &:hover {
                  .refresh-active {
                    display: none;
                  }
                  .refresh-hover {
                    display: block;
                  }
                  .refresh-default {
                    display: none;
                  }
                }
                &:active {
                  .refresh-active {
                    display: block;
                  }
                  .refresh-hover {
                    display: none;
                  }
                  .refresh-default {
                    display: none;
                  }
                }
              }
              .delete-icon {
                position: absolute;
                top: 5px;
                right: 5px;
                cursor: pointer;
                width: 24px;
                height: 24px;
                z-index: 1;
                img {
                  width: 24px;
                  height: 24px;
                }
                .delete-active {
                  display: none;
                }
                .delete-hover {
                  display: none;
                }
                &:hover {
                  .delete-active {
                    display: none;
                  }
                  .delete-hover {
                    display: block;
                  }
                  .delete-default {
                    display: none;
                  }
                }
                &:active {
                  .delete-active {
                    display: block;
                  }
                  .delete-hover {
                    display: none;
                  }
                  .delete-default {
                    display: none;
                  }
                }
              }
            }

            .card-content {
              position: relative;
              display: flex;
              align-items: center;
              width: 100%;
              height: 100%;
              flex-direction: column;
              justify-content: center;
              gap: 8px;
              //padding: 0 0 22px 0;
              height: -webkit-fill-available;

              &__sec {
                @include flexColumnACenter;
                justify-content: center;
                gap: 4px;
                width: 154px;
                height: 53px;
                .icon {
                  width: 24px;
                  height: 24px;
                  opacity: 1;
                }
                h5 {
                  color: $font_grey;
                  font-size: 14px;
                  font-weight: 400;
                }
                &.plugin {
                  border: 1px solid $font_grey;
                  border-radius: 8px;
                }
              }

              img {
                display: block;
                width: 100%;
                height: auto;
              }

              // .icon {
              //   width: 40px;
              //   height: 40px;
              // }

              // .icon.focus {
              //   width: 80px;
              //   height: 80px;
              // }

              .gradient-mask {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(to top, #000000cc, #00000000);
                backdrop-filter: blur(5px);
                pointer-events: none;
              }

              .text-display {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 14px;
                color: white;
              }

              p {
                font-size: 12px;
                font-weight: 400;
                color: $onhover_grey;
                padding: 0px 20px 0 20px;
                text-align: center;
              }
            }

            .card-content img {
              opacity: 0.4;
            }

            .uploaded {
              width: 100%;
              opacity: 1 !important;
              overflow: hidden;
              object-fit: contain;
            }

            .card-content span {
              margin-top: 10px;
              font-size: 18px;
            }
          }

          &__ratio {
            width: 100%;

            &__heading {
              width: 100%;
              @include flexAcenter;
              padding: 20px 0 8px 0;

              h4 {
                font-size: 14px;
                font-weight: 500;
                text-align: start;
              }
            }

            &__sec {
              &__sub {
                @include textc;
                border-radius: $font_xverysmall;
                border: 1px solid $primary_black;
                background: $bg_light_grey;
                padding: $font_xverysmall 0;
              }
            }
          }
        }
      }
    }

    // ::-webkit-scrollbar-thumb {
    //   background: #ffffff;
    // }
    // ::-webkit-scrollbar-thumb:hover {
    //   background: #ffffff;
    // }
  }
}

@media screen and (min-width: 768px) and (max-width: 1090px) {
  .toolset {
    width: 156px;
    top: 36px;
    height: calc(var(--vh, 1vh) * 100 - 36px - 24px);

    &__sections {
      height: calc(100% - 24px);
      padding: 12px 0px 12px 0px;
    }

    &__section {
      padding: 0 8px 0 12px;

      &__tools {
        &__heading {
          padding: 0 0 12px 0;

          h4 {
            font-size: 14px;
          }
        }

        &__tab {
          img {
            width: 20px;
            height: 20px;
          }
        }

        .react-tabs {
          &__tab {
            .updated {
              right: -8px;
            }
            span {
              font-size: 10px;
              padding: 4px 0 0 0;
            }
          }
        }

        &__main {
          &__text {
            padding: 0 0 24px 0;

            &__modal {
              margin: 8px 0 0 0;
              &__heading {
                padding: 8px 0 4px 0;

                h4 {
                  font-size: 12px;
                }
              }

              &__sec {
                gap: 4px;
                &__sub {
                  padding: 0;
                  justify-content: center;
                  border-radius: 4px;

                  span {
                    height: 32px;
                    font-size: 12px;
                  }
                }
              }
            }

            &__ratio {
              margin: 8px 0 0 0;
              &__heading {
                padding: 8px 0 4px 0;

                h4 {
                  font-size: 12px;
                }
              }

              &__sec {
                &__sub {
                  padding: 0;
                  height: 48px;
                  justify-content: center;
                  border-radius: 4px;

                  &__section {
                    h6 {
                      font-size: 12px;
                    }
                  }
                }
              }
            }

            &__orientation {
              margin: 8px 0 0 0;
              &__heading {
                padding: 8px 0 4px 0;

                h4 {
                  font-size: 12px;
                }
              }

              &__sec {
                gap: 4px;
                &__sub {
                  padding: 0;
                  justify-content: center;
                  border-radius: 4px;

                  span {
                    font-size: 12px;
                    height: 32px;
                  }
                }
              }
            }

            &__image-count {
              margin: 8px 0 0 0;
              &__heading {
                padding: 8px 0 4px 0;

                h4 {
                  font-size: 12px;
                }
              }

              &__sec {
                gap: 4px;
                &__sub {
                  margin: 0 1.3px 0 0;
                  height: 22px;
                  border-radius: 4px;
                }
              }
            }
          }

          &__design {
            padding: 0 0 24px 0;

            &__base-img {
              margin: 8px 0 0 0;
              &__heading {
                padding: 8px 0 4px 0;

                h4 {
                  font-size: 12px;
                }

                .tooltip-top {
                  img {
                    width: 12px;
                  }
                }
              }

              .card {
                width: 130px;
                height: 130px;
              }

              .card-content {
                justify-content: center;
                padding: 0 !important;
                gap: 4px;

                &__sec {
                  @include flexColumnACenter;
                  justify-content: center;
                  gap: 0px;
                  border: 1px solid $font_grey;
                  width: 120px;
                  height: 38px;
                  border-radius: 8px;
                  .icon {
                    width: 16px;
                    height: 16px;
                  }
                  h5 {
                    color: $font_grey;
                    font-size: 11px;
                    font-weight: 400;
                  }
                }

                // .icon {
                //   width: 24px;
                //   height: 24px;
                // }

                p {
                  font-size: 10px;
                  padding: 0 8px;
                }
              }
            }

            &__imagetype {
              &__heading {
                padding: 2px;
                &__sec {
                  padding: 2px;
                  h5 {
                    font-size: 9px;
                  }
                }
                .ch {
                  padding: 2px 10px;
                }
              }
            }

            &__fidelity {
              padding: 8px 0 0 0;
              &__heading {
                padding: 0 0 4px 0;

                h4 {
                  font-size: 11px;
                }

                .tooltip-top {
                  img {
                    width: 12px;
                  }
                }
              }

              &__sec {
                .span {
                  font-size: 10px;
                }
              }
            }

            &__resolution {
              margin: 8px 0 0 0;
              &__heading {
                padding: 8px 0 0 0;
                h4 {
                  font-size: 11px;
                }
                span {
                  font-size: 11px;
                }
              }
              &__section {
                &__heading {
                  flex-direction: column;
                  border-radius: 8px;
                  &__sec {
                    padding: 4px 8px;
                    h5 {
                      font-size: 11px;
                    }
                  }
                }
                h6 {
                  font-size: 10px;
                }
              }
            }

            &__image-count {
              &__heading {
                padding: 12px 0 4px 0;

                h4 {
                  font-size: 12px;
                }
              }

              &__sec {
                gap: 4px;
                &__sub {
                  height: 22px;
                  border-radius: 4px;
                }
              }
            }
          }

          &__imagec {
            padding: 0 0 24px 0;

            &__selection {
              padding: 8px 0 0 0;
              &__sub {
                padding: 0 4px;
                &__sec {
                  padding: 2px;
                  min-width: 0;
                  img {
                    width: 18px;
                    height: 18px;
                  }

                  h5 {
                    font-size: 10px;
                  }
                }
              }
            }

            &__fidelity {
              &__heading {
                padding: 8px 0 4px 0;

                h4 {
                  font-size: 12px;
                }

                .tooltip-top {
                  img {
                    width: 12px;
                  }
                }
              }

              &__sec {
                .span {
                  font-size: 10px;
                }
              }
            }

            &__base-img {
              &__heading {
                padding: 8px 0 4px 0;

                h4 {
                  font-size: 12px;
                }

                .tooltip-top {
                  img {
                    width: 12px;
                  }
                }
              }

              .card {
                width: 130px;
                height: 132px;
              }

              .card-content {
                gap: 4px;

                &__sec {
                  @include flexColumnACenter;
                  justify-content: center;
                  gap: 0px;
                  width: 120px;
                  height: 38px;
                  .icon {
                    width: 16px;
                    height: 16px;
                  }
                  h5 {
                    color: $font_grey;
                    font-size: 11px;
                    font-weight: 400;
                  }
                }
                // .icon {
                //   width: 24px;
                //   height: 24px;
                // }

                p {
                  font-size: 10px;
                  padding: 0 8px;
                }
              }
            }

            &__mask {
              &__heading {
                padding: 12px 0 4px 0;

                h4 {
                  font-size: 12px;
                }

                .tooltip-top {
                  img {
                    width: 12px;
                  }
                }
              }

              .card {
                width: 130px;
                height: 132px;
              }

              .card-content {
                gap: 4px;

                &__sec {
                  @include flexColumnACenter;
                  justify-content: center;
                  gap: 0px;
                  width: 120px;
                  height: 38px;
                  .icon {
                    width: 16px;
                    height: 16px;
                  }
                  h5 {
                    color: $font_grey;
                    font-size: 11px;
                    font-weight: 400;
                  }
                }
                // .icon {
                //   width: 24px;
                //   height: 24px;
                // }

                p {
                  font-size: 10px;
                  padding: 8px;
                }
              }
            }

            &__CNSwitch {
              padding: 8px 0 4px 0;

              &__heading {
                h4 {
                  font-size: 12px;
                  padding: 0;
                }

                .tooltip-top {
                  img {
                    width: 12px;
                  }
                }
              }

              &__sec1 {
                .span {
                  font-size: 10px;
                }
              }
            }

            &__image-count {
              &__heading {
                padding: 8px 0 4px 0;

                h4 {
                  font-size: 12px;
                }
              }

              &__sec {
                &__sub {
                  height: 22px;
                  border-radius: 4px;
                }
              }
            }
          }

          &__upscale {
            padding: 0 0 24px 0;

            &__base-img {
              &__heading {
                padding: 8px 0 4px 0;

                h4 {
                  font-size: 12px;
                }

                .tooltip-top {
                  img {
                    width: 12px;
                  }
                }
              }

              .card {
                width: 130px;
                height: 132px;
              }
            }

            &__selection {
              &__sub {
                padding: 0 4px;
                &__sec {
                  padding: 2px;
                  min-width: 0;
                  img {
                    width: 18px;
                    height: 18px;
                  }

                  h5 {
                    font-size: 10px;
                  }
                }
              }
            }

            &__hd {
              width: 100%;
              margin: 12px 0 0 0;
              &__options {
                gap: 4px;

                &__sec {
                  height: 30px;
                }
              }
            }
          }
          &__video-ai {
            padding: 0 0 24px 0;

            &__base-img {
              &__heading {
                padding: 8px 0 4px 0;

                h4 {
                  font-size: 12px;
                }

                .tooltip-top {
                  img {
                    width: 12px;
                  }
                }
              }

              .card {
                width: 130px;
                height: 132px;
              }
              .card-content {
                .icon {
                  width: 24px;
                  height: 24px;
                }
                p {
                  font-size: 10px;
                }
              }
            }

            &__camera {
              padding: 8px 0 0 0;
              gap: 6px;
              &__heading {
                h4 {
                  font-size: 12px;
                }
              }
              &__options {
                &__heading {
                  h4 {
                    font-size: 11px;
                  }
                }
                &__main {
                  gap: 4px;
                }
                &__sec {
                  height: 32px;
                  h5 {
                    font-size: 11px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .toolset__section__tools__main {
    &__CNimageSec {
      &__base-img {
        padding: 0 0 4px 0;
        width: calc(100% - 0px);
        &__heading {
          h4 {
            font-size: 12px;
          }

          .tooltip-top {
            img {
              width: 12px;
            }
          }
        }

        .card {
          width: 130px;
          height: 144px;
        }

        .card-content {
          gap: 4px;

          &__sec {
            @include flexColumnACenter;
            justify-content: center;
            gap: 0px;
            width: 120px;
            height: 38px;
            .icon {
              width: 16px;
              height: 16px;
            }
            h5 {
              color: $font_grey;
              font-size: 11px;
              font-weight: 400;
            }
          }
          // .icon {
          //   width: 24px;
          //   height: 24px;
          // }

          p {
            font-size: 10px;
            padding: 0 0px;
          }
        }
      }

      &__ratio {
        &__heading {
          padding: 12px 0 4px 0;

          h4 {
            font-size: 12px;
          }
        }

        &__sec {
          &__sub {
            border-radius: 4px;

            &__section {
              a {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 767px) {
  .toolset {
    width: 22vw;
    top: 36px;
    border-radius: 6px;
    height: calc(var(--vh, 1vh) * 100 - 36px - 24px);

    &__sections {
      height: calc(100% - 24px);
      padding: 12px 0px 12px 0px;
    }

    &__section {
      padding: 0 8px 0 12px;

      &__tools {
        .react-tabs {
          &__tab-panel {
            display: none;
          }

          &__tab-panel--selected {
            display: block;
            width: 100%;
          }
        }

        &__heading {
          padding: 0 0 12px 0;

          h4 {
            font-size: 14px;
          }
        }

        &__tab {
          img {
            width: 20px;
            height: 20px;
          }
        }
        .react-tabs {
          .updated {
            right: 0;
          }
          &__tab {
            span {
              font-size: 10px;
              padding: 4px 0 0 0;
            }
          }
        }

        &__main {
          &__text {
            padding: 0 0 32px 0;

            &__modal {
              margin: 8px 0 0 0;
              &__heading {
                padding: 8px 0 4px 0;

                h4 {
                  font-size: 12px;
                }
              }

              &__sec {
                &__sub {
                  padding: 0;
                  justify-content: center;
                  border-radius: 4px;

                  span {
                    height: 32px;
                    font-size: 12px;
                  }
                }
              }
            }

            &__ratio {
              margin: 8px 0 0 0;
              &__heading {
                padding: 8px 0 4px 0;

                h4 {
                  font-size: 12px;
                }
              }

              &__sec {
                &__sub {
                  padding: 0;
                  height: 48px;
                  justify-content: center;
                  border-radius: 4px;

                  h6 {
                    font-size: 12px;
                  }
                }
              }
            }

            &__orientation {
              margin: 8px 0 0 0;
              &__heading {
                padding: 8px 0 4px 0;

                h4 {
                  font-size: 12px;
                }
              }

              &__sec {
                &__sub {
                  padding: 0;
                  justify-content: center;
                  border-radius: 4px;

                  span {
                    height: 32px;
                    font-size: 12px;
                  }
                }
              }
            }

            &__image-count {
              &__heading {
                padding: 8px 0 4px 0;

                h4 {
                  font-size: 12px;
                }
              }

              &__sec {
                &__sub {
                  height: 24px;
                  border-radius: 4px;
                }
              }
            }
          }

          &__design {
            padding: 0 0 32px 0;

            &__imagetype {
              &__heading {
                padding: 2px;
                &__sec {
                  padding: 2px 4px;
                  h5 {
                    font-size: 10px;
                  }
                }
                .ch {
                  padding: 0px 8px;
                }
              }
            }

            &__base-img {
              &__heading {
                padding: 8px 0 4px 0;

                h4 {
                  font-size: 12px;
                }

                .tooltip-top {
                  img {
                    width: 12px;
                  }
                }
              }

              .card {
                width: 17.8vw;
                height: 132px;
              }

              .card-content {
                justify-content: center;
                padding: 0 !important;
                gap: 4px;

                &__sec {
                  @include flexColumnACenter;
                  justify-content: center;
                  gap: 0px;
                  width: 16.4vw;
                  height: 5vw;
                  .icon {
                    width: 16px;
                    height: 16px;
                  }
                  h5 {
                    color: $font_grey;
                    font-size: 11px;
                    font-weight: 400;
                  }
                }

                // .icon {
                //   width: 24px;
                //   height: 24px;
                // }

                p {
                  font-size: 1.5vw;
                  padding: 0 2px;
                }
              }
            }

            &__fidelity {
              flex-direction: column;

              &__heading {
                width: 100%;
                padding: 8px 0 4px 0;

                h4 {
                  font-size: 12px;
                }

                .tooltip-top {
                  img {
                    width: 12px;
                  }
                }
              }

              &__sec {
                width: 100%;

                .span {
                  font-size: 10px;
                }
              }
            }

            &__resolution {
              margin: 8px 0 0 0;
              &__heading {
                padding: 8px 0 0 0;
                h4 {
                  font-size: 11px;
                }
                span {
                  font-size: 11px;
                }
              }
              &__section {
                &__heading {
                  flex-direction: column;
                  border-radius: 8px;
                  &__sec {
                    padding: 4px 8px;
                    h5 {
                      font-size: 11px;
                    }
                  }
                }
                h6 {
                  font-size: 10px;
                }
              }
            }

            &__image-count {
              margin: 8px 0 0 0;
              &__heading {
                padding: 8px 0 4px 0;

                h4 {
                  font-size: 12px;
                }
              }

              &__sec {
                &__sub {
                  height: 24px;
                  border-radius: 4px;
                }
              }
            }
          }

          &__imagec {
            padding: 0 0 24px 0;

            &__fidelity {
              flex-direction: column;

              &__heading {
                width: 100%;
                padding: 8px 0 4px 0;

                h4 {
                  font-size: 12px;
                }

                .tooltip-top {
                  img {
                    width: 12px;
                  }
                }
              }

              &__sec {
                width: 100%;

                .span {
                  font-size: 10px;
                }
              }
            }

            &__base-img {
              &__heading {
                padding: 8px 0 4px 0;

                h4 {
                  font-size: 12px;
                }

                .tooltip-top {
                  img {
                    width: 12px;
                  }
                }
              }

              .card {
                width: 17.8vw;
                height: 132px;
              }

              .card-content {
                justify-content: center;
                padding: 0 !important;
                gap: 4px;

                &__sec {
                  @include flexColumnACenter;
                  justify-content: center;
                  gap: 0px;
                  width: 16.4vw;
                  height: 5vw;
                  .icon {
                    width: 16px;
                    height: 16px;
                  }
                  h5 {
                    color: $font_grey;
                    font-size: 11px;
                    font-weight: 400;
                  }
                }

                // .icon {
                //   width: 24px;
                //   height: 24px;
                // }

                p {
                  font-size: 1.5vw;
                  padding: 0 2px;
                }
              }

              &__camera {
                flex-direction: column;

                &__heading {
                  width: 100%;
                }

                &__section {
                  width: 100%;
                }

                &__imageType {
                  width: 100%;
                }
              }
            }

            &__selection {
              padding: 8px 0 0 0;
              &__sub {
                padding: 0 4px;
                &__sec {
                  min-width: 0;
                  img {
                    width: 18px;
                    height: 18px;
                  }
                  h5 {
                    font-size: 10px;
                  }
                }
              }
            }

            &__CNSwitch {
              padding: 8px 0 8px 0;

              &__heading {
                h4 {
                  font-size: 12px;
                  font-weight: 500;
                }
              }

              &__sec1 {
                &__sub1 {
                  span {
                    font-size: 10px;
                  }
                }
              }
            }

            &__mask {
              &__heading {
                padding: 8px 0 4px 0;

                h4 {
                  font-size: 12px;
                }

                button {
                  padding: 2px 3px;
                  h4 {
                    font-size: 11px;
                  }
                }

                .tooltip-top {
                  img {
                    width: 12px;
                  }
                }
              }

              .card {
                width: 17.8vw;
                height: 132px;
              }

              .card-content {
                justify-content: center;
                padding: 0 !important;
                gap: 4px;

                &__sec {
                  @include flexColumnACenter;
                  justify-content: center;
                  gap: 0px;
                  width: 16.4vw;
                  height: 5vw;
                  .icon {
                    width: 16px;
                    height: 16px;
                  }
                  h5 {
                    color: $font_grey;
                    font-size: 11px;
                    font-weight: 400;
                  }
                }

                // .icon {
                //   width: 24px;
                //   height: 24px;
                // }

                p {
                  font-size: 10px;
                  padding: 0 2px;
                }
              }
            }

            &__fidelity1 {
              flex-direction: column;
              padding: 8px 0 4px 0;

              &__heading {
                width: 100%;

                h4 {
                  font-size: 12px;
                  padding: 0;
                }

                .tooltip-top {
                  img {
                    width: 12px;
                  }
                }
              }

              &__sec {
                width: 100%;

                .span {
                  font-size: 10px;
                }
              }
            }

            &__image-count {
              &__heading {
                padding: 8px 0 4px 0;

                h4 {
                  font-size: 12px;
                }
              }

              &__sec {
                &__sub {
                  height: 24px;
                  border-radius: 4px;
                }
              }
            }
          }

          &__upscale {
            padding: 0 0 24px 0;

            &__base-img {
              &__heading {
                padding: 8px 0 4px 0;

                h4 {
                  font-size: 12px;
                }

                .tooltip-top {
                  img {
                    width: 12px;
                  }
                }
              }

              .card {
                width: 17.8vw;
                height: 18vw;
              }
            }

            &__selection {
              &__sub {
                padding: 0 4px;
                &__sec {
                  min-width: 0;
                  img {
                    width: 18px;
                    height: 18px;
                  }
                  h5 {
                    font-size: 10px;
                  }
                }
              }
            }

            &__hd {
              width: 100%;
              margin: 12px 0 0 0;
              &__options {
                gap: 4px;

                &__sec {
                  height: 30px;
                }
              }
            }
          }
          &__video-ai {
            padding: 0 0 24px 0;

            &__base-img {
              &__heading {
                padding: 8px 0 4px 0;

                h4 {
                  font-size: 12px;
                }

                .tooltip-top {
                  img {
                    width: 12px;
                  }
                }
              }

              .card {
                width: 130px;
                height: 132px;
              }
              .card-content {
                gap: 4px;

                &__sec {
                  @include flexColumnACenter;
                  justify-content: center;
                  gap: 0px;
                  width: 16.4vw;
                  height: 5vw;
                  .icon {
                    width: 16px;
                    height: 16px;
                  }
                  h5 {
                    color: $font_grey;
                    font-size: 11px;
                    font-weight: 400;
                  }
                }
                // .icon {
                //   width: 24px;
                //   height: 24px;
                // }
                p {
                  font-size: 1.5vw;
                }
              }
            }

            &__camera {
              padding: 8px 0 0 0;
              gap: 6px;
              &__heading {
                h4 {
                  font-size: 12px;
                }
              }
              &__options {
                &__heading {
                  h4 {
                    font-size: 11px;
                  }
                }
                &__main {
                  gap: 4px;
                }
                &__sec {
                  height: 32px;
                  h5 {
                    font-size: 11px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .toolset__section__tools__main {
    &__CNimageSec {
      &__base-img {
        width: calc(100% - 0px);
        &__heading {
          h4 {
            font-size: 12px;
          }

          .tooltip-top {
            img {
              width: 12px;
            }
          }
        }

        .card {
          width: 17.8vw;
          height: 136px;
        }

        .card-content {
          justify-content: center;
          padding: 0 !important;
          gap: 4px;

          &__sec {
            @include flexColumnACenter;
            justify-content: center;
            gap: 0px;
            width: 16.4vw;
            height: 5vw;
            .icon {
              width: 16px;
              height: 16px;
            }
            h5 {
              color: $font_grey;
              font-size: 11px;
              font-weight: 400;
            }
          }

          // .icon {
          //   width: 24px;
          //   height: 24px;
          // }

          p {
            font-size: 1.2vw;
            padding: 0 2px;
          }
        }
      }

      &__ratio {
        &__heading {
          padding: 12px 0 4px 0;

          h4 {
            font-size: 12px;
          }
        }

        &__sec {
          &__sub {
            border-radius: 4px;

            &__section {
              a {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
