@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.custom-popover4 {
  min-width: 84px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-popover4 .inner-wrapper {
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  box-sizing: border-box;
}
.cancel-popover {
  width: 196px !important;
  display: flex;
  justify-content: center;
  .ant-popover-inner {
    padding: 8px !important;
  }
}
.render-confirm {
  @include flexcolumn;
  gap: 8px;
  &__heading {
    @include flexrow;
    gap: 8px;
    img {
      width: 20px;
      height: 20px;
      padding: 2px 0 0 0;
    }
    h4 {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
    }
  }
  &__buttons {
    @include flexJEnd;
    button {
      @include flexCenter;
      width: 64px;
      height: 24px;
      border: none;
      background: none;
      font-size: 12px;
      font-weight: 400;
      color: $primary_blue;
      border-radius: 13px;
      line-height: 14px;
      cursor: pointer;
      &:last-child {
        margin: 0 0 0 6px;
        background: $primary_blue;
        color: $primary_white;
      }
      &:hover {
        opacity: 0.6;
      }
    }
  }
}

.work-space {
  @include flexJCenter;
  background: $bg_light_grey;
  padding: 0px 6px 0 6px;
  position: relative;
  height: calc(var(--vh, 1vh) * 100 - 48px);
  &__container {
    position: relative;
    width: calc(100% - 479px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  &__main {
    overflow: auto;
    padding: 12px 0 80px 0;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100%;
    &__images {
      width: 1098px;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: fit-content;
      margin-top: auto;
      .gif-icon {
        display: flex;
        justify-content: center;
        img {
          width: 6vw;
        }
      }
      &__list {
        @include flexJCenter;
        &__data:first-child {
          padding: 0;
        }
        &__data {
          @include flexrow;
          width: 100%;
          &__content {
            flex-direction: column;
            padding: 0 8px 0 0;
          }
          &__icon {
            @include flexJEnd;
            padding: 0 0 6px 0;
            img {
              width: 36px;
              height: 36px;
            }
          }
          &__time {
            display: flex;
            flex-direction: column;
            text-align: end;
            width: 106px;
            p {
              font-size: 10px;
            }
            span {
              font-size: 10px;
            }
          }
          &__mode {
            position: relative;
            @include flexJEnd;
            padding: 4px 0 0 0;
            img {
              width: 20px;
              height: 20px;
            }
            .tool {
              display: none;
            }
          }
          &__mode:hover {
            .tool {
              display: block;
            }
          }
          &__cancel {
            display: flex;
            justify-content: flex-end;
            margin: 12px 0 0 0;
            button {
              @include flexrowacenter;
              justify-content: center;
              gap: 4px;
              background: #a8adaf;
              color: $primary_white;
              border: none;
              height: 24px;
              width: 84px;
              border-radius: 23px;
              cursor: pointer;
              img {
                width: 16px;
                height: 16px;
              }
            }
          }
        }
        &__data.single {
          position: relative;
          width: 600px;
          p {
            position: absolute;
            left: 0px;
            top: 0;
          }
        }
        &__images {
          width: 984px;
          display: flex;
          justify-content: flex-start;
          &__new {
            width: 100%;
            display: flex;
            justify-content: flex-start;
          }
          &__image {
            position: relative;
            width: calc(25% - 6px);
            margin: 0 8px 8px 0;
            @include flexCenter;
            .favorites {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
            }
            .one {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              width: 50%;
              left: auto;
              top: auto;
            }
            .fav-icon {
              position: relative;
              width: max-content;
              height: fit-content;
              margin: 4px 0 0 4px;
              .fav {
                display: none;
                width: 32px;
                height: 32px;
                cursor: pointer;
              }
              .fav1 {
                display: none;
              }
              .fav2 {
                display: none;
              }
              .del {
                display: none;
                width: 32px;
                height: 32px;
                cursor: pointer;
              }
              .del1 {
                display: none;
              }
              .del2 {
                display: none;
              }
              .tooltip-show {
                display: none;
              }
            }
            .fav-icon:hover {
              .fav {
                display: none;
              }
              .fav1 {
                display: block;
              }
              .fav2 {
                display: none;
              }
              .del {
                display: none;
              }
              .del1 {
                display: block;
              }
              .del2 {
                display: none;
              }
              .tooltip-show {
                display: block;
              }
            }
            .video-gen {
              position: absolute;
              bottom: 0;
              right: 0;
              &__icon {
                width: 32px !important;
                height: 32px;
                margin: 0 12px 8px 0;
              }
            }
            img {
              width: 100%;
            }
            .highlighted {
              border: 2px solid white;
              box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
            }
            .dots {
              width: 20%;
            }
            .dotsn {
              width: 20%;
            }
            p {
              width: 70%;
              font-size: 14px;
              font-weight: 600;
              text-align: center !important;
              padding: 0 !important;
            }
            h6 {
              width: 90%;
              font-size: 12px;
              text-align: center !important;
              color: $primary_black;
              font-weight: 400;
            }
            &__gen {
              position: absolute;
              @include flexCenter;
              flex-direction: column;
              gap: 12px;
            }
          }
          &__image:hover {
            .favorites {
              background: #00000020;
            }
            .fav {
              display: block;
            }
            .fav1 {
              display: none;
            }
            .fav2 {
              display: none;
            }
            .del {
              display: block;
            }
            .del1 {
              display: none;
            }
            .del2 {
              display: none;
            }
          }
          .favorite {
            .fav-icon {
              .fav {
                display: none;
              }
              .fav1 {
                display: none;
              }
              .fav2 {
                display: block;
              }
            }
            .fav-icon:hover {
              .fav {
                display: none;
              }
              .fav1 {
                display: block;
              }
              .fav2 {
                display: none;
              }
              .del {
                display: none;
              }
              .del1 {
                display: block;
              }
              .del2 {
                display: none;
              }
            }
          }
          &__image.one {
            width: 100%;
            @include flexCenter;
            img {
              width: 50%;
            }
            .dots1 {
              width: 20%;
            }
            .dotsn {
              width: 20%;
            }
            p {
              font-size: 14px;
              font-weight: 600;
              text-align: center !important;
              padding: 0 !important;
            }
            h6 {
              width: 45%;
              font-size: 12px;
              text-align: center !important;
              color: $primary_black;
              font-weight: 400;
            }
            &__gen {
              position: absolute;
              @include flexCenter;
              flex-direction: column;
              gap: 12px;
            }
          }
          &__image:nth-child(4n) {
            margin: 0 0 6px 0;
          }
          &__image.single {
            width: 100%;
            margin: 0 0 6px 0;
            img {
              width: 486px;
            }
          }
        }
        &__images.single {
          width: 100%;
        }
      }
    }
  }
  .alert {
    position: absolute;
    transform: translateX(3%);
    z-index: 99;
    width: 50%;
    @include flexColumnACenter;
    top: 12px;

    &__container {
      width: calc(30% + 32px);
    }

    .message1 {
      flex-direction: column;
      justify-content: flex-start;
    }
    .message2 {
      width: 342px;
      flex-direction: column;
      justify-content: flex-start;
    }
    &__message {
      margin: 0 0 8px 0;
      width: 300px;
      background: $primary_white;
      padding: 16px;
      @include flexAcenter;
      border-radius: 8px;
      box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1),
        0px 4px 4px 0px rgba(0, 0, 0, 0.06), 0px 4px 4px 0px rgba(0, 0, 0, 0.05);

      &__main1 {
        @include flexrow;
        span {
          padding: 0 0 0 8px;
          font-size: 14px;
          font-weight: 600;
        }
      }

      &__buttons1 {
        width: 100%;
        @include flexrow;
        justify-content: flex-end;
        button {
          width: 80px;
          height: 27px;
          border-radius: 13px;
          border: none;
          background: $primary_black;
          color: $primary_white;
          cursor: pointer;
          font-size: 12px;
        }
        button:hover {
          opacity: 0.7;
        }
        button:active {
          opacity: 0.5;
        }
      }

      &__heading {
        display: flex;
        width: 100%;
        img {
          width: 20px;
          height: 20px;
        }
        h4 {
          font-size: 16px;
          padding: 0 0 0 8px;
        }
      }
      &__content {
        padding: 12px 0 0 0;
        p {
          font-size: 12px;
        }
      }
      &__buttons {
        padding: 16px 0 0 0;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        &__section {
          width: 180px;
          display: flex;
          justify-content: flex-end;
          button {
            width: auto !important;
            padding: 8px !important;
            border-radius: 20px !important;
            border: none;
            background: $primary_black;
            color: $primary_white;
            cursor: pointer;
            font-size: 12px;
          }
          button:hover {
            opacity: 0.7;
          }
          button:active {
            opacity: 0.5;
          }
          .cancel {
            width: 47% !important;
            background: $primary_white;
            color: $primary_black;
            opacity: 0.5;
          }
          .cancel:hover {
            opacity: 0.7;
          }
          .cancel:active {
            opacity: 1;
          }
        }
      }
      &__main {
        display: flex;
        flex-direction: column;
        width: 100%;

        &__content {
          display: flex;
          align-items: center;
          justify-content: space-between;

          &__data {
            display: flex;
            align-items: center;
          }

          p {
            font-size: 12px;
            font-weight: 500;
          }

          .cancel {
            cursor: pointer;
            width: 8px;
            height: 8px;
          }
        }
      }

      &__buttons {
        display: flex;
        justify-content: flex-end;
        padding: 16px 0 0 0;

        button {
          width: auto;
          min-width: 32px;
          font-size: 12px;
          cursor: pointer;
        }

        .success {
          border: none;
          background: $primary_black;
          color: $primary_white;
          border-radius: 16px;
          padding: 8px 16px;
          margin: 0 0 0 32px;
        }

        .cancel {
          border: none;
          background: none;
          color: $primary_black;
        }
      }

      img {
        width: 20px;
        height: 20px;
      }

      p {
        font-size: 12px;
        font-weight: 500;
        padding: 0 0 0 10px;
      }
    }

    &__message.gen {
      width: calc(100% - 32px);
    }

    &__cancel {
      margin: 0 0 8px 0;
      width: 350px;
      background: $primary_white;
      padding: 16px;
      @include flexAcenter;
      border-radius: 8px;
      box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1),
        0px 4px 4px 0px rgba(0, 0, 0, 0.06), 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
      &__container {
        @include flexrowacenter;
        gap: 8px;
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  &__button {
    @include flexCenter;
    position: fixed;
    bottom: 4px;
    height: 80px;
    // width: 440px;
    button {
      height: 40px;
      min-width: 250px;
      border-radius: 20px;
      border: 2px white;
      padding: 0 0px;
      background: transparent;
      color: $primary_white;
      cursor: pointer;
      overflow: hidden;
      position: relative;
      font-size: 18px;
      box-shadow: 0px 0px 5px 0 #787878;
      span {
        margin: 0px 24px;
      }
    }
    .button-fill {
      content: "";
      position: absolute;
      left: calc(var(--fill-width, 0%) - 50%);
      top: -105px;
      transform: translateX(-54%) rotate(var(--rotate-deg, 0deg));
      width: 250px;
      height: 250px;
      background-color: #d9d9d9;
      // transition: left 1s ease-out, transform 1s ease-out, background-color 1s ease-out;
      transition: left 1s cubic-bezier(0.175, 0.885, 0.32, 1),
        transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275),
        background-color 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      border-radius: 40%;
      z-index: -1;
      overflow: hidden;
    }
    .button-fill-bg {
      position: absolute;
      top: 0px;
      background-color: $primary_black;
      z-index: -2;
      width: 250px;
      height: 40px;
      border-radius: 20px;
      @include flexCenter;
    }

    button:hover {
      opacity: 0.6;
    }

    button:active {
      opacity: 0.4;
    }

    button.disabled {
      cursor: default;
      min-width: 250px;
      background-color: $primary_white;
      color: $primary_black;
      box-shadow: 0px 0px 2px 0 #787878;
    }

    .base-img {
      background: $primary_white;
      color: $primary_black;
      cursor: not-allowed;
      box-shadow: 0px 0px 0px 0 #000000, 0px 0px 4px 0 #000000;
    }
  }
  &__messages {
    position: absolute;
    top: -3vw;
    z-index: 1000;
    &__content {
      display: flex;
      background: $primary_white;
      margin: $font_xlarge 0 0 0;
      padding: $font_xsmall;
      box-shadow: 0 4px 16px 0 #00000050;
      border-radius: $font_xverysmall;
      p {
        padding: 0 0 0 $font_xverysmall;
      }
    }
  }
}

.edit-project {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: transparent;
  @include flexCenter;
  background-color: #00000021;
  z-index: 1000;
  top: 0;
  &__container {
    width: 25%;
    background: $primary_white;
    padding: $font_xlarge 0;
    @include flexJCenter;
    box-shadow: 0 4px 16px 0 #ffffff40;
    border-radius: $font_xverysmall;
    position: relative;
  }
  &__section {
    width: 85%;
    @include flexcolumn;
    .cross {
      width: 20px;
      height: 20px;
      position: absolute;
      right: 12px;
      top: 12px;
      cursor: pointer;
    }
    &__heading {
      padding: 0 0 20px 0;
      text-align: center;
      h3 {
        font-size: 18px;
      }
    }
    &__input {
      padding: 0 0 16px 0;
      width: 100%;
      input {
        width: calc(100% - 32px);
        font-size: 14px;
        border: 1px solid $primary_grey;
        border-radius: 18px;
        padding: 8px 16px;
        color: $grey_input;
        outline: none;
      }
      p {
        text-align: end;
        font-size: 12px;
        padding: 4px 0 0 0;
        span {
          font-size: 12px;
        }
      }
    }
    &__button {
      width: 100%;
      @include flexJCenter;
      button {
        width: 140px;
        padding: 12px 0;
        border-radius: 24px;
        border: none;
        background: $primary_black;
        color: $primary_white;
        cursor: pointer;
      }
      button:disabled {
        cursor: not-allowed;
        background: #d9d9d9;
        color: #d9d9d9;
        border-radius: 0;
      }
    }
  }
}

.reload-feed {
  position: absolute;
  bottom: 25px;
  transform: translateX(calc(-50% + 160px));
  left: 50%;
  button {
    display: flex;
    background-color: $primary_grey;
    color: $primary_black;
    border: none;
    border-radius: 50%;
    // width: $font_xxlarge;
    // height: $font_xxlarge;
    font-size: $font_xsmall;
    cursor: pointer;
    padding: 0;
    transition: background-color 0.3s ease-in-out;
    &:hover {
      background-color: #677074; /* Darker shade of the base color */
    }
    &:active {
      background-color: #263238; /* Darker shade of the base color */
    }
    img {
      width: 20px;
      height: 20px;
      padding: 8px 8px 8px 8px;
    }
  }
}

@media screen and (max-width: 1194px) {
  .work-space__main__images__list__images .favorite .fav-icon:hover {
    .fav1 {
      display: none;
    }
    .del1 {
      display: none;
    }

    .fav2 {
      display: block;
    }
    .del2 {
      display: block;
    }
  }
}

@media screen and (min-width: 1250px) and (max-width: 1610px) {
  .work-space {
    height: calc(100vh - 48px);
    &__container {
      width: calc(100% - 479px);
    }
    &__main {
      width: 100%;
      display: flex;
      justify-content: center;
      &__images {
        width: 738px;
        &__list {
          &__data {
            @include flexrow;
            width: 100%;
            padding: 0;
            &__time {
              text-align: end;
              width: 58px;
              padding: 0 6px 0 0;
              p {
                font-size: 8px;
              }
            }
            &__mode {
              padding: 0;
              img {
                width: 18px;
                height: 18px;
              }
            }
          }
          &__data.single {
            position: relative;
            width: 408px;
            padding: 0;
            justify-content: flex-end;
          }
          &__images.single {
            width: 326px;
          }
          &__data.default {
            justify-content: flex-end;
          }
          &__images {
            width: 674px;
            &__image {
              width: calc(25% - 4.5px);
              margin: 0px 6px 6px 0;
              .dots {
                width: 20%;
              }
              .dotsn {
                width: 40%;
              }
              p {
                width: 70%;
                font-size: 12px;
              }
              h6 {
                width: 70%;
                font-size: 10px;
              }
            }
            &__image:nth-child(4n) {
              margin: 0 0 6px 0;
            }
            &__image.single {
              width: 100%;
              margin: 0px 0 6px 0;
              img {
                width: 100%;
              }
              p {
                width: 40%;
                font-size: 20px;
              }
              .dots {
                width: 20%;
              }
              .dotsn {
                width: 40%;
              }
            }
          }
        }
      }
    }
    &__button {
      button {
        font-size: 18px;
      }
    }
  }
}

@media screen and (min-width: 1090px) and (max-width: 1250px) {
  .work-space {
    height: calc(100vh - 48px);
    &__container {
      width: calc(100% - 479px);
    }
    &__main {
      width: 100%;
      display: flex;
      justify-content: center;
      &__images {
        width: 578px;
        &__list {
          &__data {
            @include flexrow;
            width: 100%;
            padding: 0;
            &__icon {
              img {
                width: 28px;
                height: 28px;
              }
            }
            &__time {
              text-align: end;
              width: 58px;
              padding: 0 6px 0 0;
              p {
                font-size: 7px;
              }
            }
            &__mode {
              padding: 0;
              img {
                width: 16px;
                height: 16px;
              }
            }
          }
          &__data.single {
            width: 320px;
            position: relative;
            p {
              position: absolute;
              top: 0;
              left: 0;
            }
          }
          &__images {
            width: 514px;
            &__image {
              width: calc(25% - 4.5px);
              margin: 0px 6px 6px 0;
              .dots {
                width: 20%;
              }
              .dotsn {
                width: 40%;
              }
              p {
                width: 70%;
                font-size: 10px;
              }
              h6 {
                width: 70%;
                font-size: 8px;
              }
            }
            &__image:nth-child(4n) {
              margin: 0 0 6px 0;
            }
            &__image.one {
              p {
                width: 70%;
                font-size: 14px;
              }
              h6 {
                //width: 70%;
                font-size: 12px;
              }
            }
            &__image.single {
              display: flex;
              justify-content: flex-end;
              margin: 0px 0 6px 0;
              img {
                width: 254px;
              }

              p {
                width: 40%;
                font-size: 12px;
              }
              .dots {
                width: 20%;
              }
              .dotsn {
                width: 40%;
              }
              h6 {
                width: 70%;
                font-size: 10px;
              }
            }
          }
        }
      }
    }
    &__button {
      button {
        font-size: 13px;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1090px) {
  .work-space {
    height: calc(var(--vh, 1vh) * 100 - 36px);
    &__container {
      width: calc(100% - 328px);
    }
    &__main {
      padding: 12px 0 60px 0;
      &__images {
        width: 406px;
        &__list {
          &__data {
            flex-direction: column;
            &__icon {
              justify-content: flex-start;
              padding: 0;
              img {
                width: 24px;
                height: 24px;
              }
            }
            &__content {
              @include flexrowacenter;
              padding: 4px 0;
            }
            &__time {
              width: max-content;
              flex-direction: row;
              align-items: center;
              padding: 0 8px;
              p:first-child {
                padding: 0 4px 0 0;
              }
            }
            &__mode {
              display: flex;
              justify-content: flex-start;
              padding: 0;
              img {
                width: 12px;
                height: 12px;
              }
            }
          }
          &__images {
            width: 406px;
            &__image {
              width: calc(25% - 1.5px);
              margin: 0 2px 2px 0;
              .fav-icon {
                margin: 4px 0 0 4px;
                .fav {
                  width: 24px;
                  height: 24px;
                }
                .del {
                  width: 24px;
                  height: 24px;
                }
              }
              .dots {
                top: 20%;
              }
              .dotsn {
                top: 20%;
              }
              p {
                top: 40%;
                width: 90%;
              }
              h6 {
                top: 70%;
                width: 80% !important;
              }
            }
            &__image:last-child {
              margin: 0 0 2px 0;
            }
            p {
              width: 70%;
              font-size: 10px;
            }
            h6 {
              width: 70%;
              font-size: 8px;
            }
          }
        }
      }
    }
    &__button {
      height: 60px;
      button {
        min-width: 180px;
        height: 32px;
        @include flexCenter;
        &.disabled {
          min-width: 180px;
        }
      }
      .button-fill {
        top: -75px;
        width: 180px;
        height: 180px;
      }
      .button-fill-bg {
        width: 180px;
        height: 32px;
        // top: px;
      }
      button:disabled {
        min-width: 180px;
      }
    }
    .alert {
      transform: translateX(0%);
      &__message {
        padding: 8px;
        width: auto;
        max-width: 280px;
        img {
          width: 14px;
          height: 14px;
        }
        p {
          font-size: 11px;
        }
        &__main1 {
          span {
            font-size: 12px;
          }
        }
        &__buttons1 {
          button {
            width: 60px;
            height: 23px;
          }
        }
        &__buttons {
          .success {
            padding: 4px 8px;
          }
        }
      }
    }
  }
  .edit-project {
    &__container {
      width: 300px;
      padding: 12px;
    }
    &__section {
      width: 90%;
      &__heading {
        h3 {
          font-size: 14px;
        }
      }
      &__input {
        input {
          width: calc(100% - 12px);
          padding: 6px 0 6px 10px;
          font-size: 12px;
        }
        p {
          font-size: 10px;
          span {
            font-size: 10px;
          }
        }
      }
      &__button {
        button {
          width: 120px;
          padding: 10px 0;
        }
      }
    }
  }
  .back-to-bottom {
    right: 8px !important;
    bottom: 18px !important;
    button {
      width: 32px !important;
      height: 32px !important;
      img {
        width: 24px !important;
        height: 24px !important;
      }
    }
  }
  .reload-feed {
    bottom: 18px;
    transform: translateX(calc(-50% + 152px));
    left: 50%;
    button {
      img {
        width: 22px;
        height: 22px;
        padding: 4px;
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 767px) {
  .work-space {
    height: calc(var(--vh, 1vh) * 100 - 36px);
    &__container {
      width: calc(100% - 46.5vw);
    }
    &__main {
      padding: 12px 0 6vw 0;
      &__images {
        width: 49.6vw;
        &__list {
          &__data {
            flex-direction: column;
            &__icon {
              justify-content: flex-start;
              padding: 0;
              img {
                width: 24px;
                height: 24px;
              }
            }
            &__content {
              @include flexrowacenter;
              padding: 4px 0;
            }
            &__time {
              width: max-content;
              flex-direction: row;
              align-items: center;
              padding: 0 8px;
              p:first-child {
                padding: 0 4px 0 0;
              }
            }
            &__mode {
              display: flex;
              justify-content: flex-start;
              padding: 0;
              img {
                width: 12px;
                height: 12px;
              }
            }
          }
          &__images {
            width: 49.6vw;
            &__image {
              width: calc(25% - 1.5px);
              margin: 0 2px 2px 0;
              .fav-icon {
                margin: 4px 0 0 4px;
                .fav {
                  width: 24px;
                  height: 24px;
                }
                .del {
                  width: 24px;
                  height: 24px;
                }
              }
              .dots {
                top: 20%;
              }
              .dotsn {
                top: 20%;
              }
              p {
                top: 40%;
                width: 90%;
              }
              h6 {
                top: 70%;
                width: 80% !important;
              }
            }
            &__image.one {
              p {
                width: 70%;
                font-size: 1.5vw;
              }
              h6 {
                //width: 70%;
                font-size: 1.5vw;
              }
            }
            &__image:last-child {
              margin: 0 0 2px 0;
            }
            p {
              width: 70%;
              font-size: 1.2vw;
            }
            h6 {
              width: 70%;
              font-size: 1vw;
            }
          }
        }
      }
    }
    &__button {
      bottom: 0vw;
      height: 6vw;
      button {
        min-width: 25vw;
        height: 4.5vw;
        @include flexCenter;
        span {
          font-size: 1.8vw;
        }
        &:disabled {
          min-width: 25vw;
        }
        &.disabled {
          min-width: 25vw;
        }
      }
      .button-fill {
        top: -10.5vw;
        width: 25vw;
        height: 25vw;
      }
      .button-fill-bg {
        width: 25vw;
        height: 4.5vw;
      }
      button:disabled {
        min-width: 25vw;
      }
    }
    .alert {
      width: 320px;
      transform: translateX(0%);
      &__message {
        padding: 8px;
        width: auto;
        max-width: 280px;
        img {
          width: 14px;
          height: 14px;
        }
        p {
          font-size: 11px;
        }
        &__buttons {
          .success {
            font-size: 11px;
            padding: 4px 8px;
          }
        }
      }
    }
  }
  .edit-project {
    &__container {
      width: 300px;
      padding: 12px;
    }
    &__section {
      width: 90%;
      &__heading {
        h3 {
          font-size: 2.4vw;
        }
      }
      &__input {
        input {
          width: calc(100% - 2vw);
          padding: 1vw 0 1vw 1.4vw;
          font-size: 1.8vw;
        }
        p {
          font-size: 1.4vw;
          span {
            font-size: 1.4vw;
          }
        }
      }
      &__button {
        button {
          width: 18vw;
          padding: 1.2vw 0;
        }
      }
    }
  }
  .back-to-bottom {
    bottom: 1vw !important;
    right: 8px !important;
    button {
      width: 4.5vw !important;
      height: 4.5vw !important;
      @include flexCenter;
      img {
        width: 3.4vw !important;
        height: 3.4vw !important;
        padding: 0 !important;
      }
    }
  }
  .reload-feed {
    bottom: 1vw;
    transform: translateX(calc(-50% + 16vw));
    left: 50%;
    button {
      width: 4.5vw;
      height: 4.5vw;
      @include flexCenter;
      img {
        width: 2.6vw;
        height: 2.6vw;
        padding: 0.5vw;
      }
    }
  }
}
