@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.professional {
  width: 100%;
  border: 1px solid $primary_grey;
  border-radius: 1.2vw;
  &__card {
    height: 100%;
    &__container {
      height: 100%;
    }
    &__image {
      width: 100%;
      height: 13.8vw;
      border-radius: 1.2vw 1.2vw 0 0;
      img {
        width: 100%;
        height: 13.8vw;
        object-fit: cover;
        border-radius: 1.2vw 1.2vw 0 0;
      }
    }
    &__content {
      @include flexcolumn;
      background: $primary_white;
      padding: 12px 1.2vw 1.2vw 1.2vw;
      border-radius: 0 0 1.2vw 1.2vw;
      height: calc(100% - 15.6vw);
      min-height: 108px;
      h3 {
        color: #000000;
        font-size: 1.4vw;
        line-height: 2.1vw;
        font-weight: 500;
        padding: 0 0 0.4vw 0;
      }
      p {
        font-size: 0.8vw;
        color: #000000;
        font-weight: 400;
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 1090px) {
  .professional {
    border-radius: 12px;
    &__card {
      &__image {
        border-radius: 12px 12px 0 0;
        height: 184px;
        img {
          height: 184px;
        }
      }
      &__content {
        height: calc(100% - 204px);
        border-radius: 0 0 12px 12px;
        min-height: 92px;
        h3 {
          font-size: 18px;
          line-height: 24px;
        }
        p {
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }
}
