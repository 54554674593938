@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.access {
    background: rgb(0 0 0 / 54%);
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &__popup {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &__container {
        width: 350px;
        background: $primary_white;
        padding: 16px;
        border-radius: 8px;
      }
      &__section {
        @include flexColumnACenter;
        &__header {
          width: 100%;
          display: flex;
          position: relative;
          padding: 0 0 $font_xsmall 0;
          img {
            width: 16px;
            height: 16px;
          }
          h3 {
            font-size: 16px;
            padding: 0 0 0 8px;
          }
          .cross {
            position: absolute;
            right: 0;
            top: 0;
            cursor: pointer;
          }
        }
        &__content {
          padding: 0 0 8px 0;
          p {
            font-size: 14px;
            color: $primary_blue;
          }
        }
        &__buttons {
          padding: 16px 0 0 0;
          display: flex;
          justify-content: flex-end;
          width: 100%;
          &__section {
            width: 53%;
            display: flex;
            justify-content: flex-end;
            button {
              width: 47%;
              padding: $font_xverysmall 0;
              border-radius: $font_large;
              border: none;
              background: $primary_black;
              color: $primary_white;
              cursor: pointer;
              font-size: 12px;
            }
            button:hover {
              opacity: 0.7;
            }
            button:active {
              opacity: 0.5;
            }
            .cancel {
              background: $primary_white;
              color: $primary_black;
              opacity: 0.5;
            }
            .cancel:hover {
              opacity: 0.7;
            }
            .cancel:active {
              opacity: 1;
            }
          }
        }
      }
    }
  }