@import "./variables.scss";

// font families
@font-face {
  font-family: $inter_bold;
  src: url($inter_black_file) format("truetype");
}
@font-face {
  font-family: $inter_bold;
  src: url($inter_bold_file) format("opentype");
}

@font-face {
  font-family: $inter_extrabold;
  src: url($inter_extrabold_file) format("opentype");
}
// @font-face {
//   font-family: $inter_italic;
//   src: url($inter_italic_file) format("opentype");
// }
@font-face {
  font-family: $inter_light;
  src: url($inter_light_file) format("opentype");
}
@font-face {
  font-family: $inter_medium;
  src: url($inter_medium_file) format("opentype");
}
@font-face {
  font-family: $inter_regular;
  src: url($inter_regular_file) format("opentype");
}
@font-face {
  font-family: $inter_semibold;
  src: url($inter_semibold_file) format("opentype");
}
@font-face {
  font-family: $inter_thin;
  src: url($inter_thin_file) format("opentype");
}
