@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.before {
  background: #0000008a;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &__dialog {
    width: 349px;
    background: $primary_white;
    padding: 16px;
    border-radius: 8px;

    &__center-div {
      width: 100%;
      @include flexColumnACenter;
    }

    &__button:focus {
      outline: none;
    }

    &__main {
      width: 100%;
      @include flexcolumn;
      &__heading {
        width: 100%;
        @include flexrowacenter;
        position: relative;
        padding: 0 0 8px 0;
        text-align: start;
        img {
          width: 20px;
          height: 20px;
        }
        h3 {
          font-size: 14px;
          font-weight: 600;
          padding: 0 0 0 10px;
        }
      }
      &__content {
        width: 100%;
        text-align: start;
        p {
          font-size: 12px;
          color: $primary_blue;
          padding: 0 0 8px 0;
        }
      }
    }
    &__button {
      padding: 16px 0 0 0;
      display: flex;
      justify-content: flex-end;
      width: 100%;
      button {
        min-width: 80px;
        padding: 6px 12px;
        border-radius: 15px;
        border: none;
        background: $primary_black;
        color: $primary_white;
        cursor: pointer;
        font-size: 12px;
      }
      button:disabled {
        background: $grey_input;
        cursor: not-allowed;
      }
    }
  }
}
