@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.privacy {
  @include flexColumnACenter;
  background: $bg_light_grey;
  height: 100vh;
  &__container {
    width: 100%;
    @include flexColumnACenter;
    height: 100%;
    overflow: scroll;
  }
  &__sections {
    width: 750px;
    margin: 32px 0 0 0;
  }
  &__heading {
    @include flexcolumn;
    &__content {
      h2 {
        font-size: 32px;
        font-weight: 500;
      }
    }
    &__date {
      display: flex;
      padding: 8px 0;
      h6 {
        font-size: 14px;
        color: $primary_blue;
        font-weight: 400;
      }
    }
    h5 {
      font-size: 14px;
      color: $primary_blue;
      font-weight: 400;
      padding: 0 0 12px 0;
      display: flex;
    }
  }
  &__content {
    &__container {
      padding: 24px 0;
    }
    &__points {
      padding: 8px 0;
      h5 {
        font-size: 14px;
        color: $primary_blue;
        font-weight: 400;
        padding: 0 0 12px 0;
      }
      h4 {
        font-size: 16px;
        color: $primary_blue;
        font-weight: 600;
        padding: 0 0 12px 0;
      }
      h6 {
        font-size: 14px;
        color: $primary_blue;
        font-weight: 600;
        padding: 0 0 12px 0;
      }
      ul {
        padding: 0;
        margin: 0;
        li {
          list-style-type: none;
          padding: 0 0 0 12px;
          h5 {
            font-size: 14px;
            color: $primary_blue;
            font-weight: 400;
            padding: 0 0 12px 0;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 767px) {
  .privacy {
    &__sections {
      width: 88vw;
      margin: 24px 0 0 0;
    }
    &__heading {
      &__content {
        h2 {
          font-size: 24px;
        }
      }
      &__date {
        h6 {
          font-size: 12px;
        }
      }
      h5 {
        padding: 0 0 8px 0;
        font-size: 12px;
      }
    }
    &__content {
      &__container {
        padding: 16px 0;
      }
      &__points {
        h5 {
          font-size: 11px;
          padding: 0 0 8px 0;
        }
        h6 {
          font-size: 11px;
          padding: 0 0 8px 0;
        }
        h4 {
          font-size: 11px;
          padding: 0 0 8px 0;
        }
        ul {
          li {
            h5 {
              font-size: 11px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1090px) {
  .privacy {
    &__sections {
      width: 650px;
      margin: 24px 0 0 0;
    }
    &__heading {
      &__content {
        h2 {
          font-size: 24px;
        }
      }
      &__date {
        h6 {
          font-size: 12px;
        }
      }
      h5 {
        padding: 0 0 8px 0;
        font-size: 12px;
      }
    }
    &__content {
      &__container {
        padding: 16px 0;
      }
      &__points {
        h5 {
          font-size: 11px;
          padding: 0 0 8px 0;
        }
        h6 {
          font-size: 11px;
          padding: 0 0 8px 0;
        }
        h4 {
          font-size: 11px;
          padding: 0 0 8px 0;
        }
        ul {
          li {
            h5 {
              font-size: 11px;
            }
          }
        }
      }
    }
  }
}