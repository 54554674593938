@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.admin-layout {
  width: 100%;
  &__container {
    width: 100%;
    @include flexJCenter;
  }
  &__sections {
    width: 100%;
    @include flexColumnACenter;
    // overflow: auto;
    &__header {
      width: 100%;
    }
    &__main {
      width: 95%;
      margin: 72px 0 0 0;
    }
  }
}

@media screen and (max-width: 1090px) {
  .admin-layout {
    &__sections {
      &__main {
        margin: 36px 0 0 0;
      }
    }
  }
}