@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.custom-popover-gallery {
  width: 196px !important;
  .ant-popover-inner-content {
    text-align: center !important;
    font-size: 14px !important;
    padding: 0 8px !important;
  }
}
.gallery-header {
  position: fixed;
  top: 48px;
  background: $bg_light_grey;
  width: 1272px;
  padding: 82px 0 12px 0;
  z-index: 9;
  &__container {
    width: 100%;
    @include flexcolumn;
    &__top {
      width: 100%;
      height: 40px;
      @include flexrow;
      &__sec {
        margin: 0 16px 0 0;
      }
      &__search {
        width: auto;
        .search-icon {
          position: absolute;
          left: 20px;
          width: 20px;
        }
        .react-select {
          &__value-container {
            overflow: auto !important;
            flex-wrap: nowrap !important;
          }
          &__multi-value {
            background: #d4d6d7;
            border-radius: 6px;
            &__label {
              font-size: 12px !important;
              padding: 4px 8px !important;
            }
          }
        }
      }
    }
    &__bottom {
      width: 100%;
      @include flexbetween;
      height: 32px;
      margin: 12px 0 0 0;
    }
    &__left {
      @include flexrow;
      &__sec {
        height: 32px;
        margin: 0 16px 0 0;
        padding: 0 8px;
        cursor: pointer;
        @include flexAcenter;
        border-radius: 8px;
        border: none;
        background: none;
        img {
          height: 16px;
          width: 16px;
        }
        .active-icon {
          display: none;
        }
        .hover-icon {
          display: none;
        }
        h4 {
          font-size: 13px;
          padding: 0 0 0 4px;
          font-weight: 400;
        }
        &:hover {
          background: $primary_grey !important;
          .default-icon {
            display: none !important;
          }
          .active-icon {
            display: none !important;
          }
          .hover-icon {
            display: block !important;
          }
          h4 {
            color: $primary_blue !important;
          }
        }
        &:last-child {
          margin: 0;
        }
      }
      &__sec.active {
        background: $primary_blue;
        .default-icon {
          display: none;
        }
        .active-icon {
          display: block;
        }
        .hover-icon {
          display: none;
        }
        h4 {
          color: $primary_white;
        }
      }
      &__sec.disable {
        opacity: 0.4;
        &:hover {
          background: none;
        }
      }
      &__sec.ch {
        h4 {
          padding: 0 0 2px 4px;
        }
      }
    }
    &__right {
      @include flexAcenter;
      &__sec {
        height: 32px;
        margin: 0 16px 0 0;
        padding: 0 8px;
        cursor: pointer;
        @include flexAcenter;
        border-radius: 8px;
        img {
          height: 16px;
          width: 16px;
        }
        .active-icon {
          display: none;
        }
        h5 {
          font-size: 13px;
          padding: 0 0 0 4px;
          font-weight: 400;
        }
        &:hover {
          background: $primary_grey;
        }
        &:last-child {
          margin: 0;
        }
      }
      &__sec.active {
        background: $primary_blue;
        .default-icon {
          display: none;
        }
        .active-icon {
          display: block;
        }
        h5 {
          color: $primary_white;
        }
      }
      &__sec.disable {
        opacity: 0.4;
        &:hover {
          background: none;
        }
      }
    }
  }
}

@media screen and (min-width: 1090px) and (max-width: 1249px) {
  .gallery-header {
    width: 760px;
    padding: 74px 0 8px 0;
    &__left {
      &__sec {
        padding: 0 6px;
        img {
          width: 16px;
          height: 16px;
          padding: 0 6px 0 0;
        }
        h4 {
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (min-width: 1250px) and (max-width: 1609px) {
  .gallery-header {
    width: 982px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1089px) {
  .gallery-header {
    width: 650px;
    top: 36px;
    padding: 64px 0 8px 0;
    &__container {
      &__left {
        &__sec {
          height: 27px;
          margin: 0 6px 0 0;
          padding: 0 6px;
          img {
            width: 14px;
            height: 14px;
          }
          h4 {
            font-size: 12px;
          }
        }
      }
      &__right {
        &__sec {
          margin: 0 12px 0 0;
          .react-select {
            width: 100% !important;
            &__control {
              width: 100% !important;
            }
            &__multi-value {
              &__label {
                font-size: 10px !important;
                padding: 4px 8px !important;
              }
            }
            &__placeholder {
              font-size: 12px !important;
            }
          }
        }
        .search {
          width: 240px;
          .css-b62m3t-container {
            width: 100% !important;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 767px) {
  .gallery-header {
    width: 86vw;
    top: 36px;
    padding: 54px 0 8px 0;
    &__container {
      &__top {
        &__sec {
          margin: 0 12px 0 0;
        }
      }
      &__left {
        align-items: center;
        &__sec {
          &__sec {
            height: 27px;
            margin: 0 6px 0 0;
            padding: 0 6px;
            img {
              width: 14px;
              height: 14px;
            }
            h4 {
              font-size: 12px;
            }
          }
        }
      }
      &__right {
        &__sec {
          margin: 0 6px 0 0;
          height: 27px;
          padding: 0 6px;
          img {
            width: 14px;
            height: 14px;
          }
          h4 {
            font-size: 12px;
          }
        }
        .search {
          width: 240px;
          .css-b62m3t-container {
            width: 100% !important;
          }
        }
      }
    }
  }
}
