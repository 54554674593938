@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";
@import "../../styles/typography.scss";

.home {
  background: #000000;
  &__container {
    width: 100%;
  }
  &__sections {
    @include flexcolumn;
  }
  &__banner {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    &__section {
      width: 95%;
      min-height: 110vh;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      &__banner-img {
        width: 100%;
        height: 100%;
        .banner-img {
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      video {
        width: 100%;
        height: 100%;
      }
      &__content {
        width: 100%;
        &__logo {
          position: absolute;
          bottom: 18.8vw;
          left: 4vw;
          img {
            width: 22vw;
          }
        }
        &__text {
          position: absolute;
          bottom: 8.8vw;
          left: -15vw;
          width: 95vw;
          padding: 1.8vw 0;
          background: radial-gradient(
            50% 50% at 50% 50%,
            rgba(0, 0, 0, 0.8) 4.79%,
            rgba(0, 0, 0, 0) 100%
          );
          h1 {
            padding: 0 0 0 24vw;
            width: 68vw;
            font-size: 2.2vw;
            font-family: $inter_semibold;
            line-height: 4vw;
            color: $primary_white;
          }
          p {
            width: 50vw;
            padding: 0 0 0 24vw;
            font-size: 1vw;
            line-height: 1.5vw;
            font-weight: 400;
            color: $primary_white;
          }
        }
        &__button {
          position: absolute;
          right: 8vw;
          bottom: 13.5vw;
          button {
            min-width: 12vw;
            height: 2.6vw;
            font-size: 1.2vw;
            padding: 0 1vw;
            background: $primary_blue;
            border: 2px solid $primary_grey;
            border-radius: 2vw;
            color: $primary_white;
            cursor: pointer;
          }
        }
      }
    }
  }
  &__features {
    @include flexJCenter;
    &__container {
      padding: 2.8vw 0;
      width: 70vw;
    }
    &__heading {
      @include flexJCenter;
      h2 {
        font-size: 1.7vw;
        font-family: $inter_semibold;
        color: $primary_white;
      }
    }
    &__cards {
      padding: 2vw 0;
    }
  }
  &__offers {
    @include flexJCenter;
    &__container {
      padding: 2.8vw 0;
      width: 67vw;
    }
    &__heading {
      @include flexJCenter;
      h2 {
        font-size: 1.7vw;
        font-family: $inter_semibold;
        color: $primary_white;
      }
    }
    &__cards {
      padding: 2vw 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  &__professionals {
    @include flexJCenter;
    &__container {
      padding: 2.8vw 0;
      width: 72vw;
    }
    &__heading {
      @include flexJCenter;
      h2 {
        font-size: 1.7vw;
        font-family: $inter_semibold;
        color: $primary_white;
      }
    }
    &__cards {
      padding: 2.4vw 0;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 1.4vw;
    }
    &__button {
      padding: 12px 0 0 0;
      @include flexJCenter;
      &__section {
        position: relative;
        @include flexJCenter;
        width: 11vw;
        button {
          width: 11vw;
          height: 2.6vw;
          font-size: 1.1vw;
          background: $primary_blue;
          border: 2px solid $primary_grey;
          border-radius: 2vw;
          color: $primary_white;
          cursor: pointer;
        }
        .sec-tooltip {
          display: none;
        }
        .sec-tooltip.active {
          display: block;
        }
      }
    }
  }
  &__brands {
    @include flexJCenter;
    &__container {
      padding: 2.8vw 0;
      width: 76vw;
    }
    &__heading {
      @include flexJCenter;
      h2 {
        font-size: 1.7vw;
        font-family: $inter_semibold;
        color: $primary_white;
      }
    }
    &__cards {
      padding: 2.4vw 0;
      .swiper-wrapper {
        display: flex;
        align-items: center;
      }
      .swiper-slide .brand__card__container__content p {
        height: 114px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: horizontal;
        line-height: 1.5;
        max-height: 110px;
        position: relative;
      }
      .swiper-slide-next .brand__card__container__content p {
        height: 194px;
        max-height: 244px;
      }
    }
  }
  &__footer {
    @include flexJCenter;
    &__container {
      padding: 2.8vw 0 1.2vw 0;
      width: 62vw;
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 767px) {
  .home {
    &__banner {
      &__section {
        min-height: 110vh;
        align-items: center;
        &__content {
          &__text {
            width: 100%;
            left: 0;
            bottom: 160px;
            @include flexColumnACenter;
            h1 {
              width: 90%;
              padding: 0;
              font-size: 28px;
              line-height: 40px;
            }
            p {
              width: 90%;
              padding: 0;
              font-size: 16px;
              line-height: 24px;
            }
          }
          &__button {
            bottom: 120px;
            right: 100px;
            button {
              min-width: 160px;
              font-size: 16px;
              height: 34px;
              border-radius: 10vw;
            }
          }
        }
      }
    }
    &__features {
      &__container {
        width: 90%;
        padding: 24px 0;
      }
      &__heading {
        h2 {
          font-size: 24px;
        }
      }
      &__cards {
        padding: 24px 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 12px;
      }
    }
    &__offers {
      &__container {
        padding: 24px 0;
        width: 90%;
      }
      &__heading {
        h2 {
          font-size: 24px;
        }
      }
      &__cards {
        padding: 24px 0;
        grid-template-columns: repeat(2, 1fr);
        gap: 12px;
      }
    }
    &__professionals {
      &__container {
        padding: 24px 0;
        width: 540px;
      }
      &__heading {
        h2 {
          font-size: 24px;
        }
      }
      &__cards {
        padding: 24px 0;
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;
      }
      &__button {
        &__section {
          width: 120px;
          button {
            width: 120px;
            font-size: 14px;
            height: 32px;
            border-radius: 30px;
          }
        }
      }
    }
    &__brands {
      &__container {
        padding: 24px 0;
        width: 90%;
      }
      &__heading {
        h2 {
          font-size: 24px;
        }
      }
      &__cards {
        padding: 24px 0;
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;
      }
    }
    &__footer {
      &__container {
        width: 90%;
        padding: 24px 0;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1090px) {
  .home {
    &__banner {
      &__section {
        min-height: 100vh;
        align-items: center;
        &__content {
          &__text {
            width: 100%;
            left: 10px;
            bottom: 130px;
            @include flexColumnACenter;
            h1 {
              width: 90%;
              padding: 0;
              font-size: 28px;
              line-height: 40px;
            }
            p {
              width: 90%;
              padding: 0;
              font-size: 16px;
              line-height: 24px;
            }
          }
          &__button {
            bottom: 100px;
            right: 100px;
            button {
              min-width: 160px;
              font-size: 16px;
              height: 34px;
              border-radius: 10vw;
            }
          }
        }
      }
    }
    &__features {
      &__container {
        width: 90%;
        padding: 24px 0;
      }
      &__heading {
        h2 {
          font-size: 24px;
        }
      }
      &__cards {
        padding: 24px 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 12px;
      }
    }
    &__offers {
      &__container {
        padding: 24px 0;
        width: 90%;
      }
      &__heading {
        h2 {
          font-size: 24px;
        }
      }
      &__cards {
        padding: 24px 0;
        grid-template-columns: repeat(2, 1fr);
        gap: 12px;
      }
    }
    &__professionals {
      &__container {
        padding: 24px 0;
        width: 72%;
      }
      &__heading {
        h2 {
          font-size: 24px;
        }
      }
      &__cards {
        padding: 24px 0;
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;
      }
      &__button {
        &__section {
          width: 120px;
          button {
            width: 120px;
            font-size: 14px;
            height: 32px;
            border-radius: 30px;
          }
        }
      }
    }
    &__brands {
      &__container {
        padding: 24px 0;
        width: 90%;
      }
      &__heading {
        h2 {
          font-size: 24px;
        }
      }
      &__cards {
        padding: 24px 0;
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;
      }
    }
    &__footer {
      &__container {
        width: 90%;
        padding: 24px 0;
      }
    }
  }
}
