@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.addon-popup {
  background: rgb(0 0 0 / 54%);
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &__popup {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &__container {
      width: 380px;
      background: $primary_white;
      padding: 16px;
      border-radius: $font_xverysmall;
    }
    &__section {
      @include flexColumnACenter;
      &__header {
        width: 100%;
        display: flex;
        position: relative;
        padding: 0 0 $font_xsmall 0;
        img {
          width: 18px;
          height: 18px;
        }
        h3 {
          font-size: 14px;
        }
        .cross {
          position: absolute;
          right: 0;
          top: 0;
          cursor: pointer;
        }
      }
      &__quantity {
        width: 100%;
        &__sec {
          @include flexbetween;
          align-items: center;
          padding: 8px 0 0 0;
          h5 {
            font-size: 12px;
            color: $grey_input;
            font-weight: 500;
          }
          h6 {
            font-size: 12px;
            color: $primary_blue;
            font-weight: 500;
            span {
              color: $grey_input;
              font-size: 12px;
              font-weight: 500;
            }
          }
          img {
            width: 4vw;
          }
        }
      }
      &__plans {
        background: $bg_grey_backround;
        border: 1px solid $primary_grey;
        border-radius: 4px;
        padding: 0 12px;
        margin: 8px 0 0 0;
        width: calc(100% - 24px);
      }
      &__plan:last-child {
        border-bottom: none;
      }
      &__plan {
        width: 100%;
        border-bottom: 1px solid $primary_grey;
        padding: 12px 0;
        h4 {
          font-size: 14px;
          color: $font_grey;
        }
        &__details {
          @include flexbetween;
          padding: 4px 0 0 0;
          h6 {
            font-size: 12px;
            color: $font_grey;
            font-weight: 500;
          }

          h5 {
            font-size: 14px;
            color: $font_grey;
            font-weight: 500;
          }
        }
      }
      &__amount {
        width: 100%;
        @include flexbetween;
        padding: 60px 0 12px 0;
        &__date {
          @include flexrowacenter;
          h6 {
            font-size: 12px;
            color: $grey_input;
            font-weight: 500;
          }
          h4 {
            font-size: 14px;
            padding: 0 0 0 6px;
            color: $primary_blue;
          }
        }
        h5 {
          font-size: 14px;
          color: $primary_blue;
        }
      }
      &__buttons {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        &__section {
          // width: 60%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          button {
            width: 120px;
            height: 27px;
            @include flexCenter;
            // padding: $font_xverysmall 0;
            border-radius: $font_large;
            border: none;
            background: $primary_black;
            color: $primary_white;
            cursor: pointer;
            font-size: 12px;
          }
          button:hover {
            opacity: 0.7;
          }
          button:active {
            opacity: 0.5;
          }
          button:disabled {
            cursor: not-allowed;
            background: $secondary_grey;
          }
          p {
            padding: 20px 0 0 0;
            font-size: 14px;
            font-weight: 400;
            color: #FF4D4F;
            text-align: end;
          }
        }
      }
      &__agree {
        display: flex;
        justify-content: flex-end;
        &__main {
          // width: calc(100% - 48px);
          padding: 12px 0;
          @include flexrow;
          align-items: baseline;
          justify-content: flex-end;
          input {
            width: 12px;
            height: 12px;
          }
          input[type="checkbox"] {
            accent-color: black;
          }
          p {
            text-align: start;
            font-size: 12px;
            color: $grey_input;
            font-weight: 400;
            word-spacing: 2px;
            padding: 0 0 0 10px;
            a {
              color: $primary_black;
              cursor: pointer;
              text-decoration: none;
              font-size: 12px;
            }
            a:hover {
              text-decoration: underline;
            }
          }
        }
      }
      .gif-icon {
        @include flexJCenter;
        .gif {
          width: 6vw;
        }
      }
    }
  }
}
