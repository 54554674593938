@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

:root {
  --textarea-height: 32px;
  --textarea-min-height: auto;
}

.file-popover {
  width: auto !important;
  .ant-popover-inner-content {
    padding: 0 8px !important;
  }
}

.upload-popover-content {
  width: 640px !important;
  inset: auto auto 280px calc(50% - 190px) !important;
  .ant-popover-inner {
    padding: 0 !important;
  }
  .ant-popover-inner-content {
    padding: 120px 0px !important;
    background: $bg_grey_backround;
    border: 1px solid $primary_grey;
    border-radius: 8px !important;
  }
}

.ant-modal-body {
  width: 100%;
  height: calc(100vh - 100px);
  overflow: auto;
  @include flexCenter;
  img {
    object-fit: cover;
    max-height: -webkit-fill-available;
    max-width: -webkit-fill-available;
  }
}

.upload-popover {
  width: 100%;
  &__content {
    @include flexColumnACenter;
    text-align: center;
    img {
      width: 102px;
      height: 102px;
    }
    h4 {
      font-size: 24px;
      font-weight: 600;
      color: $primary_black;
      padding: 8px 0;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      color: $primary_black;
    }
  }
}

.airi-bot {
  user-select: text;
  position: relative;
  background: $primary_white;
  height: 100%;
  &__sections {
    @include flexJEnd;
    @include flexrow;
  }
  &__sidenav {
    position: fixed;
    width: 232px;
    left: 0;
    margin: 12px;
    height: -webkit-fill-available;
    background: $primary_white;
    border: 1px solid $primary_grey;
    border-radius: $font_xverysmall;
    z-index: 98;
    &__container {
      padding: 16px 0;
      height: -webkit-fill-available;
    }
    &__sections {
      @include flexcolumn;
      height: -webkit-fill-available;
    }
    &__header {
      padding: 0 24px 20px 24px;
      h4 {
        color: $primary_blue;
        font-size: 16px;
        font-weight: 600;
      }
    }
    &__button {
      padding: 0 0 12px 0;
      button {
        width: 100%;
        @include flexAcenter;
        background: transparent;
        border: none;
        height: 40px;
        padding: 0 24px 0 24px;
        cursor: pointer;
        img {
          width: 12px;
          height: 12px;
        }
        span {
          padding: 0 0 0 14px;
          font-size: 14px;
          font-weight: 500;
          color: $primary_blue;
        }
      }
      button:hover {
        background: $grey_input;
      }
      button:active {
        background: $font_grey;
      }
    }
    &__chats {
      height: -webkit-fill-available;
      overflow: auto;
      padding: 0 2px 0 0;
      &__list {
        @include flexcolumn;
        height: max-content;
        &__sec {
          width: calc(100% - 48px);
          @include flexAcenter;
          justify-content: space-between;
          height: 40px;
          padding: 0 24px;
          margin: 0 0 12px 0;
          cursor: pointer;
          position: relative;
          &__input {
            width: 85%;
            .tooltipl {
              position: absolute;
              width: 200px;
              top: -5px;
              right: -100px;
              @include flexCenter;
              &__container {
                background: $primary_white;
                border-radius: 4px;
                padding: 6px 8px;
                z-index: 1000;
                box-shadow: 3px 2px 4px 0px #00000025,
                  -4px 2px 4px 0px rgba(0, 0, 0, 0.25);
                box-sizing: border-box;
                p {
                  font-size: 14px;
                }
              }
              &__section {
                width: 100%;
                @include flexCenter;
              }
              &__arrow {
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;
                border-left: 7px solid $primary_white;
                transform: rotate(180deg);
                z-index: 10001;
              }
            }
          }
          &__inputs {
            width: 100%;
            h5 {
              width: 100%;
              color: $primary_blue;
              font-size: 14px;
              font-weight: 500;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            input {
              font-size: 14px;
              background: $primary_white;
              color: $primary_black;
              outline: none;
              border: none;
            }
          }
          .trash-icons {
            display: none;
            img {
              width: 16px;
              height: 16px;
            }
          }
          &__tooltip {
            .bot-tooltip {
              display: none !important;
            }
          }
        }
        &__sec.edit {
          background: $primary_white !important;
          border-top: 1px solid $primary_blue;
          border-bottom: 1px solid $primary_blue;
          height: calc(40px - 2px);
          .trash-icons {
            display: none !important;
            .trash {
              display: none !important;
            }
            .hover {
              display: none !important;
            }
            .click {
              display: none !important;
            }
          }
        }
        &__sec.active {
          background: $secondary_grey;
        }
        &__sec:hover {
          .trash-icons {
            display: block !important;
            .trash {
              display: block;
            }
            .hover {
              display: none;
            }
            .click {
              display: none;
            }
          }
          .trash-icons:hover {
            .trash {
              display: none;
            }
            .hover {
              display: block;
            }
            .click {
              display: none;
            }
          }
          .trash-icons:active {
            .trash {
              display: none;
            }
            .hover {
              display: none;
            }
            .click {
              display: block;
            }
          }
        }
        &__sec:hover {
          background: $primary_white;
          height: calc(40px - 2px);
          border-top: 1px solid $primary_blue;
          border-bottom: 1px solid $primary_blue;
          .trash-icons {
            display: block;
            .trash {
              display: block;
            }
            .hover {
              display: none;
            }
            .click {
              display: none;
            }
          }
          .trash-icons:hover {
            .trash {
              display: none;
            }
            .hover {
              display: block;
            }
            .click {
              display: none;
            }
          }
          .trash-icons:active {
            .trash {
              display: none;
            }
            .hover {
              display: none;
            }
            .click {
              display: block;
            }
          }
        }
      }
    }
  }
  &__main {
    width: calc(100% - 245px);
    // height: calc(100vh - 50px - var(--textarea-height));
    &__section {
      height: 100%;
      @include flexcolumn;
      height: calc(100vh - 48px);
      position: relative;
      &__chat {
        @include flexcolumn;
        height: calc(100vh - 48px);
        overflow: scroll;
        border-bottom: 1px solid $primary_grey;
        &__container {
          background: $primary_white;
          border-bottom: 1px solid $primary_grey;
          @include flexJCenter;
          &__data {
            width: 740px;
            @include flexrow;
            flex-direction: row-reverse;
            padding: 24px 0;
            &__user {
              @include flexcolumn;
              align-items: flex-start;
              justify-content: space-between;
              width: 60px;
              &__top {
                @include flexcolumn;
                align-items: flex-start;
                img {
                  width: 32px;
                  height: 32px;
                  padding: 0 0 0 16px;
                }
                p {
                  font-size: 10px;
                  font-weight: 500;
                  color: $font_grey1;
                  padding: 12px 0 12px 16px;
                }
                .answer-p {
                  text-align: end;
                }
              }
              &__bottom {
                img {
                  width: 14px;
                  height: 14px;
                }
              }
            }
            &__content {
              width: 620px;
              &__file {
                width: 100%;
                @include flexJEnd;
                padding: 0 0 8px 0;
                &__section {
                  height: 56px;
                  width: 312px;
                  @include flexAcenter;
                  border-radius: 8px;
                  background: $primary_grey;
                  border: 1px solid $bg_grey_backround;
                  box-shadow: 4px 4px 4px #00000000;
                  position: relative;
                  cursor: pointer;
                  img {
                    width: 40px;
                    height: 40px;
                    padding: 0 12px;
                  }
                  &__name {
                    @include flexcolumn;
                    .name {
                      width: 230px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    }
                  }
                  &__download {
                    position: absolute;
                    top: -8px;
                    right: -8px;
                    background: $primary_white;
                    border: 1px solid $bg_grey_backround;
                    box-shadow: 4px 4px 4px #00000000;
                    width: 24px;
                    height: 24px;
                    @include flexCenter;
                    border-radius: 50%;
                    cursor: pointer;
                    .download {
                      width: 16px;
                      height: 16px;
                    }
                  }
                }
              }
              .thumbnail {
                img {
                  border-radius: 8px;
                }
                &__loading {
                  width: 200px;
                  height: 200px;
                  background: $primary_grey;
                  border-radius: 8px;
                }
              }
              pre {
                font-size: 14px;
                line-height: 18px;
                font-weight: 500;
                color: $primary_blue;
                // line-break: auto;
                text-wrap: auto;
                p {
                  line-break: anywhere;
                }
              }
              span {
                width: 60px;
              }
            }
          }
        }
        .answer {
          background: $bg_light_grey;
          &__data {
            flex-direction: row;
            &__user {
              align-items: flex-end;
              &__top {
                align-items: flex-end;
                img {
                  padding: 0 16px 0 0;
                }
                p {
                  padding: 12px 16px 12px 0;
                }
              }
            }
            &__content {
              &__file {
                justify-content: flex-start;
                &__section {
                  &__download {
                    position: absolute;
                    top: -8px;
                    right: -8px;
                    background: $primary_white;
                    border: 1px solid $bg_grey_backround;
                    box-shadow: 4px 4px 4px #00000000;
                    width: 24px;
                    height: 24px;
                    @include flexCenter;
                    border-radius: 50%;
                    cursor: pointer;
                    .download {
                      width: 16px;
                      height: 16px;
                    }
                  }
                }
              }
              pre {
                font-size: 14px;
                line-height: 18px;
                font-weight: 500;
                color: $primary_blue;
                line-break: anywhere;
                text-wrap: auto;
              }
            }
          }
        }
      }
      &__scroll {
        position: absolute;
        bottom: 32px;
        right: 22px;
        z-index: 100;
        img {
          width: 36px;
          height: 36px;
          cursor: pointer;
        }
      }
      &__empty {
        padding: 0 0 108px 0;
        &.file {
          padding: 0 0 172px 0;
        }
      }
      &__bottom {
        @include flexColumnACenter;
        justify-content: flex-end;
        position: fixed;
        right: 0;
        bottom: 0;
        width: 100%;
        width: calc(100% - 245px);
        max-height: 72px;
        &.file {
          max-height: 150px;
        }
        &__uploads {
          width: 640px;
          margin: 0 0 12px 0;
          @include flexwrap;
          gap: 12px;
          &__file {
            height: 56px;
            width: 312px;
            @include flexAcenter;
            border-radius: 8px;
            background: $primary_grey;
            border: 1px solid $bg_grey_backround;
            box-shadow: 4px 4px 4px #00000000;
            position: relative;
            img {
              width: 40px;
              height: 40px;
              padding: 0 12px;
            }
            &__name {
              @include flexcolumn;
              span {
                width: 230px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
            .close {
              display: none;
              width: 20px;
              height: 20px;
              position: absolute;
              top: -6px;
              right: -16px;
              cursor: pointer;
            }
            &:hover {
              .close {
                display: block;
              }
            }
          }
        }
        &__input {
          @include flexCenter;
          width: 640px;
          box-shadow: 0 0 transparent, 0 0 transparent, 0 0 transparent,
            0 0 transparent, 0 0 15px rgba(0, 0, 0, 0.1);
          margin: 0 0 32px 0;
          position: relative;
          border-radius: 8px;
          textarea {
            width: 100%;
            height: 100%;
            font-size: 16px;
            font-weight: 500;
            border: 1px solid $primary_grey;
            padding: 12px 36px 12px 12px;
            // padding: 12px 36px 12px 56px;
            border-radius: 8px;
            max-height: 160px;
            overflow: auto;
            font-family: $inter_regular;
          }
          textarea:focus {
            border: 1px solid $primary_blue;
            outline: none;
          }
          textarea:focus-visible {
            border: 1px solid $primary_blue;
            outline: none;
          }
          .non-resizable-textarea {
            resize: none;
            user-drag: none;
          }
          img {
            width: 20px;
            height: 20px;
            position: absolute;
            right: 12px;
            bottom: 12px;
            cursor: pointer;
          }
          .uploading {
            opacity: 0.6;
            cursor: default;
          }
          .count-remain {
            position: absolute;
            bottom: -20px;
            right: 0;
            font-size: 12px;
          }
          .upload-icon {
            img {
              bottom: 12px;
              left: 12px;
            }
            .image-upload {
              left: 32px;
            }
          }
        }
      }
    }
  }
  .down {
    background: rgb(0 0 0 / 54%);
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &__popup {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &__container {
        width: 380px;
        background: $primary_white;
        padding: 16px;
        border-radius: $font_xverysmall;
      }
      &__section {
        @include flexColumnACenter;
        &__header {
          width: 100%;
          display: flex;
          position: relative;
          padding: 0 0 12px 0;
          img {
            width: 20px;
            height: 20px;
          }
          h3 {
            font-size: 16px;
            padding: 0 0 0 $font_xverysmall;
          }
          .cross {
            position: absolute;
            right: 0;
            top: 0;
            cursor: pointer;
          }
        }
        &__content {
          width: 100%;
          justify-content: flex-start;
          @include flexrow;
          padding: 0 0 12px 0;
          p {
            font-size: 14px;
            color: $grey_input;
          }
        }
        &__buttons {
          padding: 16px 0 0 0;
          display: flex;
          justify-content: flex-end;
          width: 100%;
          &__section {
            width: 50%;
            display: flex;
            justify-content: space-between;
            button {
              width: 47%;
              padding: 8px 0;
              border-radius: 16px;
              border: none;
              background: $primary_black;
              color: $primary_white;
              cursor: pointer;
              font-size: 12px;
            }
            button:hover {
              opacity: 0.7;
            }
            button:active {
              opacity: 0.5;
            }
            .cancel {
              background: $primary_white;
              color: $primary_black;
              opacity: 0.5;
            }
            .cancel:hover {
              opacity: 0.7;
            }
            .cancel:active {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .delete {
    background: rgb(0 0 0 / 54%);
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &__popup {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &__container {
        width: 380px;
        background: $primary_white;
        padding: 16px;
        border-radius: $font_xverysmall;
      }
      &__section {
        @include flexColumnACenter;
        &__header {
          width: 100%;
          display: flex;
          position: relative;
          padding: 0 0 12px 0;
          img {
            width: 20px;
            height: 20px;
          }
          h3 {
            font-size: 16px;
            padding: 0 0 0 $font_xverysmall;
          }
          .cross {
            position: absolute;
            right: 0;
            top: 0;
            cursor: pointer;
          }
        }
        &__content {
          @include flexrow;
          width: 100%;
          justify-content: flex-start;
          padding: 0 0 12px 0;
          p {
            font-size: 14px;
            color: $grey_input;
          }
        }
        &__buttons {
          padding: 16px 0 0 0;
          display: flex;
          justify-content: flex-end;
          width: 100%;
          &__section {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            button {
              width: 25%;
              padding: 8px 0;
              border-radius: 16px;
              border: none;
              background: $primary_black;
              color: $primary_white;
              cursor: pointer;
              font-size: 12px;
            }
            button:hover {
              opacity: 0.7;
            }
            button:active {
              opacity: 0.5;
            }
            .cancel {
              background: $primary_white;
              color: $primary_black;
              opacity: 0.5;
            }
            .cancel:hover {
              opacity: 0.7;
            }
            .cancel:active {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .container {
    padding: 2vw 0;
    @include flexCenter;
    img {
      width: 4.5vw;
    }
  }
}

@media screen and (min-width: 1090px) and (max-width: 1250px) {
  .airi-bot {
    &__main {
      width: calc(100% - 210px);
      &__section {
        &__chat {
          &__container {
            &__data {
              padding: 18px 0;
              &__user {
                &__top {
                  img {
                    width: 20px;
                    height: 20px;
                  }
                  p {
                    padding: 8px 0 8px 12px;
                  }
                }
              }
            }
          }
          .answer {
            &__data {
              &__user {
                &__top {
                  padding: 8px 12px 8px 0;
                }
              }
            }
          }
        }
      }
    }
    &__sidenav {
      width: 196px;
      &__header {
        padding: 0 18px 14px 18px;
        h4 {
          font-size: 14px;
        }
      }
      &__button {
        button {
          padding: 0 18px;
          span {
            font-size: 12px;
          }
        }
      }
      &__chats {
        &__list {
          &__sec {
            padding: 0 18px;
            &__inputs {
              h5 {
                font-size: 12px;
              }
              input {
                font-size: 12px;
              }
            }
            .trash-icons {
              img {
                width: 12px;
                height: 12px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1090px) {
  .airi-bot {
    &__sidenav {
      width: 182px;
      &__container {
        padding: 12px 0;
      }
      &__header {
        padding: 0 16px 12px 16px;
        h4 {
          font-size: 14px;
        }
      }
      &__button {
        button {
          padding: 0 16px;
          img {
            width: 10px;
            height: 10px;
          }
          span {
            font-size: 12px;
            padding: 0 0 0 12px;
          }
        }
      }
      &__chats {
        &__list {
          &__sec {
            padding: 0 16px;
            height: 28px;
            margin: 0 0 8px 0;
            &__inputs {
              h5 {
                font-size: 12px;
              }
            }
            .trash-icons {
              .trash {
                width: 12px;
                height: 12px;
              }
            }
          }
          &__sec:hover {
            height: 26px;
          }
        }
      }
    }
    &__main {
      width: calc(100% - 195px);
      &__section {
        height: calc(100vh - 36px);
        &__chat {
          height: calc(100vh - 36px);
          &__container {
            &__data {
              width: 532px;
              padding: 16px 0;
              &__user {
                width: 48px;
                &__top {
                  img {
                    width: 24px;
                    height: 24px;
                  }
                  p {
                    padding: 8px 0 8px 12px;
                  }
                }
              }
              &__content {
                p {
                  font-size: 12px;
                }
              }
            }
          }
        }
        &__bottom {
          width: calc(100% - 195px);
          &__input {
            width: 532px;
            textarea {
              font-size: 12px;
              padding: 10px 28px 10px 10px;
            }
            img {
              width: 16px;
              height: 16px;
            }
            .count-remain {
              font-size: 10px;
              span {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 1090px) {
  .airi-bot {
    &__sidenav {
      width: 26vw;
      &__container {
        padding: 12px 0;
      }
      &__header {
        padding: 0 1.8vw 1.4vw 1.8vw;
        h4 {
          font-size: 2vw;
        }
      }
      &__button {
        button {
          padding: 0 1.8vw;
          img {
            width: 10px;
            height: 10px;
          }
          span {
            font-size: 1.8vw;
            padding: 0 0 0 1.4vw;
          }
        }
      }
      &__chats {
        &__list {
          &__sec {
            width: calc(100% - 4vw);
            padding: 0 1.8vw;
            height: 4vw;
            margin: 0 0 1vw 0;
            &__inputs {
              h5 {
                font-size: 1.8vw;
              }
            }
            .trash-icons {
              .trash {
                width: 12px;
                height: 12px;
              }
            }
          }
          &__sec:hover {
            height: 4vw;
          }
        }
      }
    }
    &__main {
      width: calc(100% - 28.4vw);
      &__section {
        height: calc(100vh - 36px);
        &__chat {
          height: calc(100vh - 36px);
          &__container {
            &__data {
              width: 66vw;
              padding: 1.8vw 0;
              &__user {
                width: 48px;
                &__top {
                  img {
                    width: 24px;
                    height: 24px;
                  }
                  p {
                    padding: 8px 0 8px 12px;
                  }
                }
              }
              &__content {
                p {
                  font-size: 12px;
                }
              }
            }
          }
        }
        &__bottom {
          width: calc(100% - 29vw);
          &__input {
            width: 66vw;
            textarea {
              font-size: 12px;
              padding: 10px 28px 10px 10px;
            }
            img {
              width: 16px;
              height: 16px;
            }
            .count-remain {
              font-size: 10px;
              span {
                font-size: 10px;
              }
            }
          }
        }
        &__empty {
          padding: 0 0 13.4vw 0;
        }
      }
    }
  }
}
