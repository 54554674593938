@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.loaderWrapper {
  @include flexCenter;
  overflow: hidden;
  z-index: 10000;

  &__loader {
    @include absoluteCenter;
    img {
      width: 48px !important;
      height: 48px !important;
      -webkit-animation: spin 3s linear infinite;
      animation: spin 3s linear infinite;
      border: none !important;
    }
  }

  &__smallLoader {
    img {
      width: 18px !important;
      height: 18px !important;
    }
  }
  &__tableLoader {
    img {
      width: 20px !important;
      height: 20px !important;
    }
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
.loaderMinHeight {
  height: 70rem;
}

@media screen and (max-width: 760px) {
  .loaderWrapper {
    &__loader {
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}
