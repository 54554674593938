@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.custom-popover-team {
  width: 196px !important;
  .ant-popover-inner-content {
    text-align: center !important;
    font-size: 14px !important;
    padding: 0 8px !important;
  }
}

.team-confirm {
  background: #0000008a;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__sections {
    width: 480px;
    background: $primary_white;
    padding: 16px;
    border-radius: 8px;
    @include flexColumnACenter;
    &__heading {
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &__top {
        @include flexAcenter;
        img {
          width: 18px;
          height: 18px;
        }
        h3 {
          font-size: 16px;
          font-weight: 600;
          color: $primary_blue;
          padding: 0 0 0 12px;
        }
      }
      p {
        font-size: 14px;
        color: $primary_blue;
        font-weight: 400;
        padding: 18px 0 0 0;
      }
    }
    &__input {
      width: 90%;
      padding: 24px 0;
      input {
        width: calc(100% - 24px);
        height: 40px;
        padding: 0 12px;
        border-radius: 8px;
        border: 1px solid $primary_grey;
        font-size: 14px;
        outline: none;
      }
      p {
        font-size: 12px;
        color: red;
        padding: 12px 0 0 0;
      }
    }
    &__buttons {
      width: 90%;
      @include flexJEnd;
      &__main {
        button {
          min-width: 80px;
          padding: 6px 12px;
          border-radius: 15px;
          border: none;
          background: $primary_black;
          color: $primary_white;
          cursor: pointer;
          font-size: 12px;
        }
        button:hover {
          opacity: 0.7;
        }
        button:active {
          opacity: 0.5;
        }
        button:disabled {
          background: $grey_input;
          cursor: default;
        }
        button:disabled:hover {
          opacity: 1;
        }
        button:disabled:active {
          opacity: 1;
        }
        .cancel {
          background: none;
          color: $primary_black;
          border: none;
        }
        .cancel:hover {
          background: none;
          opacity: 0.5;
          cursor: pointer;
        }
      }
    }
  }
}
