@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.news-dropdown-popover {
  width: 200px !important;
}

.dropdown {
  padding: 4px 0;
  &__option {
    height: 40px;
    @include flexrowacenter;
    padding: 0 8px;
    cursor: pointer;
    h5 {
      font-size: 14px;
      font-weight: 400;
    }
    &.active {
      background: $primary_blue;
      h5 {
        color: $primary_white;
      }
    }
  }
}

.news {
  background: rgb(0 0 0 / 54%);
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &__container {
    @include flexJCenter;
    height: 100%;
  }
  &__sections {
    margin: 60px 0 0 0;
    height: calc(100% - 72px);
    width: 1070px;
    background: $primary_white;
    border-radius: 8px;
  }
  &__main {
    height: 100%;
    padding: 0 40px;
    @include flexColumnACenter;
    gap: 20px;
    &__heading {
      margin: 40px 0 0 0;
      height: 38px;
      width: 100%;
      @include flexbetween;
      align-items: center;
      &__left {
        h4 {
          font-size: 18px;
          font-weight: 600;
        }
      }
      &__right {
        @include flexCenter;
        gap: 4px;
        padding: 0 8px;
        border-radius: 8px;
        height: 32px;
        h5 {
          font-size: 13px;
          font-weight: 400;
        }
        img {
          width: 16px;
          height: 16px;
        }
        .active {
          display: none;
        }
        &.active {
          background: $primary_blue;
          h5 {
            color: $primary_white;
          }
          .default {
            display: none;
          }
          .active {
            display: block;
          }
        }
      }
    }
    &__section {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: calc(100% - 110px);
      overflow: scroll;
      .gif {
        width: 100%;
        min-height: calc(var(--vh, 1vh) * 100 - 180px);
        @include flexCenter;
        img {
          width: 120px;
        }
        p {
          font-size: 12px;
        }
      }
    }
    &__card {
      width: 100%;
      padding: 0 0 12px 0;
      &__container {
        background: $primary_white;
        padding: 32px 20px;
        border-radius: 8px;
        border: 1px solid $primary_grey;
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
      &__heading {
        @include flexbetween;
        &__left {
          &__image {
            padding: 0 0 8px 0;
            @include flexrowacenter;
            h3 {
              padding: 0 0 0 8px !important;
            }
          }
          img {
            width: 24px;
            height: 24px;
          }
          h3 {
            font-size: 18px;
            font-weight: 600;
            padding: 0 0 8px 0;
          }
          span {
            font-size: 14px;
          }
        }
        &__right {
          h6 {
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
      &__content {
        p {
          font-size: 14px;
          font-weight: 400;
          color: black;
        }
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 767px) {
  .news {
    height: calc(100vh - 36px);
    margin: 36px 0 0 0;
    &__container {
      align-items: center;
    }
    &__sections {
      margin: 0;
      width: 82vw;
      height: calc(100% - 24px);
    }
    &__main {
      gap: 12px;
      padding: 0 20px;
      &__heading {
        margin: 18px 0 0 0;
        height: 32px;
        &__left {
          h4 {
            font-size: 16px;
          }
        }
      }
      &__section {
        height: calc(100% - 80px);
      }
      &__tabs {
        &__tab-list {
          border-radius: 18px;
          margin: 24px 0;
        }
        &__tab {
          font-size: 12px;
          padding: 0.8vw 1.2vw;
          margin: 0 4vw 0 0;
        }
        &__tab.active {
          border-radius: 18px;
        }
      }
      &__card {
        &__container {
          padding: 16px 12px;
        }
        &__heading {
          &__left {
            h3 {
              font-size: 16px;
            }
            span {
              font-size: 12px;
            }
          }
          &__right {
            h6 {
              font-size: 12px;
            }
          }
        }
        &__content {
          padding: 0;
          p {
            font-size: 10px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1090px) {
  .news {
    height: calc(100vh - 36px);
    margin: 36px 0 0 0;
    &__container {
      align-items: center;
    }
    &__sections {
      width: 650px;
      margin: 0;
      height: calc(100% - 36px);
    }
    &__main {
      gap: 12px;
      padding: 0 24px;
      &__heading {
        margin: 24px 0 0 0;
        height: 32px;
        &__left {
          h4 {
            font-size: 16px;
          }
        }
      }
      &__tabs {
        &__tab-list {
          border-radius: 18px;
          margin: 24px 0;
        }
        &__tab {
          font-size: 12px;
          padding: 4px 8px;
          margin: 0 24px 0 0;
        }
        &__tab.active {
          border-radius: 18px;
        }
      }
      &__card {
        &__container {
          padding: 20px 12px;
        }
        &__heading {
          &__left {
            h3 {
              font-size: 16px;
            }
            span {
              font-size: 12px;
            }
          }
          &__right {
            h6 {
              font-size: 12px;
            }
          }
        }
        &__content {
          p {
            font-size: 10px;
          }
        }
      }
    }
  }
}
