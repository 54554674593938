@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.ai-project {
  @include flexJEnd;
  background: $bg_light_grey;
  height: calc(var(--vh, 1vh) * 100 - 48px);

  &__container {
    width: calc(100% - 232px - 12px);
    @include flexJCenter;
    height: 100%;
    overflow: hidden;
  }

  &__sections {
    width: 952px;
    padding: 12px 0 0 0;
    img {
      width: 120px;
    }
  }
}

@media screen and (min-width:600px) and (max-width:767px) {
  .ai-project {
    &__container {
      width: calc(100% - 12vw);
    }
    &__sections {
      width: 90%;
    }
  }
}

@media screen and (min-width:768px) and (max-width:1090px) {
  .ai-project {
    &__container {
      width: calc(100% - 100px);
    }
    &__sections {
      width: 678px;
    }
  }
}