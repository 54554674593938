@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.sucess {
  background: rgb(0 0 0 / 54%);
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &__popup {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &__container {
      width: 350px;
        background: $primary_white;
        padding: 16px;
        border-radius: 8px;
    }
    &__section {
      @include flexColumnACenter;
      &__header {
        width: 100%;
        display: flex;
        position: relative;
        padding: 0 0 8pxl 0;
        img {
          width: 16px;
          height: 16px;
        }
        h3 {
          font-size: 14px;
          padding: 0 0 0 8px;
        }
        .cross {
          position: absolute;
          right: -12px;
          top: -12px;
          cursor: pointer;
        }
      }
      &__content {
        width: 100%;
        text-align: left;
        padding: 0 0 12px 0;
        p {
          font-size: 12px;
          color: $primary_blue;
        }
      }
      &__buttons {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        button {
          width: 120px;
          height: 27px;
          @include flexCenter;
          border-radius: $font_large;
          border: none;
          background: $primary_black;
          color: $primary_white;
          cursor: pointer;
          font-size: 12px;
        }
        button:hover {
          opacity: 0.7;
        }
        button:active {
          opacity: 0.5;
        }
      }
    }
  }
}
