@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.sub-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: hidden;
  z-index: 100;
  background: rgba(0, 0, 0, 0.7098039216);
  margin: 0 !important;
  animation: fadeIn 0.5s forwards;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  &__container {
    width: 100%;
    height: 100%;
    @include flexCenter;
    position: relative;
    .loader {
      position: absolute;
      width: 10vw;
      z-index: 100;
    }
    animation: slideIn 0.5s forwards;
    @keyframes slideIn {
      from {
        scale: 0.5;
        opacity: 0;
      }
      to {
        scale: 1;
        opacity: 1;
      }
    }
  }
  &__section {
    width: calc(1196px - 40px);
    height:85%;
    background: $primary_white;
    border-radius: 8px;
    border: 1px solid $primary_grey;
    padding: 1vh 1vw;
    &__header {
      padding: 2vh 0px 0px 0px;
      @include flexColumnACenter;
      h2 {
        font-size: 24px;
        font-weight: 600;
      }
      p {
        font-size: 14px;
        font-weight: 500;
        color: $font_grey;
      }
    }
    &__main {
      overflow: scroll;
      &__details {
        &__tabs {
          padding: 2vh 0;
          .react-tabs {
            @include flexColumnACenter;
            &__tab-panel {
              width: 100%;
              @include flexColumnACenter;
              .gif-icon {
                width: 100%;
                @include flexColumnACenter;
                .gif {
                  width: 6vw;
                }
              }
              .content1 {
                font-size: 16px;
                font-weight: 600;
                color: $primary_blue;
                text-align: center;
                padding: 0px 0 0 0;
              }
              .no-data {
                height: 65vh;
                @include flexCenter;
              }
            }
            &__tab-panel--selected {
              height: 65vh;
              overflow: auto;
            }
            &__tab-list {
              width: max-content;
              padding: 4px;
              margin: 0 0 2vh 0;
              display: flex;
              background: $bg_grey_backround;
              border-radius: 28px;
              border: 1px solid $primary_grey;
            }
            &__tab {
              list-style-type: none;
              padding: 9px 13px;
              cursor: pointer;
            }
            &__tab--selected {
              border: 1px solid $primary_blue;
              background: $primary_white;
              border-radius: 28px;
              padding: 8px 12px;
              span {
                font-size: 14px;
                font-weight: 600;
                color: $primary_blue;
              }
            }
            &__tab--selected::after {
              display: none !important;
            }
          }
        }
        &__tab {
          span {
            font-size: 14px;
            font-weight: 600;
            color: $grey_input;
          }
        }
        .center {
          .swiper-wrapper {
            justify-content: center !important;
          }
        }
        .start {
          .swiper-wrapper {
            justify-content: flex-start !important;
          }
        }
        &__content {
          width: 100%;
          @include flexColumnACenter;
          .swiper {
            padding: 2vh 0 0 0 !important;
            width: 100% !important;
          }
          &__sec:last-child {
            margin: 0;
          }
          &__sec {
            width: calc(280px - 40px);
            border: 1px solid $primary_grey;
            border-radius: 8px;
            padding: 20px 20px 0px 20px;
            margin: 0 12px 0 0;
            overflow: scroll;
            height:56vh;
            &__heading {
              padding: 1.5vh 0;
              h4 {
                font-size: 18px;
                font-weight: 600;
              }
            }
            &__currency {
              padding: 1.5vh 0;
              .discount-price {
                font-size: 32px;
                font-weight: 500;
              }
              .original-price {
                font-size: 14px;
                color: gray;
                text-decoration: line-through;
                margin-left: 8px;
              }
              .duration {
                font-size: 14px;
                color: $primary_blue;
                margin-left: 4px; /* Adjust spacing as needed */
              }
            }
            &__plan {
              padding: 1.5vh 0;
              p {
                font-size: 12px;
                font-weight: 500;
              }
            }
            &__button {
              padding: 1.5vh 0;
              display: flex;
              button {
                padding: 0;
                width: 100%;
                cursor: pointer;
                border-radius: 42px;
                height: 36px;
                color: $primary_white;
                background: $primary_black;
                // border: 2px solid $grey_input;
                border: none;
                @include flexCenter;
                span {
                  padding: 8px 0;
                  width: 100%;
                  border-radius: 42px;
                  font-size: 16px;
                  // height: 36px;
                }
              }
              .disabled {
                background: $grey_input;
                cursor: not-allowed;
              }
              .schedule:hover {
                border: none;
                .old {
                  width: 100%;
                  display: none;
                }
                .new {
                  width: 100%;
                  display: block;
                  padding: 10px 0;
                  background: $font_grey;
                  background: $grey_input;
                }
              }
              .schedule:active {
                background: $grey_input;
              }
              .schedule {
                .old {
                  display: block;
                  width: 100%;
                }
                .new {
                  display: none;
                  width: 100%;
                }
              }
              .upgrade {
                @include flexCenter;
                border-radius: 42px;
                span {
                  border-radius: 42px;
                  background: $primary_blue;
                  color: $primary_white;
                  cursor: pointer;
                }
              }
              .pro-plan {
                @include flexCenter;
                span {
                  background: $grey_input;
                  // border: 2px solid $primary_grey;
                  color: $primary_white;
                  cursor: pointer;
                }
              }
              p {
                @include flexrowacenter;
                font-size: 12px;
                padding: 8px 0;
                cursor: pointer;
                img {
                  padding: 0 0 0 8px;
                  width: 16px;
                  height: 16px;
                }
              }
              .hide {
                padding: 0 0 30px 0;
              }
            }
            &__points {
              // padding: 1.5vh 0;
              width: 100%;
              @include flexcolumn;
              align-items: flex-start;
            }
            &__point {
              width: 100%;
              border-bottom: 1px solid $primary_grey;
              padding: 4px 0;
              @include flexrowacenter;
              img {
                padding:0 8px;
                align-self: flex-start;
                width: 14px;
                height: 14px;
              }
              p {
                font-size: 12px;
                padding: 0 0 0 0px;
              }
              ul{
                margin: 4px 0px;
                padding: 0 0 0 24px;
              }
            }
            &__point:last-child {
              border-bottom: none;
            }
            &__badge {
              position: absolute;
              top: -15px; /* Adjust as needed */
              left: 10px; /* Adjust as needed */
              background-color: #000; /* Or any color you prefer */
              color: #fff; /* Text color */
              padding: 5px 10px;
              border-radius: 8px;
              font-size: 14px; /* Adjust as needed */
              // font-weight: bold;
            }
          }
        }
        &__addon {
          padding: 10px 0 0 0;
          @include flexwrap;
          align-items: flex-start;
          overflow: auto;
          width: 860px;
          .gif {
            width: 6vw;
          }
          &__sec:nth-child(3n) {
            margin: 0 0 10px 0;
          }
          &__sec {
            width: calc(280px - 40px);
            height: max-content;
            border: 1px solid $primary_grey;
            border-radius: 8px;
            padding: 20px;
            margin: 0 4px 4px 0;
            &__heading {
              padding: 6px 0;
              h6 {
                font-size: 18px;
                font-weight: 600;
              }
            }
            &__currency {
              padding: 24px 0;
              h2 {
                font-size: 24px;
                span {
                  font-size: 12px;
                }
              }
            }
            &__button {
              padding: 18px 0 0 0;
              // @include flexColumnACenter;
              button {
                width: calc(100% - 10px);
                height: 36px;
                border-radius: 42px;
                background: $primary_blue;
                border: 2px solid $primary_grey;
                color: $primary_white;
                font-size: 14px;
                cursor: pointer;
              }
              button:hover {
                background: $font_grey;
              }
              button:active {
                background: $grey_input;
              }
              .active {
                background: $primary_white;
                color: $primary_blue;
                border: 2px solid $grey_input;
                cursor: default;
              }
              .active:hover {
                background: $primary_white;
              }
            }
            &__point {
              width: 100%;
              padding: 6px 0;
              @include flexrowacenter;
              img {
                align-self: flex-start;
                width: 14px;
                height: 14px;
              }
              p {
                font-size: 12px;
                padding: 0 0 0 8px;
                color: $font_grey;
              }
              ul{
                margin: 4px 0px;
                padding: 0 0 0 24px;
              }
            }
          }
        }
        &__specialAddon {
          width: 100%;
          @include flexColumnACenter;
          .swiper {
            padding: 15px 0 0 0 !important;
            width: 100% !important;
          }
          &__sec:last-child {
            margin: 0;
          }
          &__sec {
            width: calc(280px - 40px);
            border: 1px solid $primary_grey;
            border-radius: 8px;
            padding: 20px 20px 0px 20px;
            margin: 0 12px 0 0;
            overflow: scroll;
            max-height:52vh;
            &__heading {
              padding: 1.5vh 0;
              h4 {
                font-size: 18px;
                font-weight: 600;
              }
            }
            &__currency {
              padding: 1.5vh 0;
              .discount-price {
                font-size: 32px;
                font-weight: 500;
              }
              .original-price {
                font-size: 14px;
                color: gray;
                text-decoration: line-through;
                margin-left: 8px;
              }
              .duration {
                font-size: 14px;
                color: $primary_blue;
                margin-left: 4px; /* Adjust spacing as needed */
              }
            }
            &__plan {
              padding: 1.5vh 0;
              p {
                font-size: 12px;
                font-weight: 500;
              }
            }
            &__button {
              padding: 1.5vh 0;
              display: flex;
              button {
                padding: 0;
                width: 100%;
                cursor: pointer;
                border-radius: 42px;
                height: 36px;
                color: $primary_white;
                background: $primary_black;
                // border: 2px solid $grey_input;
                border: none;
                @include flexCenter;
                span {
                  padding: 8px 0;
                  width: 100%;
                  border-radius: 42px;
                  font-size: 16px;
                  // height: 36px;
                }
                &:disabled {
                  background: $grey_input;
                  cursor: default;
                }
              }
              .active {
                background: $grey_input;
                cursor: not-allowed;
              }
              .schedule:hover {
                border: none;
                .old {
                  width: 100%;
                  display: none;
                }
                .new {
                  width: 100%;
                  display: block;
                  padding: 10px 0;
                  background: $font_grey;
                  background: $grey_input;
                }
              }
              .schedule:active {
                background: $grey_input;
              }
              .schedule {
                .old {
                  display: block;
                  width: 100%;
                }
                .new {
                  display: none;
                  width: 100%;
                }
              }
              .upgrade {
                @include flexCenter;
                border-radius: 42px;
                span {
                  border-radius: 42px;
                  background: $primary_blue;
                  color: $primary_white;
                  cursor: pointer;
                }
              }
              .pro-plan {
                @include flexCenter;
                span {
                  background: $grey_input;
                  // border: 2px solid $primary_grey;
                  color: $primary_white;
                  cursor: pointer;
                }
              }
              p {
                @include flexrowacenter;
                font-size: 12px;
                padding: 8px 0;
                cursor: pointer;
                img {
                  padding: 0 0 0 8px;
                  width: 16px;
                  height: 16px;
                }
              }
              .hide {
                padding: 0 0 30px 0;
              }
            }
            &__points {
              padding: 1.5vh 0;
              width: 100%;
              @include flexcolumn;
              align-items: flex-start;
            }
            &__point {
              width: 100%;
              border-bottom: 1px solid $primary_grey;
              padding: 4px 0;
              @include flexrowacenter;
              img {
                align-self: flex-start;
                width: 14px;
                height: 14px;
              }
              p {
                font-size: 12px;
                padding: 0 0 0 8px;
              }
              ul{
                margin: 4px 0px;
                padding: 0 0 0 24px;
              }
              .bonus-label {
                background-color: #28a36f;
                border-radius: 5px;
                color: white;
                font-size: 10px;
                padding: 2px 4px;
                margin-left: 5px;
              }
            }
            &__point:last-child {
              border-bottom: none;
            }
            &__badge {
              position: absolute;
              top: -15px; /* Adjust as needed */
              left: 10px; /* Adjust as needed */
              background-color: #000; /* Or any color you prefer */
              color: #fff; /* Text color */
              padding: 5px 10px;
              border-radius: 8px;
              font-size: 14px; /* Adjust as needed */
              // font-weight: bold;
            }
          }
        }
      }
    }
    &__contact {
      text-align: center;
      margin-top: 24px;
      p {
        font-size: 14px;
        color: $font_grey;
        padding: 0 0 6px 0;
      }
      button {
        background: $primary_blue;
        border: 2px solid $primary_grey;
        color: $primary_white;
        height: 36px;
        border-radius: 42px;
        cursor: pointer;
        padding: 0 12px;
        margin: 0 0 8px 0;
      }
    }
  }
}

@media screen and (min-width: 1090px) and (max-width: 1250px) {
  .sub-popup {
    &__section {
      width: 1024px;
      &__header {
        h2 {
          font-size: 22px;
        }
        p {
          font-size: 14px;
        }
      }
      &__main {
        height: 75vh;
        &__details {
          @include flexJCenter;
          overflow: hidden;
          height: 100%;
          &__tabs {
            padding: 1.5vh 0;
            overflow: auto;
            height: auto;
            width: 100%;
            .react-tabs {
              &__tab-panel--selected {
                height: auto;
                overflow: unset;
              }
            }
          }
          &__tab {
            .react-tabs {
              &__tab--selected {
                height: auto;
                overflow: unset;
                span {
                  font-size: 12px;
                }
              }
            }
          }
          &__content {
            &__sec {
              width: calc(245px - 20px);
              padding: 10px;
              &__heading {
                padding: 8px 0;
                h4 {
                  font-size: 16px;
                }
              }
              &__currency {
                .discount-price {
                  font-size: 28px;
                  font-weight: 500;
                }
                .original-price {
                  font-size: 12px;
                  color: gray;
                  text-decoration: line-through;
                  margin-left: 8px;
                }
                .duration {
                  font-size: 12px;
                  color: $primary_blue;
                  margin-left: 4px; /* Adjust spacing as needed */
                }
              }
              &__points {
                padding: 8px 0;
              }
              &__point {
                img {
                  width: 10px;
                  height: 10px;
                }
                p {
                  font-size: 10px;
                }
                ul{
                  margin: 3px 0px;
                  padding: 0 0 0 20px;
                }
              }
              &__button {
                button {
                  height: 36px;
                  span {
                    font-size: 14px;
                  }
                }
              }
            }
            .swiper {
              height: auto !important;
              overflow: unset !important;
            }
          }
          &__addon {
            width: 626px;
            &__sec {
              width: calc(200px - 20px);
              padding: 10px;
              margin: 0 7px 7px 0;
              &__heading {
                padding: 8px 0;
                h4 {
                  font-size: 16px;
                }
              }
              &__currency {
                padding: 20px 0;
                h2 {
                  font-size: 20px;
                  span {
                    font-size: 10px;
                  }
                }
              }
              &__button {
                padding: 8px 0;
                button {
                  font-size: 14px;
                }
                p {
                  font-size: 8px;
                  img {
                    width: 12px;
                    height: 12px;
                  }
                }
              }
              &__points {
                padding: 8px 0;
              }
              &__point {
                img {
                  width: 10px;
                  height: 10px;
                }
                p {
                  font-size: 10px;
                }
                ul{
                  margin: 3px 0px;
                  padding: 0 0 0 20px;
                }
              }
            }
            .swiper {
              height: auto !important;
              overflow: unset !important;
            }
          }
          &__specialAddon {
            &__sec {
              width: calc(245px - 20px);
              padding: 10px;
              &__heading {
                padding: 8px 0;
                h4 {
                  font-size: 16px;
                }
              }
              &__currency {
                .discount-price {
                  font-size: 28px;
                  font-weight: 500;
                }
                .original-price {
                  font-size: 12px;
                  color: gray;
                  text-decoration: line-through;
                  margin-left: 8px;
                }
                .duration {
                  font-size: 12px;
                  color: $primary_blue;
                  margin-left: 4px; /* Adjust spacing as needed */
                }
              }
              &__points {
                padding: 8px 0;
              }
              &__point {
                img {
                  width: 10px;
                  height: 10px;
                }
                p {
                  font-size: 10px;
                }
                ul{
                  margin: 3px 0px;
                  padding: 0 0 0 20px;
                }
                .bonus-label {
                  background-color: #28a36f;
                  font-size: 0px;
                  padding: 5px;
                  color: transparent;
                }
              }
              &__button {
                button {
                  height: 36px;
                  span {
                    font-size: 14px;
                  }
                }
              }
            }
            .swiper {
              height: auto !important;
              overflow: unset !important;
            }
          }
        }
      }
      &__contact {
        p {
          font-size: 12px;
          span {
            font-size: 12px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1090px) {
  .sub-popup {
    &__section {
      width: 720px;
      &__header {
        h2 {
          font-size: 22px;
        }
        p {
          font-size: 14px;
        }
      }
      &__main {
        height: 75vh;
        &__details {
          @include flexJCenter;
          overflow: hidden;
          height: 100%;
          &__tabs {
            padding: 1.5vh 0;
            overflow: auto;
            height: auto;
            width: 100%;
            .react-tabs {
              &__tab-panel--selected {
                height: auto;
                overflow: unset;
              }
            }
          }
          &__tab {
            span {
              font-size: 12px;
            }
          }
          &__content {
            &__sec {
              width: calc(230px - 20px);
              padding: 10px;
              &__heading {
                padding: 8px 0;
                h4 {
                  font-size: 16px;
                }
              }
              &__currency {
                .discount-price {
                  font-size: 24px;
                  font-weight: 500;
                }
                .original-price {
                  font-size: 10px;
                  color: gray;
                  text-decoration: line-through;
                  margin-left: 8px;
                }
                .duration {
                  font-size: 10px;
                  color: $primary_blue;
                  margin-left: 4px; /* Adjust spacing as needed */
                }
              }
              &__button {
                padding: 8px 0;
                button {
                  font-size: 14px;
                }
                p {
                  font-size: 8px;
                  img {
                    width: 12px;
                    height: 12px;
                  }
                }
              }
              &__points {
                padding: 8px 0;
              }
              &__point {
                img {
                  width: 10px;
                  height: 10px;
                }
                p {
                  font-size: 10px;
                }
                ul{
                  margin: 3px 0px;
                  padding: 0 0 0 20px;
                }
              }
              &__button {
                button {
                  height: 36px;
                  span {
                    font-size: 14px;
                  }
                }
              }
            }
            .swiper {
              height: auto !important;
              overflow: unset !important;
            }
          }
          &__addon {
            width: 536px;
            &__sec {
              width: calc(170px - 20px);
              padding: 10px;
              margin: 0 7px 7px 0;
              &__heading {
                padding: 8px 0;
                h4 {
                  font-size: 16px;
                }
              }
              &__currency {
                padding: 16px 0;
                h2 {
                  font-size: 16px;
                  span {
                    font-size: 8px;
                  }
                }
              }
              &__button {
                padding: 8px 0;
                button {
                  font-size: 14px;
                }
                p {
                  font-size: 8px;
                  img {
                    width: 12px;
                    height: 12px;
                  }
                }
              }
              &__points {
                padding: 8px 0;
              }
              &__point {
                img {
                  width: 10px;
                  height: 10px;
                }
                p {
                  font-size: 10px;
                }
                ul{
                  margin: 3px 0px;
                  padding: 0 0 0 20px;
                }
              }
            }
            .swiper {
              height: auto !important;
              overflow: unset !important;
            }
          }
          &__specialAddon {
            &__sec {
              width: calc(230px - 20px);
              padding: 10px;
              &__heading {
                padding: 8px 0;
                h4 {
                  font-size: 16px;
                }
              }
              &__currency {
                .discount-price {
                  font-size: 24px;
                  font-weight: 500;
                }
                .original-price {
                  font-size: 10px;
                  color: gray;
                  text-decoration: line-through;
                  margin-left: 8px;
                }
                .duration {
                  font-size: 10px;
                  color: $primary_blue;
                  margin-left: 4px; /* Adjust spacing as needed */
                }
              }
              &__button {
                padding: 8px 0;
                button {
                  font-size: 14px;
                }
                p {
                  font-size: 8px;
                  img {
                    width: 12px;
                    height: 12px;
                  }
                }
              }
              &__points {
                padding: 8px 0;
              }
              &__point {
                img {
                  width: 10px;
                  height: 10px;
                }
                p {
                  font-size: 10px;
                }
                ul{
                  margin: 3px 0px;
                  padding: 0 0 0 20px;
                }
                .bonus-label {
                  background-color: #28a36f;
                  font-size: 0px;
                  padding: 4px;
                  color: transparent;
                }
              }
            }
            .swiper {
              height: auto !important;
              overflow: unset !important;
            }
          }
        }
      }
      &__contact {
        p {
          padding: 0 0 8px 0;
        }
        button {
          height: 28px;
          font-size: 12px;
        }
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 767px) {
  .sub-popup {
    &__container {
      top: 10%;
      height: 80vh;
    }
    &__section {
      width: 90vw;
      height: 100%;
      padding: 12px;
      &__header {
        padding: 12px 0 0 0;
        h2 {
          font-size: 20px;
          padding: 0 0 4px 0;
        }
        p {
          font-size: 12px;
        }
      }
      &__main {
        height: 70vh;
        &__details {
          @include flexJCenter;
          overflow: hidden;
          height: 100%;
          &__tabs {
            padding: 28px 0;
            width: 100%;
            overflow: auto;
            height: auto;
            .react-tabs {
              &__tab {
                padding: 2px 12px 6px 12px;
              }
              &__tab:first-child {
                margin: 0 8px 0 0;
              }
              &__tab--selected {
                padding: 3px 11px;
                border-radius: 23px;
                span {
                  font-size: 12px;
                }
              }
              &__tab-panel {
                .content1 {
                  width: 100%;
                }
              }
              &__tab-panel--selected {
                height: auto;
                overflow: unset;
              }
            }
          }
          &__tab {
            span {
              font-size: 12px;
            }
          }
          &__content {
            &__sec {
              width: calc(175px - 20px);
              padding: 10px;
              &__heading {
                padding: 8px 0;
                h4 {
                  font-size: 16px;
                }
              }
              &__currency {
                .discount-price {
                  font-size: 20px;
                  font-weight: 500;
                }
                .original-price {
                  font-size: 8px;
                  color: gray;
                  text-decoration: line-through;
                  margin-left: 8px;
                }
                .duration {
                  font-size: 8px;
                  color: $primary_blue;
                  margin-left: 4px; /* Adjust spacing as needed */
                }
              }
              &__button {
                button {
                  height: 36px;
                  span {
                    font-size: 14px;
                  }
                }
              }
              &__points {
                padding: 8px 0;
              }
              &__point {
                img {
                  width: 10px;
                  height: 10px;
                }
                p {
                  font-size: 10px;
                }
                ul{
                  margin: 3px 0px;
                  padding: 0 0 0 20px;
                }
              }
            }
            .swiper {
              height: auto !important;
              overflow: unset !important;
            }
          }
          &__addon {
            width: 496px;
            &__sec {
              width: calc(160px - 20px);
              padding: 10px;
              margin: 0 4px 4px 0;
              &__heading {
                padding: 8px 0;
                h4 {
                  font-size: 16px;
                }
              }
              &__currency {
                padding: 16px 0;
                h2 {
                  font-size: 16px;
                  span {
                    font-size: 8px;
                  }
                }
              }
              &__button {
                padding: 8px 0;
                button {
                  font-size: 14px;
                }
                p {
                  font-size: 8px;
                  img {
                    width: 12px;
                    height: 12px;
                  }
                }
              }
              &__points {
                padding: 8px 0;
              }
              &__point {
                img {
                  width: 10px;
                  height: 10px;
                }
                p {
                  font-size: 10px;
                }
                ul{
                  margin: 3px 0px;
                  padding: 0 0 0 20px;
                }
              }
            }
            .swiper {
              height: auto !important;
              overflow: unset !important;
            }
          }
          &__specialAddon {
            &__sec {
              width: calc(175px - 20px);
              padding: 10px;
              &__heading {
                padding: 8px 0;
                h4 {
                  font-size: 16px;
                }
              }
              &__currency {
                .discount-price {
                  font-size: 20px;
                  font-weight: 500;
                }
                .original-price {
                  font-size: 8px;
                  color: gray;
                  text-decoration: line-through;
                  margin-left: 8px;
                }
                .duration {
                  font-size: 8px;
                  color: $primary_blue;
                  margin-left: 4px; /* Adjust spacing as needed */
                }
              }
              &__button {
                button {
                  height: 36px;
                  span {
                    font-size: 14px;
                  }
                }
              }
              &__points {
                padding: 8px 0;
              }
              &__point {
                img {
                  width: 10px;
                  height: 10px;
                }
                p {
                  font-size: 10px;
                }
                ul{
                  margin: 3px 0px;
                  padding: 0 0 0 20px;
                }
                .bonus-label {
                  background-color: #2ba471;
                  font-size: 0px;
                  padding: 4px;
                  color: transparent;
                }
              }
            }
            .swiper {
              height: auto !important;
              overflow: unset !important;
            }
          }
        }
      }
      &__contact {
        @include flexCenter;
        p {
          padding: 0 12px 0 0;
          font-size: 12px;
        }
        button {
          height: 28px;
          font-size: 12px;
          margin: 0;
        }
      }
    }
  }
}
