@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.tgallery {
  background: $bg_light_grey;
  position: relative;
  &__container {
    @include flexJCenter;
    height: 100%;
  }
  &__sections {
    padding: 0;
  }
  &__main {
    width: 100%;
    @include flexJCenter;
    &__section {
      @include flexColumnACenter;
      .react-tabs {
        &_tab-list {
          border: none !important;
        }
        &__tab {
          border: none !important;
          padding: 0 !important;
        }
        &__tab-panel {
          padding: 88px 0 0 0;
          overflow: scroll;
        }
      }
      &__scroll {
        position: fixed;
        bottom: 20px;
        right: 20px;
        img {
          cursor: pointer;
          width: 40px;
          height: 40px;
          transform: rotate(180deg);
        }
      }
      &__button {
        position: fixed;
        bottom: 30px;
        right: 68px;
        button {
          height: 32px;
          border: none;
          color: $primary_white;
          background: $primary_blue;
          padding: 0 12px;
          border-radius: 23px;
          cursor: pointer;
        }
        button:hover {
          opacity: 0.7;
        }
        button:active {
          opacity: 0.5;
        }
        button:disabled {
          opacity: 0.3;
          cursor: not-allowed;
        }
      }
    }
    &__images {
      &__container {
        padding: 178px 0 0 0;
        width: 1272px;
        @include flexwrap;
        .gif-container {
          width: 100%;
          min-height: calc(var(--vh, 1vh) * 100 - 150px);
          @include flexCenter;
          img {
            width: 120px;
          }
          p {
            font-size: 12px;
          }
        }
        .gif-container1 {
          width: 100%;
          @include flexCenter;
          margin: 0 0 100px 0;
          img {
            width: 120px;
          }
        }
        .row {
          width: 20%;
          .column {
            width: calc(100% - 8px);
            padding: 0 0 8px 0;
            .icon {
              display: none;
              width: 2vw;
            }
            img {
              width: 100%;
              display: flex;
              border-radius: 8px;
              cursor: pointer;
              // border: 1px solid $primary_grey;
            }
          }
          .column-cont.selected {
            border: 2px dashed $grey_input;
            border-radius: 8px;
            padding: 1vw;
            .icon {
              position: absolute;
              display: block;
              right: 0.25vw;
              top: 0.25vw;
            }
          }
          .column-cont__sec {
            position: relative;
            .favorites {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
            }
            .fav-icon {
              width: max-content;
              height: fit-content;
              margin: 4px 0 0 4px;
              position: relative;
              .fav {
                display: none;
                width: 24px;
                height: 24px;
                cursor: default;
              }
              // .fav1 {
              //   display: none;
              // }
              // .fav2 {
              //   display: none;
              // }
              .tooltip-show {
                display: none;
              }
            }
            .fav-icon:hover {
              // .fav {
              //   display: none;
              // }
              // .fav1 {
              //   display: block;
              // }
              // .fav2 {
              //   display: none;
              // }
              .tooltip-show {
                display: block;
              }
            }
            .video-gen {
              position: absolute;
              bottom: 0;
              right: 0;
              &__icon {
                width: 32px !important;
                height: 32px;
                margin: 0 4px 4px 0;
              }
            }
          }
          .column-cont__sec:hover {
            .favorites {
              background: #00000020;
              border-radius: 8px;
            }
            .fav {
              display: none;
            }
            .fav1 {
              display: none;
            }
            .fav2 {
              display: none;
            }
          }
          .favorite {
            .fav-icon {
              .fav {
                display: none;
              }
              .fav1 {
                display: none;
              }
              .fav2 {
                display: block;
              }
            }
            .video-gen {
              position: absolute;
              bottom: 0;
              right: 0;
              &__icon {
                width: 32px !important;
                height: 32px;
                margin: 0 4px 4px 0;
              }
            }
            // .fav-icon:hover {
            //   .fav {
            //     display: none;
            //   }
            //   .fav1 {
            //     display: block;
            //   }
            //   .fav2 {
            //     display: none;
            //   }
            // }
          }
          .loading-img {
            @include flexCenter;
            img {
              width: 60px;
            }
          }
        }
        .row:last-child {
          .column {
            width: calc(100% - 8px);
            padding: 0 0 8px 0;
          }
        }
        &__no-project {
          width: 100%;
          @include flexColumnACenter;
          margin: 100px 0 0 0;
          p {
            font-size: 14px;
            font-weight: 400;
            margin: 0 0 12px 0;
          }
          button {
            @include flexCenter;
            border-radius: 8px;
            background: $primary_blue;
            border: 1px solid $primary_grey;
            width: 160px;
            height: 40px;
            cursor: pointer;
            img {
              width: 20px;
              height: 20px;
            }
            .add-active {
              display: none;
            }
            span {
              padding: 8px;
              font-size: 16px;
              color: $primary_white;
            }
          }
          button:hover {
            background: $secondary_grey;
            span {
              color: $primary_blue;
            }
            .add-active {
              display: block;
            }
            .add-default {
              display: none;
            }
          }
          button:active {
            background: none;
            span {
              color: $primary_blue;
            }
            .add-active {
              display: block;
            }
            .add-default {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1090px) and (max-width: 1249px) {
  .tgallery {
    height: calc(100vh - 48px);
    &__main {
      width: 100%;
      @include flexJCenter;
      &__section {
        padding: 0;
        @include flexJCenter;
        position: relative;
        width: calc(100vw - 232px - 24px);
      }
      &__images {
        &__container {
          width: 760px;
          .row {
            width: 25%;
            .column {
              width: calc(100% - 8px);
              padding: 0 0 8px 0;
            }
          }
          .row:last-child {
            .column {
              width: calc(100% - 8px);
              padding: 0 0 8px 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1250px) and (max-width: 1609px) {
  .tgallery {
    &__main {
      &__images {
        &__container {
          width: 982px;
          .row {
            .column {
              width: calc(100% - 8px);
              padding: 0 0 8px 0;
            }
          }
          .row:last-child {
            .column {
              width: calc(100% - 8px);
              padding: 0 0 8px 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1089px) {
  ::-webkit-scrollbar {
    width: 4px !important;
    height: 0px !important;
  }
  .tgallery {
    &__container {
      width: 100%;
    }
    &__main {
      &__section {
        &__scroll {
          right: 16px;
          bottom: 16px;
          img {
            width: 30px;
            height: 30px;
          }
        }
        &__button {
          bottom: 24px;
          right: 52px;
          button {
            font-size: 12px;
            height: 24px;
            padding: 0 9px;
            border-radius: 23px;
          }
        }
      }
      &__images {
        &__container {
          width: 650px;
          padding: 164px 0 0 0;
          .row {
            width: 33.3%;
            .column {
              width: calc(100% - 6px);
              padding: 0 0 6px 0;
              img {
                border-radius: 6px;
              }
            }
            .column-cont.selected {
              padding: 4px;
            }
          }
          .row:last-child {
            .column {
              width: calc(100% - 6px);
              padding: 0 0 6px 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 767px) {
  ::-webkit-scrollbar {
    width: 4px !important;
    height: 0px !important;
  }
  .tgallery {
    &__container {
      width: 100%;
    }
    &__main {
      &__section {
        &__scroll {
          right: 16px;
          bottom: 16px;
          img {
            width: 30px;
            height: 30px;
          }
        }
        &__button {
          bottom: 24px;
          right: 52px;
          button {
            font-size: 12px;
            height: 24px;
            padding: 0 9px;
            border-radius: 23px;
          }
        }
      }
      &__images {
        &__container {
          width: 86vw;
          padding: 152px 0 0 0;
          .row {
            width: 33.3%;
            .column {
              width: calc(100% - 6px);
              padding: 0 0 6px 0;
              img {
                border-radius: 6px;
              }
            }
            .column-cont.selected {
              padding: 4px;
            }
          }
          .row:last-child {
            .column {
              width: calc(100% - 6px);
              padding: 0 0 6px 0;
            }
          }
        }
      }
    }
  }
}
