@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.user-layout {
  width: 100%;
  &__container {
    width: 100%;
    @include flexJCenter;
  }
  &__sections {
    width: 100%;
    @include flexColumnACenter;
    &__header {
      width: 100%;
    }
    &__main {
      width: 100%;
      margin: 48px 0 0 0;
    }
  }
}

@media screen and (max-width: 1090px) {
  .user-layout {
    &__sections {
      &__main {
        margin: 36px 0 0 0;
      }
    }
  }
}
