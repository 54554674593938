@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/typography.scss";

.features {
  padding: 3vw 0;
  border-bottom: 1px solid #d4d8d7;
  &__container {
    @include flexbetween;
    &__left {
      width: 42%;
      &__image {
        width: 100%;
        height: auto;
        video {
          border-radius: 0.7vw;
        }
      }
    }
    &__right {
      width: 53%;
      @include flexcolumn;
      justify-content: center;
      &__content {
        width: 39vw;
        &__heading {
          display: flex;
          align-items: center;
          img {
            width: 2.4vw;
            height: 2.2vw;
          }
          h3 {
            padding: 0 0 0 1.6vw;
            font-size: 2.2vw;
            font-family: $inter_medium;
            display: flex;
            align-items: center;
            color: $primary_white;
          }
          h6 {
            font-size: 1.6vw;
            padding: 0.3vw 0 0 0.4vw;
            font-weight: 400;
            color: $primary_white;
          }
        }
        p {
          padding: 2vw 0 0 0;
          font-size: 1.2vw;
          line-height: 1.6vw;
          font-weight: 400;
          color: $primary_white;
          line-break: anywhere;
        }
      }
      &__button {
        @include flexrow;
        padding: 1.8vw 0 0 0;
        button {
          min-width: 5.6vw;
          height: 2.2vw;
          font-size: 0.9vw;
          padding: 0 0.6vw;
          background: $primary_blue;
          border: 2px solid $primary_grey;
          border-radius: 2vw;
          color: $primary_white;
          cursor: pointer;
        }
      }
    }
  }
}

.features:nth-child(2n) {
  .main-feat {
    flex-direction: row-reverse;
  }
}

.features:last-child {
  border-bottom: none;
  h3 {
    font-size: 1.6vw;
    font-weight: 400;
    display: flex;
    align-items: center;
  }
  h6 {
    font-size: 2.2vw;
    font-family: $inter_semibold;
    font-weight: 600;
    padding: 0 0 0.3vw 0.4vw;
  }
}

@media screen and (min-width: 600px) and (max-width: 1090px) {
  .features {
    padding: 18px 0;
    margin: 0 0 24px 0;
    border-radius: 12px;
    &__container {
      flex-direction: column-reverse;
      height: 100%;
      &__left {
        width: 100%;
        padding: 12px 0 0 0;
        &__image {
          width: 100%;
          video {
            height: -webkit-fill-available;
          }
        }
      }
      &__right {
        width: 100%;
        &__content {
          width: 100%;
          padding: 0;
          &__heading {
            img {
              width: 24px;
              height: 22px;
            }
            h3 {
              font-size: 22px;
            }
            h6 {
              font-size: 14px;
            }
          }
          p {
            font-size: 12px;
            padding: 12px 0 8px 0;
          }
        }
        &__button {
          justify-content: flex-start;
          button {
            min-width: 90px;
            padding: 0 12px;
            height: 32px;
            font-size: 12px;
          }
        }
      }
    }
  }
  .features:nth-child(2n) {
    .main-feat {
      flex-direction: column-reverse;
    }
  }

  .features:last-child {
    h6 {
      font-size: 22px;
    }
    h3 {
      font-size: 14px;
    }
  }
}
