@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.button {
  min-width: 80px;
  padding: 6px 12px;
  border-radius: 15px;
  border: none;
  background: $primary_black;
  color: $primary_white;
  cursor: pointer;
  font-size: 12px;
}
.button:hover {
  opacity: 0.7;
}
.button:active {
  opacity: 0.5;
}
.button__disabled {
  background: $grey_input;
  cursor: default;
}
.button__disabled:hover {
  opacity: 1;
}
.button__disabled:active {
  opacity: 1;
}
.button_link {
  background: none;
  color: $primary_black;
  border: none;
}
.button_link:hover {
  background: none;
  opacity: 0.5;
  cursor: pointer;
}
.button_link:active {
  background: none;
  opacity: 0.7;
}


@media screen and (min-width:768px) and (max-width:1090px) {
  .button {
    padding: 4px 8px;
    min-width: 72px;
    border-radius: 15px;
    font-size: 12px;
  }
  .button_link {
    min-width: 72px;
    border-radius: 15px;
    font-size: 12px;
  }
}

@media screen and (min-width:600px) and (max-width:767px) {
  .button {
    min-width: 8vw;
    padding: 4px 8px;
  }
}

@media screen and (min-width: 1090px) and (max-width:1250px) {
  .button {
    min-width: 64px;
    font-size: 12px;
    padding: 4px 8px;
  }
  .button_link {
    min-width: 64px;
    font-size: 12px;
    padding: 4px 8px;
  }
}