// @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import "./App/styles/mixins.scss";
@import "./App/styles/variables.scss";
@import "./App/styles/typography.scss";

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  font-family: $inter_regular;
}

button,
a,
span {
  font-family: $inter_regular;
  font-size: 14px;
}

textarea {
  font-family: $inter_medium;
  font-size: 12px;
}

body {
  font-family: $inter_regular;
  height: calc(99vh - 5vw);
  margin: 0 0 0 0;
  background: #fbfbfb;
  user-select: none;
  touch-action: none;
}

.app, html, body {
  overscroll-behavior: none;
}

body.popup-open {
  overflow-y: hidden;
  overflow-x: auto;
  margin: 0 5px 0 0;
}

::-webkit-scrollbar {
  width: 6px !important;
  height: 0px !important;
}
::-webkit-scrollbar-thumb {
  background: $secondary_grey;
  border-radius: 0.3vw;
}
::-webkit-scrollbar-thumb:hover {
  background: #A8ADAF;
}
::-webkit-scrollbar-track {
  border-radius: 0.5vw;
}

@media screen and (min-width: 768px) and (max-width: 1090px) {
  span {
    font-size: 12px;
  }
}

@media screen and (min-width: 600px) {
  .app {
    &__router {
      display: block;
    }
    &__coming {
      display: none;
    }
  }
}

@media screen and (max-width: 600px) {
  .app {
    &__router {
      display: none;
    }
    &__coming {
      display: block;
      overflow: hidden;
    }
  }
}
