@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.acccount-tooltip {
  position: absolute;
//   width: 200px;
  bottom: 20px;
//   left: -86px;
  @include flexColumnACenter;
  //   box-shadow: 0px 4px 4px 0px #00000025;
  &__container {
    // width: 100%;
    background: $primary_white;
    border-radius: 4px;
    padding: 12px;
    z-index: 1000;
    box-shadow: 0px 8px 4px 0px #00000025, 0px -1px 4px 0px #00000025;
    box-sizing: border-box;
    ul {
        padding: 0 ;
        margin: 0;
      li {
        font-size: 14px;
        list-style-type: none;
        text-align: center;
      }
    }
  }
  &__section {
    width: 100%;
    @include flexCenter;
  }
  &__arrow {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 5px solid $primary_white;
    transform: rotate(180deg);
    // box-shadow: 0px -4px 4px 0px #00000025;
    z-index: 10001;
  }
}
