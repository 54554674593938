@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.fav-tooltip {
  position: absolute;
  width: 200px;
  bottom: 32px;
  left: -84px;
  @include flexColumnACenter;
  //   box-shadow: 0px 4px 4px 0px #00000025;
  &__container {
    // width: 100%;
    background: $primary_white;
    border-radius: 4px;
    padding: 6px 8px;
    z-index: 1000;
    box-shadow: 0px 8px 4px 0px #00000025, 0px -1px 4px 0px #00000025;
    box-sizing: border-box;
    ul {
      width: 100%;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      list-style-position: inside;
      li {
        font-size: 14px;
        list-style-type: none;
        //padding: 0 0 0 4px;
        width: fit-content;
        span {
          font-size: 14px;
        }
      }
    }
  }
  &__section {
    width: 100%;
    @include flexCenter;
  }
  &__arrow {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 5px solid $primary_white;
    transform: rotate(180deg);
    // box-shadow: 0px -4px 4px 0px #00000025;
    z-index: 10001;
  }
}

@media screen and (min-width: 600px) and (max-width: 1090px) {
  .fav-tooltip {
    &__container {
      ul {
        li {
          span {
            font-size: 10px;
          }
        }
      }
    }
  }
}
