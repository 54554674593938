@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.global-error-popup {
  background: rgb(0 0 0 / 54%);
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &__container {
    width: 600px;
  }
  &__card {
    width: calc(100% - 64px);
    background: white;
    padding: 16px 32px;
    border: 1px solid $primary_grey;
    box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.342);
    border-radius: 8px;
    &__heading {
      h2 {
        font-size: 16px;
        font-weight: 600;
      }
    }
    &__content {
      padding: 32px 0;
      p {
        font-size: 14px;
        font-weight: 500;
        color: $primary_blue;
      }
    }
    &__qr-text {
      p {
        font-size: 14px;
        font-weight: 500;
        color: $primary_blue;
      }
    }
    &__qr-code {
      padding: 12px 0 8px 0;
      @include flexColumnACenter;
      img {
        width: 110px;
      }
      p {
        font-size: 14px;
        font-weight: 500;
        color: $primary_blue;
        padding: 4px 0 0 0;
      }
    }
    &__cleancachebutton {
      padding: 12px 0 8px 0;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      button {
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
        border-radius: 15px;
        outline: none;
        border: none;
        color: $primary_white;
        background: $primary_black;
        padding: 4px 8px;
        height: 28px;
        width: 128px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px 8px;
      }
    }
  }
}

@media screen and (max-width:1090px) {
  .global-error-popup {
    &__container {
      max-height: calc(100vh - 24px);
      overflow: hidden;
    }
    &__card {
      overflow: auto;
      max-height: calc(100vh - 48px);
    }
  }
}