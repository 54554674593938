@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.first-frame {
  background: #0000008a;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &__container {
    width: 1008px;
    height: max-content;
    background: $primary_white;
    border-radius: 12px;
  }
  &__sections {
    @include flexColumnACenter;
    padding: 16px;
  }
  &__crop {
    width: 100%;
    @include flexcolumn;
    gap: 24px;
    &__container {
      @include flexrow;
      gap: 24px;
      position: relative;
      overflow: hidden;
      &__load {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
        @include flexCenter;
        img {
          width: 100%;
          max-height: 60vh;
          object-fit: cover;
        }
        .anim {
          position: absolute;
          width: 100px;
        }
      }
    }
    &__section {
      width: 100%;
      @include flexJCenter;
    }
    &__buttons {
      @include flexcolumn;
      gap: 12px;
      &__top {
        @include flexrow;
        justify-content: flex-end;
        &__main {
          @include flexrow;
          gap: 8px;
          padding: 4px;
          border-radius: 34px;
          border: 1px solid $primary_grey;
          background: $bg_grey_backround;
          button {
            color: $primary_blue;
            background: $primary_white;
            border: 1px solid $primary_grey;
            padding: 11px 7px;
            border-radius: 30px;
            font-size: 14px;
            font-weight: 500;
            @include flexCenter;
            cursor: pointer;
            span {
              display: inline-block;
              width: 16px;
              height: 8px;
              border: 1.5px solid $primary_white;
              margin-right: 8px;
              box-sizing: border-box;
              border: 1.5px solid $primary_blue;
            }
            .span1 {
              height: 16px;
              width: 8px;
            }
            &:hover {
              color: $primary_blue;
              background: $secondary_grey;
              border: 1px solid $primary_grey;
            }
            &.active {
              background: $primary_blue;
              color: $primary_white;
              padding: 12px 8px;
              span {
                border: 1.5px solid $primary_white;
              }
            }
          }
        }
      }
      &__bottom {
        @include flexrow;
        justify-content: flex-end;
        gap: 8px;
        border-top: 1px solid $secondary_grey;
        padding: 12px 0 0 0;
        button {
          background: $primary_blue;
          color: $primary_white;
          width: 80px;
          height: 27px;
          border-radius: 30px;
          font-size: 14px;
          font-weight: 500;
          border: none;
          @include flexCenter;
          cursor: pointer;
          &:not(:disabled):hover {
            opacity: 0.7;
          }
          &:disabled {
            background: $secondary_grey;
            cursor: not-allowed;
          }
        }
        .cancel {
          color: $grey_input;
          background: $bg_grey_backround;
          border: 1px solid $primary_grey;
        }
      }
    }
  }
}

@media screen and (min-width: 1090px) and (max-width: 1250px) {
  .first-frame {
    &__container {
      width: 700px;
    }
    &__crop {
      gap: 16px;
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 1090px) {
  .first-frame {
    &__container {
      width: 560px;
    }
    &__sections {
      padding: 12px;
    }
    &__crop {
      gap: 12px;
      &__buttons {
        gap: 6px;
        &__top {
          gap: 6px;
          button {
            font-size: 12px;
            padding: 7px 5px;
            span {
              width: 12px;
              height: 6px;
            }
            .span1 {
              height: 12px;
              width: 6px;
            }
            &.active {
              padding: 8px 6px;
            }
          }
        }
        &__bottom {
          gap: 6px;
          padding: 6px 0 0 0;
          button {
            width: 72px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
