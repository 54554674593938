@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.admin-login {
  margin: 90px 0 0 0;
  position: relative;
  &__container {
    width: 100vw;
    @include flexJCenter;
  }
  &__sections {
    width: calc(445px - 40px);
    background: #fbfbfb;
    padding: 20px;
  }
  &__heading {
    padding: 0 0 40px 0;
    h1 {
      font-size: 36px;
      font-weight: 600;
      text-align: center;
    }
  }
  &__section {
    @include flexColumnACenter;
    &__main {
      width: 100%;
    }
    &__tabs {
      &__details {
        &__container {
          display: flex;
          flex-direction: column;
          padding: 12px 0;
          p {
            width: 100%;
            text-align: center;
            font-size: 14px;
            padding: 12px 0;
          }
          span {
            font-size: 14px;
            color: $grey_input;
            font-weight: 400;
            text-align: center;
            a {
              font-size: 14px;
              color: $primary_black;
              font-weight: 600;
            }
          }
        }
        &__phone {
          .error-p {
            p {
              text-align: start;
              font-size: 14px;
            }
          }
          &__input {
            padding: 8px 0;
            &__inputs {
              @include flexrow;
              .react-tel-input {
                width: 120px;
                .form-control {
                  padding: 0 0 0 44px !important;
                }
                .flag-dropdown {
                  border-radius: 20px 0 0 20px !important;
                  border: 1px solid $primary_grey !important;
                  background: $primary_white !important;
                }
                .flag-dropdown.open .selected-flag {
                  border-radius: 20px 0 0 20px !important;
                }
                .selected-flag:hover,
                :focus {
                  border-radius: 20px 0 0 20px !important;
                }
                input {
                  width: 100%;
                  border-radius: 60px 0 0 60px !important;
                  border: 1px solid $primary_grey !important;
                  font-size: 14px;
                  color: $grey_input;
                  height: 100%;
                }
              }
              input {
                width: 100%;
                padding: 0 0 0 12px;
                border-radius: 0 $font_xxlarge $font_xxlarge 0;
                border: 1px solid $primary_grey;
                border-left: none;
                font-size: 14px;
                color: $primary_black;
                outline: none;
                height: 40px;
              }
              input::placeholder {
                color: $grey_input;
              }
            }
          }
          &__otp {
            &__content {
              padding: 12px;
              p {
                text-align: start;
                font-size: 16px;
                color: $primary_blue;
              }
            }
            &__section {
              @include flexbetween;
              &__inputs {
                div {
                  width: 260px;
                  @include flexbetween;
                  align-items: center;
                  input {
                    width: 38px !important;
                    height: 38px !important;
                    border-radius: 50%;
                    border: 1.3px solid $primary_grey;
                    font-size: 14px;
                    outline: none;
                    color: $primary_black;
                  }
                }
              }
              &__button {
                button {
                  width: 116px;
                  height: 36px;
                  font-size: 12px;
                  cursor: pointer;
                  border: 2px solid $primary_grey;
                  border-radius: 19px;
                  background: $primary_blue;
                  color: $primary_white;
                }
                .disabled {
                  border: 1px solid $primary_grey;
                  background: $grey_input;
                }
              }
            }
          }
          &__agree {
            padding: 12px 0;
            @include flexrow;
            align-items: baseline;
            input {
              width: 14px;
              height: 14px;
            }
            input[type="checkbox"] {
              accent-color: black;
            }
            p {
              text-align: start;
              font-size: 14px;
              color: $grey_input;
              font-weight: 400;
              word-spacing: 2px;
              padding: 0 0 0 10px;
              a {
                color: $primary_black;
                cursor: pointer;
              }
              a:hover {
                text-decoration: underline;
              }
            }
          }
          &__button {
            padding: 32px 0 0 0;
            button {
              width: 100%;
              height: 48px;
              font-size: 18px;
              color: $primary_white;
              background: $primary_blue;
              border-radius: 24px;
              border: none;
              cursor: pointer;
            }
            .disabled {
              border: 1px solid $primary_grey;
              background: $grey_input;
              cursor: default;
            }
          }
        }
        &__top {
          p {
            text-align: start;
            font-size: 14px;
            color: $font_grey;
          }
        }
        &__text {
          padding: 20px 0 0 0;
          span {
            font-size: 14px;
            color: $font_grey;
          }
        }
      }
    }
    .react-tabs {
      @include flexColumnACenter;
      &__tab-list {
        width: 290px;
        border: 1px solid $primary_grey !important;
        margin: 0 !important;
        padding: 4px !important;
        border-radius: 23px;
        display: flex !important;
        justify-content: space-between !important;
      }
      &__tab:focus:after {
        display: none !important;
      }
      &__tab {
        bottom: 0;
        font-size: 18px;
      }
      &__tab-panel {
        width: 100%;
      }
      &__tab--selected {
        border: none !important;
        background: $primary_blue !important;
        border-radius: 20px;
        color: $primary_white !important;
      }
    }
  }
  &__loading {
    position: fixed; 
    top: 0;           
    left: 0;   
    width: 100%;
    height: calc(100vh);
    @include flexCenter;
    &__container {
      position: relative;
      width: 100%;  
      height: 100%; 
      text-align: center; 
      img {
        position: absolute;
        top: 50%;  
        left: 50%; 
        transform: translate(-50%, -50%); 
        width: 100px;
      }
      p {
        position: absolute;
        top: calc(50% + 40px); 
        width: 100%;  
        text-align: center; 
        font-size: 14px;
        font-family: $inter_semibold;
        color: $primary_blue;
      }
    }
  }
}

iframe {
  border: none !important;
}

.impowerBox .info {
  display: none !important;
}

.error-popup {
  width: 100%;
  height: calc(100vh - 90px);
  @include flexCenter;
  position: absolute;
  top: 0;
  &__container {
    width: 40%;
    img {
      width: 100%;
    }
  }
}
