@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.scanner-tooltip {
  position: absolute;
  width: 146px;
  @include flexColumnACenter;
  &__container {
    background: $primary_white;
    border-radius: 8px;
    padding: 12px;
    z-index: 1000;
    border: 1px solid #ededed;
    box-shadow: 0px 8px 4px 0px #00000025, 0px -1px 4px 0px #00000025;
    box-sizing: border-box;
    p {
      width: 120px;
      font-size: 14px;
      text-align: center;
      padding: 0 0 10px 0;
    }
    img {
      width: 122px;
      height: 122px;
    }
  }
  &__section {
    width: 100%;
    @include flexCenter;
  }
  &__arrow {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 5px solid $primary_white;
    transform: rotate(180deg);
    z-index: 10001;
  }
}

@media screen and (min-width: 600px) and (max-width: 1090px) {
  .scanner-tooltip {
    &__container {
      p {
        font-size: 10px;
      }
    }
  }
}
