@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.question-popover1 {
  width: 140px;
  text-align: center;
}

.team-data-details {
  height: 100%;
  &__container {
    height: 100%;
    overflow: auto;
  }
  &__heading {
    padding: 0 12px 20px 12px;
    @include flexbetween;
    h2 {
      font-size: 18px;
      font-family: $inter_semibold;
    }
  }
  &__top {
    @include flexJCenter;
    margin: 24px 0 0 0;
    border: 1px solid $primary_grey;
    border-radius: 6px;
    background: $bg_grey_backround;
    &__section {
      padding: 20px 12px;
      border: 1px solid $primary_grey;
      border-radius: 8px;
      background: $bg_grey_backround;
      &__enquiry {
        @include flexAcenter;
        &__date {
          width: 110px;
          &__left {
            h5 {
              font-size: 14px;
              font-weight: 400;
              color: $font_grey;
            }
          }
        }
        &__options {
          padding: 0 0 0 15px;
          @include flexAcenter;
          &__date {
            padding: 0 12px 0 0;
            width: 340px;
          }
          &__sec {
            padding: 0 12px 0 0;
            h6 {
              cursor: pointer;
              font-size: 14px;
              font-weight: 400;
              color: $grey_input;
            }
            .active {
              color: $primary_blue;
            }
            &:last-child {
              padding: 0;
            }
          }
        }
      }
      &__buttons {
        @include flexJEnd;
        &__main {
          width: max-content;
          @include flexrow;
          button {
            cursor: pointer;
            width: 65px;
            color: $primary_white;
            @include flexJCenter;
            font-size: 14px;
            padding: 6px 0;
            border-radius: 16px;
            background: $primary_blue;
            border: none;
          }
          .cancel {
            background: $grey_input;
            margin: 0 6px 0 0;
          }
        }
      }
    }
  }
  &__center {
    @include flexcolumn;
    justify-content: space-between;
    margin: 24px 0 0 0;
    border: 1px solid $primary_grey;
    border-radius: 6px;
    &__heading {
      padding: 0 20px;
      border-bottom: 1px solid $primary_grey;
      &__left {
        height: 53px;
        @include flexAcenter;
        h3 {
          font-size: 18px;
          font-weight: 600;
          color: $primary_blue;
        }
      }
    }
    &__details {
      padding: 0 20px;
      @include flexrow;
      justify-content: space-between;
      &__sec {
        // width: calc(20% - 9.6px);
        margin: 14px 12px 6px 0;
        &__heading {
          padding: 6px 0;
          @include flexrow;
          h5 {
            font-size: 14px;
            font-weight: 400;
          }
          img {
            width: 14px;
            height: 14px;
            padding: 4px 0 0 6px;
          }
        }
        &__content {
          padding: 6px 0;
          @include flexrow;
          &__left {
            h6 {
              span {
                font-size: 24px;
              }
              font-size: 14px;
              font-weight: 400;
              color: $primary_blue;
            }
          }
          &__right {
            @include flexrow;
            align-items: flex-end;
            padding: 0 0 6px 8px;
            cursor: pointer;
            h6 {
              font-size: 12px;
              font-weight: 400;
              color: $primary_blue;
            }
            img {
              width: 16px;
              height: 16px;
              transform: rotate(0deg);
            }
            .active {
              transform: rotate(90deg);
            }
          }
        }
      }
      &__sec:last-child {
        margin: 14px 0 6px 0;
      }
      &__sub {
        margin: 12px 20px;
        border: 1px solid $primary_grey;
        border-radius: 8px;
        &__header {
          background: $bg_grey_backround;
          padding: 12px 20px;
          ul {
            padding: 0;
            margin: 0;
            align-items: center;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 12px;
            li {
              list-style-type: none;
              h6 {
                font-size: 14px;
                font-weight: 400;
                color: $font_grey;
              }
            }
          }
        }
        &__data {
          padding: 6px 20px 12px 20px;
          ul {
            padding: 0;
            margin: 0;
            align-items: center;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 12px;
            li {
              list-style-type: none;
              h6 {
                font-size: 24px;
                font-weight: 400;
                span {
                  padding: 0 0 0 4px;
                  font-size: 14px;
                  font-weight: 400;
                }
              }
            }
          }
        }
        &__header1 {
          background: $bg_grey_backround;
          padding: 12px 20px;
          ul {
            padding: 0;
            margin: 0;
            align-items: center;
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: 12px;
            li {
              list-style-type: none;
              h6 {
                font-size: 14px;
                font-weight: 400;
                color: $font_grey;
              }
            }
          }
        }
        &__data1 {
          padding: 6px 20px 12px 20px;
          ul {
            padding: 0;
            margin: 0;
            align-items: center;
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: 12px;
            li {
              list-style-type: none;
              h6 {
                font-size: 24px;
                font-weight: 400;
                span {
                  padding: 0 0 0 4px;
                  font-size: 14px;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
      &__nodata {
        @include flexJCenter;
        img {
          width: 120px;
        }
        p {
          font-size: 12px;
        }
      }
    }
  }
  &__bottom {
    @include flexcolumn;
    justify-content: space-between;
    margin: 24px 0 20px 0;
    border: 1px solid $primary_grey;
    border-radius: 6px;
    overflow: hidden;
    &__heading {
      padding: 0 20px;
      border-bottom: 1px solid $primary_grey;
      @include flexbetween;
      align-items: center;
      &__left {
        height: 53px;
        @include flexAcenter;
        h3 {
          font-size: 18px;
          font-weight: 600;
          color: $primary_blue;
        }
      }
      &__right {
        button {
          width: 114px;
          color: $primary_white;
          @include flexJCenter;
          font-size: 14px;
          padding: 6px 0;
          border-radius: 16px;
          background: $primary_blue;
          border: none;
          cursor: pointer;
        }
      }
    }
    &__table {
      ::-webkit-scrollbar {
        width: 6px !important;
        height: 6px !important;
      }
      &__container {
        overflow: auto;
        padding: 0 0 12px 0;
      }
      &__header {
        ul {
          display: flex;
          padding: 0;
          margin: 0;
          li {
            border-bottom: 1px solid $primary_grey;
            display: flex;
            align-items: center;
            height: 46px;
            background: $bg_grey_backround;
            list-style-type: none;
            padding: 0 12px 0 0;
            h6 {
              font-size: 14px;
              font-weight: 400;
            }
          }
          li:first-child {
            padding: 0 12px 0 20px;
          }
          li:last-child {
            padding: 0 20px 0 0;
          }
          .first {
            h6 {
              width: 106px;
            }
          }
          .second {
            h6 {
              width: 120px;
            }
          }
          .third {
            h6 {
              width: 142px;
            }
          }
          .forth {
            h6 {
              width: 160px;
            }
          }
          .fifth {
            h6 {
              width: 324px;
            }
          }
          .sixth {
            h6 {
              width: 444px;
            }
          }
        }
      }
      &__data {
        max-height: 15vh;
        ul {
          display: flex;
          padding: 0;
          margin: 0;
          li {
            display: flex;
            align-items: center;
            height: 46px;
            list-style-type: none;
            padding: 0 12px 0 0;
            border-bottom: 1px solid $primary_grey;
            h6 {
              font-size: 14px;
              font-weight: 400;
              color: $font_grey;
            }
          }
          li:first-child {
            padding: 0 12px 0 20px;
          }
          li:last-child {
            padding: 0 20px 0 0;
          }
          .first {
            h6 {
              width: 106px;
            }
          }
          .second {
            h6 {
              width: 120px;
              max-width: 120px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .third {
            h6 {
              width: 142px;
            }
          }
          .forth {
            h6 {
              width: 160px;
            }
          }
          .fifth {
            h6 {
              width: 324px;
            }
          }
          .sixth {
            h6 {
              width: 444px;
            }
          }
        }
        &__nodata {
          @include flexJCenter;
          img {
            width: 120px;
          }
          p {
            font-size: 12px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1250px) and (max-width: 1610px) {
  .ant-picker-input {
    input {
      font-size: 10px !important;
    }
  }
  .team-data-details {
    &__top {
      &__section {
        padding: 12px 8px;
        &__enquiry {
          &__date {
            width: 88px;
            &__left {
              h5 {
                font-size: 12px;
              }
            }
          }
          &__options {
            padding: 0 0 0 10px;
            &__date {
              padding: 0 8px 0 0;
              width: 226px;
            }
            &__sec {
              padding: 0 8px 0 0;
              h6 {
                font-size: 12px;
              }
            }
          }
        }
        &__buttons {
          &__main {
            button {
              width: 54px;
              font-size: 12px;
            }
          }
        }
      }
    }
    &__center {
      margin: 16px 0 0 0;
      &__heading {
        padding: 0 12px;
        &__left {
          height: 42px;
          h3 {
            font-size: 16px;
          }
        }
      }
      &__details {
        padding: 0 12px;
        &__sec {
          margin: 8px 8px 4px 0;
          &__content {
            &__left {
              h6 {
                font-size: 12px;
                span {
                  font-size: 20px;
                }
              }
            }
          }
        }
      }
    }
    &__bottom {
      &__heading {
        padding: 0 12px;
        &__left {
          h3 {
            font-size: 16px;
          }
        }
        &__right {
          button {
            width: 102px;
            font-size: 12px;
          }
        }
      }
      &__table {
        &__header {
          ul {
            .first {
              width: 94px;
            }
            .second {
              width: 100px;
              max-width: 100px;
            }
            .third {
              width: 122px;
            }
            .forth {
              width: 130px;
            }
            .fifth {
              width: 260px;
            }
            .sixth {
              width: 300px;
            }
            li {
              height: 36px;
              h6 {
                font-size: 12px;
              }
            }
            li:first-child {
              padding: 0 8px 0 12px;
            }
          }
        }
        &__data {
          ul {
            .first {
              width: 94px;
            }
            .second {
              width: 100px;
              max-width: 100px;
            }
            .third {
              width: 122px;
            }
            .forth {
              width: 130px;
            }
            .fifth {
              width: 260px;
            }
            .sixth {
              width: 300px;
            }
            li {
              height: 36px;
              h6 {
                font-size: 12px;
              }
            }
            li:first-child {
              padding: 0 8px 0 12px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1090px) and (max-width: 1250px) {
  .ant-picker-input {
    input {
      font-size: 10px !important;
    }
  }
  .team-data-details {
    &__top {
      &__section {
        padding: 12px 8px;
        &__enquiry {
          &__date {
            width: 88px;
            &__left {
              h5 {
                font-size: 12px;
              }
            }
          }
          &__options {
            padding: 0 0 0 10px;
            &__date {
              padding: 0 8px 0 0;
              width: 226px;
            }
            &__sec {
              padding: 0 8px 0 0;
              h6 {
                font-size: 12px;
              }
            }
          }
        }
        &__buttons {
          &__main {
            button {
              width: 54px;
              font-size: 12px;
            }
          }
        }
      }
    }
    &__center {
      margin: 16px 0 0 0;
      &__heading {
        padding: 0 12px;
        &__left {
          height: 42px;
          h3 {
            font-size: 16px;
          }
        }
      }
      &__details {
        padding: 0 12px;
        &__sec {
          margin: 8px 8px 4px 0;
          &__content {
            &__left {
              h6 {
                font-size: 12px;
                span {
                  font-size: 20px;
                }
              }
            }
          }
        }
      }
    }
    &__bottom {
      &__heading {
        padding: 0 12px;
        &__left {
          h3 {
            font-size: 16px;
          }
        }
        &__right {
          button {
            width: 102px;
            font-size: 12px;
          }
        }
      }
      &__table {
        &__header {
          ul {
            .first {
              width: 94px;
            }
            .second {
              width: 100px;
              max-width: 100px;
            }
            .third {
              width: 122px;
            }
            .forth {
              width: 130px;
            }
            .fifth {
              width: 260px;
            }
            .sixth {
              width: 300px;
            }
            li {
              height: 36px;
              h6 {
                font-size: 12px;
              }
            }
            li:first-child {
              padding: 0 8px 0 12px;
            }
          }
        }
        &__data {
          ul {
            .first {
              width: 94px;
            }
            .second {
              width: 100px;
            }
            .third {
              width: 122px;
            }
            .forth {
              width: 130px;
            }
            .fifth {
              width: 260px;
            }
            .sixth {
              width: 300px;
            }
            li {
              height: 36px;
              h6 {
                font-size: 12px;
              }
            }
            li:first-child {
              padding: 0 8px 0 12px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1090px) {
  .ant-picker-input {
    input {
      font-size: 10px !important;
    }
  }
  .team-data-details {
    &__top {
      &__section {
        padding: 12px 8px;
        &__enquiry {
          &__date {
            width: 88px;
            &__left {
              h5 {
                font-size: 12px;
              }
            }
          }
          &__options {
            padding: 0 0 0 10px;
            &__date {
              padding: 0 8px 0 0;
              width: 226px;
            }
            &__sec {
              padding: 0 8px 0 0;
              h6 {
                font-size: 12px;
              }
            }
          }
        }
        &__buttons {
          &__main {
            button {
              width: 54px;
              font-size: 12px;
            }
          }
        }
      }
    }
    &__center {
      margin: 16px 0 0 0;
      &__heading {
        padding: 0 12px;
        &__left {
          height: 42px;
          h3 {
            font-size: 16px;
          }
        }
      }
      &__details {
        padding: 0 12px;
        &__sec {
          margin: 8px 8px 4px 0;
          &__content {
            &__left {
              h6 {
                font-size: 12px;
                span {
                  font-size: 20px;
                }
              }
            }
          }
        }
      }
    }
    &__bottom {
      &__heading {
        padding: 0 12px;
        &__left {
          h3 {
            font-size: 16px;
          }
        }
        &__right {
          button {
            width: 102px;
            font-size: 12px;
          }
        }
      }
      &__table {
        &__header {
          ul {
            .first {
              width: 94px;
            }
            .second {
              width: 100px;
            }
            .third {
              width: 122px;
            }
            .forth {
              width: 130px;
            }
            .fifth {
              width: 260px;
            }
            .sixth {
              width: 300px;
            }
            li {
              height: 36px;
              h6 {
                font-size: 12px;
              }
            }
            li:first-child {
              padding: 0 8px 0 12px;
            }
          }
        }
        &__data {
          ul {
            .first {
              width: 94px;
            }
            .second {
              width: 100px;
            }
            .third {
              width: 122px;
            }
            .forth {
              width: 130px;
            }
            .fifth {
              width: 260px;
            }
            .sixth {
              width: 300px;
            }
            li {
              height: 36px;
              h6 {
                font-size: 12px;
              }
            }
            li:first-child {
              padding: 0 8px 0 12px;
            }
          }
        }
      }
    }
  }
}
