@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";
@import "../../styles/typography.scss";

.tutorial-video {
  width: 100%;
  height: calc(100vh - 48px);
  @include flexJCenter;
  &__container {
    width: 1474px;
    margin: 12px 0 0 0;
    &__sections {
      width: 100%;
      height: 100%;
      @include flexbetween;
      align-items: center;
    }
    &__video {
      width: 1040px;
      height: 100%;
      @include flexrowacenter;
      &__loader {
        width: 100%;
        height: calc(80% - 12px);
        @include flexCenter;
        img {
          width: 120px;
        }
      }
      &__main {
        width: 100%;
        height: 100%;
        flex-direction: column;
        @include flexCenter;
        iframe {
          width: 100%;
          height: calc(75% - 12px);
          border-radius: 12px;
        }
      }
      &__content {
        width: 100%;
        @include flexrow;
        align-items: flex-end;
        padding: 12px 0 0 0;
        h2 {
          font-size: 22px;
          font-family: $inter_semibold;
        }
        h4 {
          font-size: 18px;
          font-weight: 400;
          padding: 0 0 0 8px;
        }
      }
    }
    &__data {
      width: 408px;
      height: 100%;
      overflow: hidden;
      @include flexJCenter;
      ::-webkit-scrollbar {
        width: 0px !important;
        height: 0px !important;
      }
      &__videos {
        overflow: auto;
        width: calc(100% - 48px);
        margin: 24px 0 0 0;
        &__section:last-child {
          border-bottom: none;
        }
        &__section {
          width: 100%;
          border-bottom: 1px solid $secondary_grey;
          padding: 24px 0;
          &__heading {
            @include flexrowacenter;
            justify-content: space-between;
            min-height: 48px;
            margin: 0 0 16px 0;
            cursor: pointer;
            &__content {
              @include flexrow;
              h2 {
                font-size: 16px;
                font-family: $inter_medium;
              }
            }
            &__icon {
              width: 40px;
              @include flexCenter;
              img {
                width: 16px;
                height: 16px;
              }
            }
            .active {
              img {
                transform: rotate(180deg);
              }
            }
          }
          &__list {
            &__sec {
              @include flexrowacenter;
              justify-content: space-between;
              background: $primary_white;
              min-height: 30px;
              border: 1px solid $primary_grey;
              padding: 8px 12px;
              border-radius: 8px;
              margin: 0 0 8px 0;
              cursor: pointer;
              &__left {
                width: calc(100% - 96px);
                @include flexrow;
                h2 {
                  font-size: 14px;
                  font-weight: 400;
                  width: max-content;
                }
              }
              &__right {
                width: 96px;
                @include flexrowacenter;
                justify-content: flex-end;
                h4 {
                  width: 64px;
                  font-size: 14px;
                  font-weight: 400;
                  text-align: end;
                  padding: 0 8px 0 0;
                }
                img {
                  width: 16px;
                  height: 16px;
                }
                .play-d {
                  display: block;
                }
                .play-h {
                  display: none;
                }
                .play-a {
                  display: none;
                }
              }
            }
            &__sec:hover {
              background: $bg_grey_backround;
            }
            &__sec.active {
              background: $primary_blue;
              .left {
                h2 {
                  color: $primary_white;
                }
              }
              .right {
                h4 {
                  color: $primary_white;
                }
                .play-d {
                  display: none;
                }
                .play-h {
                  display: none;
                }
                .play-a {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 767px) {
  .tutorial-video {
    min-height: calc(100% - 36px);
    &__container {
      width: 90%;
      margin: 12px 0 0 0;
      &__video {
        width: 60%;
        &__main {
          video {
            height: calc(80% - 10px);
            border-radius: 8px;
          }
        }
        &__content {
          h2 {
            font-size: 16px;
          }
          h4 {
            font-size: 12px;
          }
        }
      }
      &__data {
        width: 37%;
        &__videos {
          width: calc(100% - 24px);
          margin: 12px 0 0 0;
          &__section {
            padding: 12px 0;
            &__heading {
              min-height: 36px;
              &__content {
                h2 {
                  font-size: 14px;
                }
              }
              &__icon {
                width: 32px;
                img {
                  width: 14px;
                  height: 14px;
                }
              }
            }
            &__list {
              &__sec {
                padding: 6px 10px;
                margin: 0 0 6px 0;
                border-radius: 6px;
                min-height: 26px;
                &__left {
                  width: calc(100% - 60px);
                  h2 {
                    font-size: 12px;
                  }
                }
                &__right {
                  width: 60px;
                  img {
                    width: 14px;
                    height: 14px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1090px) {
  .tutorial-video {
    min-height: calc(100% - 36px);
    &__container {
      width: 90%;
      margin: 12px 0 0 0;
      &__video {
        width: 60%;
        &__main {
          video {
            height: calc(75% - 10px);
            border-radius: 8px;
          }
        }
        &__content {
          h2 {
            font-size: 16px;
          }
          h4 {
            font-size: 12px;
          }
        }
      }
      &__data {
        width: 37%;
        &__videos {
          width: calc(100% - 24px);
          margin: 12px 0 0 0;
          &__section {
            padding: 12px 0;
            &__heading {
              min-height: 36px;
              &__content {
                h2 {
                  font-size: 14px;
                }
              }
              &__icon {
                width: 32px;
                img {
                  width: 14px;
                  height: 14px;
                }
              }
            }
            &__list {
              &__sec {
                padding: 6px 10px;
                margin: 0 0 6px 0;
                border-radius: 6px;
                min-height: 26px;
                &__left {
                  width: calc(100% - 60px);
                  h2 {
                    font-size: 12px;
                  }
                }
                &__right {
                  width: 60px;
                  img {
                    width: 14px;
                    height: 14px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
