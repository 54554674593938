@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.tooltipb {
  position: absolute;
  width: 200px;
  bottom: 20px;
  left: -86px;
  @include flexColumnACenter;
  //   box-shadow: 0px 4px 4px 0px #00000025;
  &__container {
    // width: 100%;
    background: $primary_white;
    border-radius: 4px;
    padding: 6px 8px;
    z-index: 1000;
    box-shadow: 0px 8px 4px 0px #00000025, 0px -1px 4px 0px #00000025;
    box-sizing: border-box;
    p {
      font-size: 14px;
    }
  }
  &__section {
    width: 100%;
    @include flexCenter;
  }
  &__arrow {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 5px solid $primary_white;
    transform: rotate(180deg);
    // box-shadow: 0px -4px 4px 0px #00000025;
    z-index: 10001;
  }
}
.tooltipbn {
  position: absolute;
  width: 120px;
  bottom: 30px;
  @include flexColumnACenter;
  &__container {
    h5 {
      font-size: 14px;
    }
  }
  &__arrow {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 5px solid $primary_white;
    transform: rotate(180deg);
    // box-shadow: 0px -4px 4px 0px #00000025;
    z-index: 10001;
    margin: 0 0 0 10px;
  }
}

.tooltipm {
  position: absolute;
  width: 140px;
  bottom: 20px;
  left: 26px;
  @include flexColumnACenter;
  &__container {
    background: $primary_white;
    border-radius: 4px;
    padding: 6px 8px;
    z-index: 1000;
    box-shadow: 0px 8px 4px 0px #00000025, 0px -1px 4px 0px #00000025;
    box-sizing: border-box;
    h5 {
      font-size: 14px;
      font-weight: 400;
      padding: 0 0 4px 0;
    }
    p {
      font-size: 12px;
    }
  }
  &__arrow {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 5px solid $primary_white;
    transform: rotate(180deg);
    // box-shadow: 0px -4px 4px 0px #00000025;
    z-index: 10001;
    margin: 0 0 0 100px;
  }
}

.tooltipbi {
  position: absolute;
  width: max-content;
  bottom: 58px;
  p {
    font-size: 12px;
  }
}

@media screen and (min-width: 600px) and (max-width: 1090px) {
  .bot-tooltip {
    &__container {
      p {
        font-size: 10px;
      }
    }
  }
  .tooltipbn {
    &__container {
      h5 {
        font-size: 10px;
      }
    }
  }
  .tooltipm {
    bottom: 18px;
    left: -64px;
    &__container {
      h5 {
        font-size: 12px;
      }
      p {
        font-size: 10px;
      }
    }
  }
  .tooltipbi {
    p {
      font-size: 10px;
    }
  }
}
