@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.new-version {
  background: rgb(0 0 0 / 54%);
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__sections {
    min-width: 380px;
    max-width: 960px;
    max-height: 90vh;
    background: $primary_white;
    padding: 32px 20px;
    border-radius: 8px;
    @include flexColumnACenter;
    &__header {
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;
      padding: 0 0 16px 0;
      img {
        width: 16px;
        height: 16px;
      }
      h3 {
        font-size: 18px;
        font-weight: 600;
        padding: 0 0 0 10px;
      }
    }
    &__content {
      overflow: auto;
      p {
        font-size: 14px;
        color: $primary_blue;
        padding: 0 0 6px 0;
        ul {
          margin: 0;
        }
      }
    }
    &__buttons {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding-top: 24px;
      button {
        min-width: 80px;
        padding: 6px 12px;
        border-radius: 15px;
        border: none;
        background: $primary_black;
        color: $primary_white;
        cursor: pointer;
        font-size: 12px;
      }
      button:hover {
        opacity: 0.7;
      }
      button:active {
        opacity: 0.5;
      }
      button:disabled {
        background: $grey_input;
        cursor: not-allowed;
      }
    }
  }
}

@media screen and (max-width: 1090px) {
  .new-version {
    &__sections {
      padding: 16px 10px;
      min-width: 90%;
      max-width: 90%;
      max-height: 80vh;
      overflow: auto;
      &__header {
        padding: 0 0 8px 0;
        img {
          width: 14px;
          height: 14px;
        }
        h3 {
          font-size: 14px;
        }
      }
      &__content {
        p {
          font-size: 12px;
        }
      }
      &__buttons {
        padding: 12px 0 0 0;
      }
    }
  }
}
