@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.sub-limit {
  background: rgb(0 0 0 / 54%);
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__sections {
    width: 350px;
    background: $primary_white;
    padding: 16px;
    border-radius: 8px;
    @include flexColumnACenter;
    &__header {
      width: 100%;
      display: flex;
      position: relative;
      padding: 0 0 12px 0;
      img {
        width: 16px;
        height: 16px;
      }
      h3 {
        font-size: 14px;
        font-weight: 600;
        padding: 0 0 0 10px;
      }
    }
    &__content {
      p {
        font-size: 12px;
        color: $primary_blue;
        padding: 0 0 24px 0;
      }
    }
    &__buttons {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      button {
        width: 80px;
        padding: 6px 0;
        border-radius: 15px;
        border: none;
        background: $primary_black;
        color: $primary_white;
        cursor: pointer;
        font-size: 12px;
      }
      button:hover {
        opacity: 0.7;
      }
      button:active {
        opacity: 0.5;
      }
    }
  }
}
