@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.all-galleries {
  @include flexJCenter;
  background: $bg_light_grey;
  position: relative;
  &__container {
    @include flexJCenter;
    height: 100%;
  }
  &__sections {
    position: relative;
    width: 1272px;
  }
  &__header {
    width: 1272px;
    padding: 12px 0 0 0;
    @include flexJCenter;
    position: fixed;
    z-index: 10;
    &__tabs {
      @include flexrow;
    }
    &__tab {
      margin: 0 40px 0 0;
      cursor: pointer;
      padding: 8px;
      opacity: 0.7;
      h2 {
        font-size: 14px;
        height: 18px;
      }
      &:last-child {
        margin: 0;
      }
    }
    &__tab.active {
      opacity: 1;
      border-bottom: 1px solid $primary_blue;
    }
    &__tab:hover {
      opacity: 1;
      border-bottom: 1px solid #26323866;
    }
    &__tab.disable {
      opacity: 0.5;
      cursor: default;
      &:hover {
        opacity: 0.5;
        border-bottom: none;
      }
    }
  }
}

@media screen and (min-width: 1250px) and (max-width: 1609px) {
  .all-galleries {
    &__sections {
      width: 982px;
    }
    &__header {
      width: 982px;
    }
  }
}

@media screen and (min-width: 1090px) and (max-width: 1249px) {
  .all-galleries {
    &__sections {
      width: 760px;
    }
    &__header {
      width: 760px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1089px) {
  .all-galleries {
    &__sections {
      width: 650px;
    }
    &__header {
      width: 650px;
      &__tab {
        margin: 0 32px 0 0;
        cursor: pointer;
        padding: 6px;
        h2 {
          font-size: 12px;
          height: 16px;
        }
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 767px) {
  .all-galleries {
    &__sections {
      width: 88vw;
    }
    &__header {
      width: 88vw;
      &__tab {
        margin: 0 32px 0 0;
        cursor: pointer;
        padding: 6px;
        h2 {
          font-size: 12px;
          height: 16px;
        }
      }
    }
  }
}
