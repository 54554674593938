@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.footer {
  &__container {
    &__top {
      @include flexbetween;
    }
    &__left {
      // width: 26.3vw;
      width: 100%;
      @include flexbetween;
      align-items: flex-end;
      padding: 0 0 1.2vw 0;
      &__logo {
        img {
          width: 8vw;
          height: 1.8vw;
        }
      }
      &__contact {
        padding: 1.2vw 0 0 0;
        h5 {
          color: $primary_white;
          font-size: 0.7vw;
          font-weight: 400;
          padding: 0 0 0.2vw 0;
        }
        h5:last-child {
          padding: 0;
        }
      }
      &__social {
        width: 11vw;
        @include flexbetween;
        &__sec {
          position: relative;
          img {
            width: 1.2vw;
            height: 1.2vw;
          }
          .sec-tooltip {
            display: none;
          }
          .sec-tooltip.active {
            display: block;
          }
        }
      }
    }
    &__right {
      display: none;
      width: 24.5vw;
      // @include flexbetween;
      &__sec {
        &__header {
          padding: 0 0 1.2vw 0;
          h5 {
            font-size: 0.8vw;
            font-weight: 400;
            color: $primary_white;
          }
        }
        &__options {
          h4 {
            font-size: 1vw;
            font-weight: 400;
            color: $primary_white;
            padding: 0 0 0.6vw 0;
          }
        }
      }
    }
    &__bottom {
      border-top: 1px solid #d4d6d7;
      margin: 1.2vw 0 0 0;
      padding: 1.2vw 0 0 0;
      @include flexrow;
      h4 {
        font-size: 0.75vw;
        font-weight: 400;
        color: $primary_white;
        opacity: 0.7;
        padding: 0 1.2vw 0 0;
      }
      h5 {
        font-size: 0.75vw;
        font-weight: 400;
        color: $primary_white;
        padding: 0 12px 0 0;
      }
      h5:last-child {
        padding: 0;
      }
    }
    &__bottom2 {
      @include flexrow;
      h4 {
        font-size: 0.75vw;
        font-weight: 400;
        color: $primary_white;
        padding: 0 1.2vw 0 0;
      }
      h5 {
        font-size: 0.75vw;
        font-weight: 400;
        color: $primary_white;
        padding: 0 12px 0 0;
      }
      a{
        font-size: 0.75vw;
        font-weight: 400;
        color: $primary_white;
        padding: 0 12px 0 0;
      }
      h5:last-child {
        padding: 0;
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 1090px) {
  .footer {
    padding: 0 24px;
    &__container {
      &__top {
        flex-direction: column;
      }
      &__left {
        width: 100%;
        &__logo {
          img {
            width: 100px;
            height: 24px;
          }
        }
        &__contact {
          padding: 12px 0;
          h5 {
            font-size: 12px;
          }
        }
        &__social {
          width: 160px;
          &__sec {
            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
      &__right {
        padding: 18px 0 0 0;
        &__sec {
          &__header {
            padding: 0 0 12px 0;
            h5 {
              font-size: 12px;
            }
          }
          &__options {
            h4 {
              font-size: 16px;
              padding: 0 0 8px 0;
            }
          }
        }
      }
      &__bottom {
        padding: 18px 0 0 0;
        margin: 18px 0 0 0;
        h4 {
          font-size: 10px;
        }
        h5 {
          font-size: 10px;
        }
      }
      &__bottom2 {
        padding: 18px 0 0 0;
        margin: 18px 0 0 0;
        h4 {
          font-size: 10px;
        }
        h5 {
          font-size: 10px;
        }
        a {
          font-size: 10px;
        }
      }
    }
  }
}
