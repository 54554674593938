@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.tutorial {
  @include flexJCenter;
  background: $bg_light_grey;
  height: 100%;

  &__container {
    @include flexJCenter;
    height: 100%;
  }
  &__sections {
    width: 1355px;
    padding: 36px 0 12px 0;
  }
  &__main {
    &__section {
      @include flexwrap;
      justify-content: space-between;
      // margin: 0 220px;
      gap: 60px;
      .gif {
        width: 100%;
        min-height: calc(var(--vh, 1vh) * 100 - 48px);
        @include flexCenter;
        img {
          width: 120px;
        }
        p {
          font-size: 12px;
        }
      }
    }
    &__card {
      width: 425px;
      cursor: pointer;
      &__container {
        border-radius: 12px;
        @include flexcolumn;
        justify-content: space-between;
      }
      &__heading {
        @include flexbetween;
        &__left {
          &__top {
            @include flexAcenter;
          h3 {
            padding: 20px 0 8px 0;
            font-size: 22px;
            font-family: $inter_semibold;
          }
          h6 {
            @include flexCenter;
            width: max-content;
            padding: 0 2px;
            height: 14px;
            background: #F4C135;
            border-radius: 2px;
            font-size: 11px;
            color: $primary_white;
            margin: 12px 0 0 8px;
            font-weight: 400;
          }
        }
          span {
            font-size: 14px;
            padding: 4px 12px;
            border-radius: 6px;
            color: $primary_white;
          }
        }
      }
      &__content {
        padding: 20px 0 0 0;
        p {
          font-size: 18px;
          font-weight: 400;
          color: #677074;
        }
        img {
          width: 100%;
        }
        &__video {
          position: relative;
          &__section {
            height: 200px;
            transition: 0.6s ease-in-out;
            position: relative;
            width: 100%;
            height: 200px;
            border-radius: 12px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              cursor: pointer;
            }
            .hover-video {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 12px;
              visibility: hidden;
              opacity: 0;
              transition: opacity 0.3s ease-in-out;
            }
          }
          &__section:hover {
            transform: scale(1.12);
            box-shadow: 0 0 4px 4px #e3e3e3;
            .hover-video {
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1090px) and (max-width: 1600px) {
  .tutorial {
    &__sections {
      width: 760px;
    }
    &__main {
      &__section {
        gap: 50px;
        margin: 0;
      }
      &__card {
        width: 355px;
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 767px) {
  .tutorial {
    &__sections {
      width: 82vw;
    }
    &__main {
      &__section {
        gap: 6vw;
        margin: 0;
      }
      &__card {
        width: 38vw;
        &__container {
          border-radius: 6px;
        }
        &__heading {
          &__left {
            h3 {
              font-size: 16px;
              padding: 12px 0 6px 0;
            }
            span {
              font-size: 12px;
            }
          }
        }
        &__content {
          padding: 12px 0 0 0;
          p {
            font-size: 12px;
          }
          &__video {
            &__section {
              img {
                height: 20vw;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1090px) {
  .tutorial {
    &__container {
      border-radius: 6px;
    }
    &__sections {
      width: 650px;
    }
    &__main {
      &__section {
        gap: 40px;
        margin: 0;
      }
      &__card {
        width: 305px;
        &__container {
          border-radius: 6px;
        }
        &__heading {
          &__left {
            h3 {
              font-size: 16px;
              padding: 12px 0 6px 0;
            }
            span {
              font-size: 12px;
            }
          }
        }
        &__content {
          padding: 12px 0 0 0;
          p {
            font-size: 12px;
          }
          &__video {
            &__section {
              img {
                height: 140px;
              }
            }
          }
        }
      }
    }
  }
}
