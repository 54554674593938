@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.background-tires {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: -56px;
  left: 0;
  z-index: 100;
}
.tires {
  position: fixed;
  top: 60px;
  left: 248px;
  width: 60%;
  height: calc(var(--vh, 1vh) * 100 - 50px - 24px);
  z-index: 1000;
  display: flex;
  justify-content: center;
  background: $primary_white;
  border-radius: 8px;
  a {
    color: $primary_black;
  }
  &__container {
    width: 100%;
  }
  &__disable-section {
    position: fixed;
    top: 60px;
    left: 12px;
    width: 232px;
    height: calc(var(--vh, 1vh) * 100 - 50px - 24px);
    background: $primary_white;
    border-radius: 8px;
    opacity: 0.5;
    cursor: not-allowed;
    z-index: 1001;
  }
  &__main {
    height: 100%;
    position: relative;
    &__close {
      img {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
        z-index: 1000 !important;
      }
    }
    &__section {
      height: 100%;
      display: flex;
      h3 {
        display: none;
      }
      &__menu1 {
        width: 20%;
        border-radius: 8px 0 0 8px;
        border: 1px solid #ededed;
        padding: 20px 16px;
        h4 {
          font-size: 18px;
          padding: 0 0 16px 0;
        }
        ul {
          padding: 0;
          margin: 0;
          height: 90%;
          overflow: scroll;
          li {
            list-style-type: none;
            padding: 8px;
            @include flexbetween;
            align-items: center;
            cursor: pointer;
            a {
              @include flexAcenter;
              width: 70%;
              text-decoration: none;
              font-size: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              //cursor: pointer;
            }
            span {
              @include flexCenter;
              width: 28px;
              padding: 0 0 1px 0;
              height: 11px;
              background: #f4c135;
              border-radius: 2px;
              font-size: 11px;
              color: $primary_white;
            }
            .icon {
              width: 10%;
              img {
                display: none;
                width: 20px;
                height: 20px;
                transform: scale(80%);
              }
            }
          }
          .active {
            background: $primary_black;
            color: $primary_white;
            border-radius: 4px;
            a {
              color: $primary_white;
            }
            .icon {
              img {
                display: flex;
              }
            }
          }
          li:hover {
            border-radius: 4px;
            background: $secondary_grey;
            a {
              color: $primary_black;
            }
            .icon {
              img {
                display: block;
                filter: hue-rotate(200.28deg) saturate(32.3%) brightness(22%);
              }
            }
          }
        }
      }
      &__sub-menu {
        padding: 20px 16px;
        width: 20%;
        border: 1px solid #ededed;
        h4 {
          font-size: 18px;
          padding: 0 0 16px 0;
        }
        ul {
          padding: 0;
          margin: 0;
          width: 100%;
          li {
            list-style-type: none;
            padding: 8px;
            @include flexbetween;
            align-items: center;
            cursor: pointer;
            a {
              @include flexAcenter;
              width: 70%;
              text-decoration: none;
              font-size: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              //cursor: pointer;
            }
            span {
              @include flexCenter;
              width: 28px;
              padding: 0 0 1px 0;
              height: 11px;
              background: #f4c135;
              border-radius: 2px;
              font-size: 11px;
              color: $primary_white;
            }
            .icon {
              width: 10%;
              img {
                display: none;
                width: 20px;
                height: 20px;
                transform: scale(80%);
              }
            }
          }
          .active {
            background: $primary_black;
            color: $primary_white;
            border-radius: 4px;
            a {
              color: $primary_white;
            }
            img {
              display: block;
            }
          }
          li:hover {
            background: $secondary_grey;
            border-radius: 4px;
            a {
              color: $primary_black;
            }
            img {
              display: block;
              filter: hue-rotate(200.28deg) saturate(32.3%) brightness(22%);
            }
          }
        }
      }
      &__styles {
        padding: 20px 0 20px 16px;
        width: calc(60%);
        border-radius: 0 8px 8px 0;
        border: 1px solid #ededed;
        position: relative;
        overflow: hidden;
        h4 {
          font-size: 18px;
          padding: 0 0 16px 0;
        }
        &__list {
          height: calc(100% - 24px * 2 - 18px);
          overflow: scroll;
          padding: 14px;
        }
        &__tab {
          &__heading {
            padding: 0 0 10px;
            @include flexAcenter;
            h6 {
              font-size: 16px;
              padding: 0 8px 0 0;
            }
            span {
              @include flexCenter;
              width: 28px;
              padding: 0 0 1px 0;
              height: 11px;
              background: #f4c135;
              border-radius: 2px;
              font-size: 11px;
              color: $primary_white;
            }
          }
          &__images {
            @include flexbetween;
            flex-wrap: wrap;
            &__image {
              width: 32%;
              border: 1px solid $primary_grey;
              border-radius: 10px;
              @include flexcolumn;
              justify-content: space-between;
              margin: 0 0 16px 0;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              cursor: pointer;
              h5 {
                font-size: 14px;
                font-weight: 500;
                padding: 10px;
              }
              img {
                width: 100%;
                border-radius: 0 0 10px 10px;
              }
            }
            .active {
              border: 1px solid $primary_black;
            }
          }
        }
      }
    }
  }
  ::-webkit-scrollbar {
    width: 6px !important;
    height: 0px !important;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: $secondary_grey;
    border-radius: $font_small;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: $secondary_grey;
  }
}

@media screen and (min-width: 1090px) and (max-width: 1250px) {
  .tires {
    &__main {
      &__section {
        &__menu1 {
          ul {
            li {
              a {
                font-size: 12px;
              }
            }
          }
        }
        &__sub-menu {
          ul {
            li {
              a {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1090px) {
  .tires {
    top: 48px;
    left: 174px;
    width: 550px;
    height: calc(100vh - 60px);
    border-radius: 6px;
    &__disable-section {
      width: 158px;
    }
    &__main {
      &__close {
        img {
          width: 16px;
          height: 16px;
          top: 12px;
          right: 12px;
        }
      }
      &__section {
        &__menu1 {
          width: 126px;
          padding: 12px 0 12px 8px;
          overflow: hidden;
          h4 {
            font-size: 12px;
            padding: 0 0 12px 0;
          }
          ul {
            li {
              padding: 4px;
              border-radius: 2px;
              a {
                font-size: 10px;
              }
            }
            li:last-child {
              padding: 4px 4px 12px 4px;
            }
            .active {
              img {
                width: 12px;
                height: 12px;
              }
            }
          }
        }
        &__sub-menu {
          width: 126px;
          padding: 12px 0 12px 8px;
          overflow: hidden;
          h4 {
            font-size: 12px;
          }
          ul {
            li {
              padding: 4px;
              border-radius: 2px;
              a {
                font-size: 10px;
              }
            }
            li:last-child {
              padding: 4px 4px 12px 4px;
            }
            .active {
              img {
                width: 12px;
                height: 12px;
              }
            }
          }
        }
        &__styles {
          width: 268px;
          padding: 12px 0 12px 8px;
          overflow: hidden;
          h4 {
            font-size: 12px;
            padding: 0 0 12px 0;
            font-weight: 400;
          }
          &__list {
            padding: 8px 2px;
            height: calc(100% - 42px);
          }
          &__tab {
            &__heading {
              padding: 0 0 4px 0;
              h6 {
                font-size: 10px;
              }
            }
            &__images {
              &__image {
                width: 79px;
                height: 98px;
                border-radius: 4px 4px 0 0;
                margin: 0 0 8px 0;
                h5 {
                  font-size: 10px;
                  padding: 4px 0;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                img {
                  width: 79px;
                  height: 78px;
                  border-radius: 0 0 4px 4px;
                }
              }
            }
          }
        }
      }
    }
    ::-webkit-scrollbar {
      width: 4px !important;
      height: 0px !important;
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 767px) {
  .tires {
    top: 48px;
    left: 24.5vw;
    width: 74vw;
    height: calc(100vh - 60px);
    border-radius: 6px;
    &__disable-section {
      width: 22.4vw;
    }
    &__main {
      &__close {
        img {
          width: 16px;
          height: 16px;
          top: 12px;
          right: 12px;
        }
      }
      &__section {
        &__menu1 {
          overflow: hidden;
          width: 16vw;
          padding: 12px 0 12px 8px;
          h4 {
            font-size: 12px;
          }
          ul {
            li {
              padding: 4px;
              border-radius: 2px;
              a {
                font-size: 10px;
              }
            }
            li:last-child {
              padding: 4px 4px 12px 4px;
            }
            .active {
              img {
                width: 12px;
                height: 12px;
              }
            }
          }
        }
        &__sub-menu {
          overflow: hidden;
          width: 16vw;
          padding: 12px 0 12px 8px;
          h4 {
            font-size: 12px;
          }
          ul {
            li {
              padding: 4px;
              border-radius: 2px;
              a {
                font-size: 10px;
              }
            }
            li:last-child {
              padding: 4px 4px 12px 4px;
            }
            .active {
              img {
                width: 12px;
                height: 12px;
              }
            }
          }
        }
        &__styles {
          width: 36vw;
          padding: 12px 0 12px 8px;
          overflow: hidden;
          h4 {
            font-size: 12px;
            padding: 0 0 12px 0;
          }
          &__list {
            padding: 8px 2px;
            height: calc(100% - 42px);
          }
          &__tab {
            &__heading {
              padding: 0 0 4px 0;
              h6 {
                font-size: 10px;
              }
            }
            &__images {
              &__image {
                width: 9.5vw;
                height: 14vw;
                border-radius: 4px;
                margin: 0 0 8px 0;
                h5 {
                  font-size: 10px;
                  padding: 4px 0 4px 4px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                img {
                  width: 9.5vw;
                  height: 11vw;
                  border-radius: 0 0 4px 4px;
                }
              }
            }
          }
        }
      }
    }
    ::-webkit-scrollbar {
      width: 4px !important;
      height: 0px !important;
    }
  }
}
