@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.commit-popup {
  background: rgb(0 0 0 / 54%);
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &__popup {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &__container {
      width: 350px;
      background: $primary_white;
      padding: 16px;
      border-radius: 8px;
    }
    &__section {
      @include flexColumnACenter;
      &__header {
        width: 100%;
        display: flex;
        position: relative;
        padding: 0 0 8px 0;
        img {
          width: 16px;
          height: 16px;
        }
        h3 {
          font-size: 14px;
        }
        .cross {
          position: absolute;
          right: 0;
          top: 0;
          cursor: pointer;
        }
      }
      &__content {
        padding: 0 0 8px 0;
        p {
          font-size: 12px;
          color: $primary_blue;
          padding: 0 0 $font_xverysmall 0;
          span {
            font-size: 10px;
            color: $primary_blue;
            text-decoration: underline;
          }
        }
      }
      &__plans {
        background: $bg_grey_backround;
        border: 1px solid $primary_grey;
        border-radius: 4px;
        padding: 0 12px;
        margin: 8px 0 0 0;
        width: 100%;
      }
      &__plan:last-child {
        border-bottom: none;
      }
      &__plan {
        width: 100%;
        border-bottom: 1px solid $primary_grey;
        padding: 12px 0;
        h4 {
          font-size: 14px;
          color: $primary_blue;
        }
        &__details {
          @include flexbetween;
          padding: 4px 0 0 0;
          h6 {
            font-size: 12px;
            color: $primary_blue;
            font-weight: 500;
          }

          h5 {
            font-size: 14px;
            color: $primary_blue;
            font-weight: 500;
          }
        }
      }
      &__amount {
        width: 100%;
        @include flexbetween;
        padding: 16px 0;
        &__date {
          @include flexrowacenter;
          h6 {
            font-size: 12px;
            color: $primary_blue;
            font-weight: 500;
          }
          h4 {
            font-size: 14px;
            padding: 0 0 0 6px;
            color: $primary_blue;
          }
        }
        h5 {
          font-size: 14px;
          color: $primary_blue;
        }
      }
      &__buttons {
        padding: 12px 0 0 0;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        &__section {
          width: 60%;
          display: flex;
          justify-content: flex-end;
          button {
            min-width: 80px;
            padding: 4px 8px;
            border-radius: 15px;
            border: none;
            background: $primary_black;
            color: $primary_white;
            cursor: pointer;
            font-size: 12px;
          }
          button:hover {
            opacity: 0.7;
          }
          button:active {
            opacity: 0.5;
          }
          .cancel {
            background: $primary_white;
            color: $primary_black;
            opacity: 0.5;
          }
          .cancel:hover {
            opacity: 0.7;
          }
          .cancel:active {
            opacity: 1;
          }
        }
      }
      .gif-icon {
        @include flexJCenter;
        .gif {
          width: 10vw;
        }
      }
    }
  }
}
