@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.existing-members {
  background: #0000008a;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.5s forwards;
  --container-max-height: 80vh;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  &__container {
    width: 430px;
    max-height: var(--container-max-height);
    background: $primary_white;
    border: 1px solid $primary_grey;
    border-radius: 8px;
    overflow: hidden;
  }
  .no-data {
    min-height: 360px;
    justify-content: space-between;
  }
  &__section {
    padding: 20px 40px;
    @include flexcolumn;
    &__buttons {
      @include flexJEnd;
    }
    p{
      color: red;
      font-size: 12px;
    }
  }
  &__heading {
    h2 {
      font-size: 18px;
      font-weight: 600;
    }
    p {
      font-size: 16px;
    }
    h5{
      color: $primary_blue;
      font-weight: 400;
    }
  }
  &__list {
    padding: 40px 0 0 0;
    max-height: calc(var(--container-max-height) - 300px);
    overflow: scroll;
    &__member {
      @include flexbetween;
      padding: 0 0 20px 0;
      &:last-child {
        padding: 0;
      }
      &__left {
        @include flexrowacenter;
      }
      &__icon {
        @include flexrowacenter;
        img {
          width: 40px;
          height: 40px;
        }
      }
      &__details {
        padding: 0 0 0 4px;
        h3 {
          font-size: 14px;
          color: $font_grey;
        }
        h4 {
          font-size: 14px;
          color: $font_grey;
        }
      }
      &__right {
        button {
          background: transparent;
          color: $primary_blue;
          min-width: 80px;
          height: 27px;
          font-size: 12px;
          font-family: $inter_regular;
          border: 1px solid $primary_blue;
          border-radius: 13px;
          cursor: pointer;
        }
        .invite {
          background: $primary_blue;
          color: $primary_white;
          border: none;
        }
        // .active {
        //   background: $primary_blue;
        //   color: $primary_white;
        //   border: none;
        // }
      }
    }
    &__loader {
      @include flexJCenter;
      img {
        width: 80px;
      }
    }
  }
  &__buttons {
    padding: 48px 0px 0 0px;
    @include flexJEnd;
    &__main {
      width: 160px;
      @include flexbetween;
    }
  }
}

@media screen and (max-width: 1250px) {
  .existing-members {
    &__container {
      width: 386px;
    }
    &__section {
      padding: 16px 32px;
    }
    &__heading {
      h2 {
        font-size: 16px;
      }
      p {
        font-size: 14px;
      }
    }
    &__list {
      padding: 32px 0 0 0;
      &__member {
        padding: 0 0 12px 0;
        &__icon {
          img {
            width: 32px;
            height: 32px;
          }
        }
        &__details {
          h3 {
            font-size: 12px;
          }
          h4 {
            font-size: 12px;
          }
        }
        &__right {
          button {
            font-size: 10px;
            min-width: 64px;
            height: 22px;
          }
        }
      }
    }
    &__buttons {
      padding: 32px 0 0 0;
      &__main {
        width: 144px;
      }
    }
  }
}