@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.feed-ref {
  user-select: text;
  &__container {
    @include flexCenter;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    z-index: 101;
    background: rgba(0, 0, 0, 0.7098039216);
    box-sizing: border-box;
    padding: 60px 42px 12px 42px;
    flex-direction: row-reverse;
    align-items: flex-start;
  }
  &__section {
    width: 100%;
    height: 100%;
    max-width: max-content;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
