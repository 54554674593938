@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.brand {
  &__card {
    border-radius: 1vw;
    padding: 0 1vw;
    background: $primary_white;
    border: 1px solid $primary_grey;
    &__container {
      &__content {
        padding: 1vw 0;
        p {
          font-size: 0.8vw;
        }
      }
      &__data {
        padding: 0.6vw 0 1.2vw 0;
        @include flexrow;
        &__icon {
          width: 3.7vw;
          height: 3.7vw;
          border-radius: 50%;
          background: #d9d9d9;
          img {
            width: 3.7vw;
            height: 3.7vw;
            border-radius: 50%;
          }
        }
        &__content {
          padding: 0 0 0 0.6vw;
          h4 {
            font-size: 0.9vw;
            font-weight: 500;
          }
          h5 {
            font-size: 0.8vw;
            padding: 0.3vw 0 0.1vw 0;
            font-weight: 500;
          }
          h6 {
            font-size: 0.7vw;
            font-weight: 500;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 767px) {
  .brand {
    &__card {
      padding: 0 12px;
      border-radius: 12px;
      &__container {
        &__content {
          padding: 12px 0;
          p {
            font-size: 12px;
            height: 164px !important;
            max-height: 164px !important;
          }
          .swiper-slide-next .brand__card__container__content p {
            height: 164px !important;
            max-height: 164px !important;
          }
        }
        &__data {
          padding: 12px 0;
          align-items: center;
          &__icon {
            width: 48px;
            height: 48px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          &__content {
            width: calc(100% - 48px);
            h4 {
              font-size: 12px;
            }
            h5 {
              font-size: 11px;
              padding: 0;
            }
            h6 {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1090px) {
  .brand {
    &__card {
      padding: 0 12px;
      border-radius: 12px;
      &__container {
        &__content {
          padding: 12px 0;
          p {
            font-size: 12px;
            height: 144px !important;
            max-height: 144px !important;
          }
          .swiper-slide-next .brand__card__container__content p {
            height: 144px !important;
            max-height: 144px !important;
          }
        }
        &__data {
          padding: 12px 0;
          align-items: center;
          &__icon {
            width: 48px;
            height: 48px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          &__content {
            width: calc(100% - 48px);
            h4 {
              font-size: 12px;
            }
            h5 {
              font-size: 11px;
              padding: 0;
            }
            h6 {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}