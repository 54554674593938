@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.options {
  color: $grey_input !important;
}
.options:hover {
  color: $primary_black !important;
}
.camera {
  width: 100%;
  position: relative;
  .options {
    font-size: 14px;
    color: black !important;
  }
  select {
    width: 100%;
  }
  .css-b62m3t-container {
    outline: none;
    border: 1px solid black;
    border-radius: 8px;
    max-height: 36px;
  }
  .css-13cymwt-control {
    background: $bg_grey_backround !important;
    border: none;
    cursor: pointer;
    min-height: 34px;
    max-height: 34px;
  }
  .css-t3ipsp-control {
    border-radius: 8px;
    border: none;
    background-color: $bg_grey_backround !important;
    box-shadow: none !important;
    min-height: 34px;
    max-height: 34px;
  }
  .css-1u9des2-indicatorSeparator {
    display: none !important;
  }
  .css-1fdsijx-ValueContainer {
    padding: 2px 0 2px 8px !important;
  }
}
.select-option {
  width: 100%;
  span {
    width: 100% !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.hover {
  &__options1 {
    width: 155px;
    position: fixed;
    z-index: 100;
  }
  &__section {
    @include flexcolumn;
    p {
      font-size: 12px;
      padding: 10px 0 2px 0;
      color: $font_grey;
    }
    background: $primary_white;
    padding: 8px;
    width: 80%;
    border-radius: 8px;
    border: 1px solid $primary_grey;
    box-shadow: 0px 5px 5px 0px #00000010;
  }
  &__image {
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1090px) {
  .camera {
    .css-13cymwt-control {
      height: 32px !important;
    }
    .css-1xc3v61-indicatorContainer {
      padding: 4px !important;
      svg {
        width: 16px !important;
        height: 16px !important;
      }
    }
  }
}
