@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.loading {
  position: fixed; 
  top: 48px;           
  left: 0;   
  z-index: 99;
  width: 100%;
  height: calc(100vh);
  @include flexCenter;
  background-color: #fbfbfb;
  &__container {
    position: relative;
    width: 100%;  
    height: 100%; 
    text-align: center; 
    img {
      position: absolute;
      top: 50%;  
      left: 50%; 
      transform: translate(-50%, -50%); 
      width: 100px;
    }
    p {
      position: absolute;
      top: calc(50% + 40px); 
      width: 100%;  
      text-align: center; 
      font-size: 14px;
      font-family: $inter_semibold;
      color: $primary_blue;
    }
  }
}
