@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.project-popover {
  width: 480px;
}
.custom-popover2 {
  width: 142px;
}
.custom-popover5 {
  width: 172px;
}
.ant-popover-inner {
  padding: 12px 0 !important;
}
.question-popover {
  width: 196px;
}
.popover-content {
  padding: 8px;
}
.popover-text {
  text-align: start !important;
}
.workshop {
  @include flexJCenter;
  background: $bg_light_grey;
  height: 100%;
  &__container {
    @include flexJCenter;
    height: 100%;
    position: relative;
  }
  &__section {
    width: 1144px;
    padding: 32px 0 0 0;
    &__teams {
      width: 1144px;
      &__list {
        @include flexbetween;
        &__expand {
          width: 800px;
          @include flexbetween;
          overflow: hidden;
          &__sec {
            width: 86px;
            @include flexrowacenter;
            justify-content: flex-end;
            height: max-content;
            cursor: pointer;
            padding: 0 12px;
            img {
              width: 18px;
              height: 18px;
              margin: 2px 0 0 0;
            }
            .expanded {
              transform: rotate(180deg);
            }
            h5 {
              padding: 0 0 0 8px;
              font-size: 16px;
              line-height: 20px;
            }
          }
        }
        &__button {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          button {
            @include flexrowacenter;
            width: 187px;
            height: 40px;
            border-radius: 8px;
            border: 1px solid $primary_blue;
            background: none;
            outline: none;
            cursor: pointer;
            img {
              width: 20px;
              height: 20px;
            }
            .manage-active {
              display: none;
            }
            span {
              padding: 0 0 0 8px;
              font-size: 16px;
            }
          }
          .center {
            justify-content: center;
          }
          button:hover {
            background: $secondary_grey;
          }
          button:active {
            background: $primary_blue;
            span {
              color: $primary_white;
            }
            .manage-active {
              display: block;
            }
            .manage-default {
              display: none;
            }
          }
        }
        &__cards {
          width: 614px;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 16px;
          max-height: 70px;
        }
        &__cards.show-more {
          max-height: max-content;
        }
        &__card.active {
          background: $primary_blue;
          transition: background 0.5s ease-in-out;
          h2 {
            color: $primary_white;
          }
          h4 {
            color: $primary_white;
          }
        }
        &__card.hovered {
          background: $secondary_grey;
          .card-dots {
            display: block;
          }
          h2 {
            color: $primary_blue;
          }
          h4 {
            color: $primary_blue;
          }
        }
        &__card {
          border-radius: 60px;
          padding: 8px 12px;
          cursor: pointer;
          transition: background 0.5s ease-in-out;
          &:hover {
            background: $secondary_grey;
            .card-dots {
              display: block;
            }
            h2 {
              color: $primary_blue;
            }
            h4 {
              color: $primary_blue;
            }
          }
          &__container {
            @include flexrowacenter;
            justify-content: space-between;
          }
          &__left {
            @include flexrowacenter;
          }
          &__name {
            padding: 0 0 0 12px;
            h2 {
              font-size: 16px;
              line-height: 22px;
              font-family: $inter_semibold;
              width: 128px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            h4 {
              font-size: 14px;
              line-height: 20px;
              font-weight: 400;
              width: 112px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          &__icon {
            @include flexCenter;
            img {
              width: 38px;
              height: 38px;
              border-radius: 50%;
              border: 1px solid $primary_white;
            }
          }
          &__dots {
            width: 20px;
            height: 20px;
            position: relative;
            img {
              width: 20px;
              height: 20px;
              display: none;
            }
            .card-dots.ant-popover-open {
              display: block;
            }
          }
          &__options {
            &__sec {
              padding: 8px 12px 8px 16px;
              @include flexAcenter;
              cursor: pointer;
              img {
                width: 20px;
                height: 20px;
                opacity: 0.4;
              }
              h5 {
                font-size: 14px;
                font-family: $inter_regular;
                padding: 0 0 0 18px;
                color: $font_grey;
              }
            }
            &__sec:hover {
              background: $secondary_grey;
              img {
                opacity: 0.6;
              }
            }
            &__sec:active {
              background: $secondary_grey;
              img {
                opacity: 1;
              }
            }
          }
          &__options.open {
            display: block;
          }
        }
      }
      &__loader {
        @include flexJCenter;
        width: 100%;
        height: 70px;
        align-items: center;  // Center vertically
        img {
          max-width: 120px;   // Set the maximum width
          max-height: 70px;   // Set the maximum height
          width: auto;        // Maintain aspect ratio
          height: auto;       // Maintain aspect ratio
        }
      }
    }
    &__projects {
      width: 1144px;
      height: calc(var(--vh, 1vh) * 100 - 174px);
      margin: 12px 0 0 0;
      &__container {
        height: calc(100% - 80px);
        padding: 40px;
        // box-shadow: -2px 0px 4px 0px rgba(0, 0, 0, 0.02);
        box-shadow: 2px 4px 8px 0px rgba(0, 0, 0, 0.1);
        background: $primary_white;
        border-radius: 8px;
        overflow: hidden;
      }
      &__header {
        margin: 0 0 12px 0;
        &__top {
          h2 {
            font-size: 22px;
            font-family: $inter_semibold;
          }
        }
        &__bottom {
          margin: 40px 0 0 0;
          @include flexbetween;
        }
        &__left {
          @include flexrow;
          &__sec.active {
            opacity: 1;
            border-bottom: 2px solid $primary_blue;
            .default {
              display: none;
            }
            .active {
              display: block;
            }
          }
          &__sec:hover {
            border-bottom: 2px solid $primary_grey;
            opacity: 0.8;
          }
          &__sec:last-child {
            border-bottom: none;
            opacity: 1;
          }
          &__sec {
            @include flexColumnACenter;
            cursor: pointer;
            padding: 0 0 12px 0;
            margin: 0 24px 0 0;
            opacity: 0.6;
            &:last-child {
              margin: 0;
              justify-content: flex-end;
            }
            &__icon {
              width: 24px;
              height: 24px;
              padding: 0 0 4px 0;
              img {
                width: 24px;
                height: 24px;
              }
              .default {
                display: block;
              }
              .active {
                display: none;
              }
            }
            &__name {
              h4 {
                font-size: 14px;
                line-height: 18px;
                font-family: $inter_medium;
              }
            }
            &__search {
              width: 250px;
              position: relative;
              @include flexAcenter;
              img {
                position: absolute;
                left: 12px;
                width: 20px;
                height: 20px;
              }
              input {
                width: 100%;
                height: 40px;
                padding: 0 25px 0 40px;
                border-radius: 8px;
                border: 1px solid $primary_grey;
                font-size: 14px;
                outline: none;
              }
            }
          }
        }
        &__right {
          @include flexrow;
          &__sec {
            @include flexrowacenter;
            &__button {
              button {
                @include flexCenter;
                border-radius: 8px;
                background: $primary_blue;
                border: 1px solid $primary_grey;
                width: 160px;
                height: 40px;
                cursor: pointer;
                img {
                  width: 20px;
                  height: 20px;
                }
                .add-active {
                  display: none;
                }
                span {
                  padding: 8px;
                  font-size: 16px;
                  color: $primary_white;
                }
              }
              button:hover {
                background: $secondary_grey;
                span {
                  color: $primary_blue;
                }
                .add-active {
                  display: block;
                }
                .add-default {
                  display: none;
                }
              }
              button:active {
                background: none;
                span {
                  color: $primary_blue;
                }
                .add-active {
                  display: block;
                }
                .add-default {
                  display: none;
                }
              }
            }
          }
        }
      }
      &__main {
        height: calc(100% - 120px);
        overflow: auto;
      }
      &__list {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 16px;
        padding: 16px 0 0 0;
        p {
          width: 100%;
          text-align: center;
          font-size: 14px;
        }
      }
      &__card {
        max-width: 200px;
        height: 213px;
        background: $primary_white;
        border-radius: 6px;
        border: 1px solid $primary_grey;
        &__container {
          height: 100%;
          @include flexcolumn;
          justify-content: space-between;
          position: relative;
          .user-avatar {
            width: 36px;
            height: 36px;
            position: absolute;
            top: 12px;
            right: 12px;
            border: 1px solid $grey_input;
            border-radius: 50%;
            padding: 1px;
          }
          &__icons {
            z-index: 98;
            display: none;
            position: absolute;
            background: $primary_white;
            width: 80px;
            height: 40px;
            border-radius: 76px;
            top: 12px;
            left: 12px;
            .project-icon {
              width: 20px;
              height: 20px;
              padding: 0 5px 0 5px;
              cursor: pointer;
            }
            .project-icon:last-child {
              padding: 0 5px 0 5px;
            }
          }
        }
        &__container:hover {
          .project-icons {
            @include flexaround;
            align-items: center;
          }
        }
        &__section {
          height: 150px;
          &__images {
            padding: 10px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 4px;
            img {
              width: 100%;
              border-radius: 2px;
              height: 63px;
              object-fit: cover;
              object-position: center;
            }
            img.single {
              width: 100%;
              height: 100%;
              padding: 0;
              object-fit: cover;
              object-position: center;
            }
          }
          &__images.single {
            display: flex;
            height: 130px;
          }
        }
        &__content {
          border-top: 1px solid $primary_grey;
          height: 50px;
          padding: 6px 12px !important;
          &__name {
            position: relative;
            h4 {
              width: 100%;
              font-size: 16px;
              font-family: $inter_regular;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .name-tooltip {
              display: none;
            }
          }
          &__name:hover {
            .name-tooltip {
              display: block;
            }
          }
          &__data {
            @include flexAcenter;
            justify-content: space-between;
            span {
              font-size: 12px;
              color: $grey_input;
            }
            &__right {
              width: 116px;
              @include flexAcenter;
              justify-content: flex-end;
            }
            &__users {
              display: flex;
              position: relative;
              width: 24px;
              height: 24px;
              img {
                width: 22px;
                height: 22px;
                position: absolute;
                border-radius: 50%;
                border: 1px solid $primary_grey;
              }
            }
            &__dots {
              width: 20px;
              height: 20px;
              img {
                cursor: pointer;
                width: 20px;
                height: 20px;
                display: none;
              }
              &__sec {
                padding: 8px 12px;
                @include flexAcenter;
                cursor: pointer;
                img {
                  width: 20px;
                  height: 20px;
                  opacity: 0.4;
                }
                h5 {
                  font-size: 14px;
                  font-family: $inter_regular;
                  padding: 0 0 0 12px;
                  color: $font_grey;
                }
              }
              &__sec:hover {
                background: $secondary_grey;
                img {
                  opacity: 0.6;
                }
              }
              &__sec:active {
                background: $secondary_grey;
                img {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
      &__card:hover {
        .card-dots {
          display: block;
        }
      }
      &__card.hovered {
        .card-dots {
          display: block;
        }
      }
      &__loader {
        padding: 100px 0 0 0;
        @include flexColumnACenter;
        img {
          width: 120px;
        }
        h4 {
          font-size: 18px;
          font-family: $inter_medium;
        }
        p {
          font-size: 14px;
          font-family: $inter_medium;
        }
      }
    }
    &__main {
      width: 1256px;
      &__button {
        button {
          @include flexrowacenter;
          background: none;
          border: 1px solid $primary_blue;
          padding: 10px 12px;
          border-radius: 8px;
          cursor: pointer;
          img {
            width: 18px;
            height: 11px;
          }
          h6 {
            font-size: 14px;
            padding: 0 0 0 4px;
          }
        }
      }
      &__privilege {
        border: 1px solid $primary_grey;
        background: $primary_white;
        border-radius: 8px;
        margin: 16px 0;
        box-shadow: 2px 4px 8px 0px rgba(0, 0, 0, 0.1);
        &__details {
          &__heading {
            height: 52px;
            padding: 0 20px;
            border-bottom: 1px solid $primary_grey;
            @include flexrowacenter;
            h2 {
              font-size: 18px;
              font-family: $inter_semibold;
            }
          }
          &__content {
            padding: 36px 20px;
            // height: 116px;
            @include flexrow;
            justify-content: space-between;
            align-items: flex-start;
            &__sec {
              width: 310px;
              &__sub {
                @include flexrow;
                align-items: flex-start;
                h3 {
                  font-size: 18px;
                  font-family: $inter_semibold;
                  padding: 0 4px 0 0;
                }
                img {
                  padding: 6px 0 0 0;
                  width: 16px;
                  height: 16px;
                }
              }
              h4 {
                font-size: 22px;
                font-family: $inter_semibold;
              }
            }
            &__sec:first-child {
              width: 310px;
            }
            &__sec:nth-child(2) {
              width: 188px;
            }
            &__sec:nth-child(3) {
              width: 188px;
            }
            &__sec:nth-child(4) {
              width: 342px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 767px) {
  .project-popover {
    width: 386px;
  }
  .custom-popover5 {
    width: 142px;
  }
  .question-popover {
    width: 164px;
  }
  .custom-popover2 {
    width: 124px;
  }
  .workshop {
    &__section {
      width: calc(100% - 32px);
      padding: 16px 0 0 0;
      &__teams {
        width: 100%;
        &__list {
          &__expand {
            width: 100%;
            &__sec {
              width: 78px;
              padding: 0 0 0 6px;
              img {
                width: 14px;
                height: 14px;
              }
              h5 {
                font-size: 12px;
              }
            }
          }
          &__cards {
            width: -webkit-fill-available;
            gap: 8px;
            grid-template-columns: repeat(3, 1fr);
            max-height: 50px;
          }
          &__card {
            padding: 4px 8px;
            &__icon {
              img {
                width: 28px;
                height: 28px;
              }
            }
            &__name {
              h2 {
                font-size: 14px;
                line-height: 20px;
                width: 9.9vw;
              }
              h4 {
                font-size: 10px;
                line-height: 16px;
                width: 9.9vw;
              }
            }
            &__options {
              &__sec {
                img {
                  width: 16px;
                  height: 16px;
                }
                h5 {
                  font-size: 12px;
                }
              }
            }
          }
          &__button {
            display: none;
            button {
              height: 36px;
              img {
                width: 16px;
                height: 16px;
              }
              span {
                font-size: 14px;
              }
            }
          }
        }
      }
      &__projects {
        width: 100%;
        height: auto;
        margin: 12px 0;
        &__main {
          height: auto;
          overflow: unset;
        }
        &__container {
          height: auto;
          padding: 12px;
        }
        &__header {
          &__top {
            display: none;
            h2 {
              font-size: 18px;
            }
          }
          &__bottom {
            margin: 0;
          }
          &__left {
            &__sec {
              margin: 0 12px 0 0;
              padding: 0 0 4px 0;
              &__icon {
                width: 16px;
                height: 16px;
                img {
                  width: 16px;
                  height: 16px;
                }
              }
              &__name {
                h4 {
                  font-size: 10px;
                }
              }
              &__search {
                width: 180px;
                input {
                  height: 32px;
                  padding: 0 12px 0 28px;
                  font-size: 10px;
                }
                img {
                  width: 14px;
                  height: 14px;
                  left: 8px;
                }
              }
            }
          }
          &__right {
            &__sec {
              &__button {
                button {
                  width: 112px;
                  height: 32px;
                  img {
                    width: 14px;
                    height: 14px;
                  }
                  span {
                    font-size: 10px;
                  }
                }
              }
            }
          }
        }
        &__list {
          grid-template-columns: repeat(4, 1fr);
          gap: 8px;
        }
        &__card {
          height: 162px;
          &__section {
            height: 116px;
            &__images {
              padding: 6px;
              gap: 4px;
              img {
                height: 49px;
              }
            }
            &__images.single {
              height: 102px;
            }
          }
          &__content {
            height: 40px;
            padding: 4px 8px !important;
            &__name {
              h4 {
                font-size: 12px;
              }
            }
            &__data {
              span {
                font-size: 10px;
              }
              &__right {
                width: 92px;
              }
              &__users {
                img {
                  width: 18px;
                  height: 18px;
                }
              }
              &__dots {
                width: 16px;
                height: 16px;
                img {
                  width: 16px;
                  height: 16px;
                }
                &__sec {
                  padding: 4px 8px;
                  img {
                    width: 16px;
                    height: 16px;
                  }
                  h5 {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
      &__main {
        width: 100%;
        &__button {
          button {
            padding: 6px 8px;
            img {
              width: 14px;
              height: 9px;
            }
            h6 {
              font-size: 12px;
            }
          }
        }
        &__privilege {
          &__details {
            &__heading {
              height: 48px;
              h2 {
                font-size: 14px;
              }
            }
            &__content {
              &__sec {
                &__sub {
                  h3 {
                    font-size: 14px;
                  }
                  img {
                    padding: 4px 0 0 0;
                  }
                }
                h4 {
                  font-size: 18px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1090px) {
  .project-popover {
    width: 386px;
  }
  .custom-popover5 {
    width: 142px;
  }
  .question-popover {
    width: 164px;
  }
  .custom-popover2 {
    width: 124px;
  }
  .workshop {
    &__section {
      width: 652px;
      padding: 16px 0 0 0;
      &__teams {
        width: 652px;
        &__list {
          &__expand {
            width: 536px;
            &__sec {
              width: 50px;
              img {
                width: 14px;
                height: 14px;
              }
              h5 {
                font-size: 12px;
              }
            }
          }
          &__cards {
            width: 352px;
            gap: 8px;
            grid-template-columns: repeat(3, 1fr);
            max-height: 50px;
          }
          &__card {
            padding: 4px 8px;
            &__icon {
              img {
                width: 32px;
                height: 32px;
              }
            }
            &__name {
              h2 {
                font-size: 12px;
                line-height: 16px;
                width: 90px;
              }
              h4 {
                font-size: 12px;
                line-height: 16px;
                width: 90px;
              }
            }
            &__options {
              &__sec {
                img {
                  width: 16px;
                  height: 16px;
                }
                h5 {
                  font-size: 12px;
                }
              }
            }
          }
          &__button {
            button {
              width: 28px;
              height: 28px;
              max-height: 50px;
              padding: 0;
              justify-content: center;
              img {
                width: 20px;
                height: 20px;
              }
              span {
                display: none;
                font-size: 12px;
              }
            }
          }
        }
      }
      &__projects {
        width: 652px;
        height: auto;
        margin: 12px 0;
        &__main {
          height: auto;
          overflow: unset;
        }
        &__container {
          height: calc(100% - 48px);
          padding: 24px;
        }
        &__header {
          &__top {
            display: none;
            h2 {
              font-size: 18px;
            }
          }
          &__bottom {
            margin: 0;
          }
          &__left {
            &__sec {
              margin: 0 18px 0 0;
              padding: 0 0 8px 0;
              &__icon {
                width: 20px;
                height: 20px;
                img {
                  width: 20px;
                  height: 20px;
                }
              }
              &__name {
                h4 {
                  font-size: 12px;
                }
              }
              &__search {
                input {
                  height: 32px;
                  padding: 0 18px 0 32px;
                  font-size: 12px;
                }
                img {
                  width: 16px;
                  height: 16px;
                }
              }
            }
          }
          &__right {
            &__sec {
              &__button {
                button {
                  width: 138px;
                  height: 34px;
                  img {
                    width: 16px;
                    height: 16px;
                  }
                  span {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
        &__list {
          grid-template-columns: repeat(4, 1fr);
          gap: 8px;
        }
        &__card {
          height: 162px;
          &__section {
            &__images {
              padding: 6px;
              gap: 4px;
              img {
                height: 49px;
              }
            }
            &__images.single {
              height: 102px;
            }
          }
          &__content {
            height: 49px;
            padding: 4px 8px !important;
            &__name {
              h4 {
                font-size: 12px;
              }
            }
            &__data {
              span {
                font-size: 10px;
              }
              &__right {
                width: 78px;
              }
              &__users {
                width: 20px;
                height: 20px;
                img {
                  width: 18px;
                  height: 18px;
                }
              }
              &__dots {
                width: 16px;
                height: 16px;
                img {
                  width: 16px;
                  height: 16px;
                }
                &__sec {
                  padding: 4px 8px;
                  img {
                    width: 16px;
                    height: 16px;
                  }
                  h5 {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
      &__main {
        width: 652px;
        &__button {
          button {
            padding: 6px 8px;
            img {
              width: 14px;
              height: 9px;
            }
            h6 {
              font-size: 12px;
            }
          }
        }
        &__privilege {
          &__details {
            &__heading {
              height: 48px;
              h2 {
                font-size: 14px;
              }
            }
            &__content {
              &__sec {
                &__sub {
                  h3 {
                    font-size: 14px;
                  }
                }
                h4 {
                  font-size: 18px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1090px) and (max-width: 1250px) {
  .project-popover {
    width: 386px;
  }
  .custom-popover5 {
    width: 142px;
  }
  .question-popover {
    width: 164px;
  }
  .custom-popover2 {
    width: 124px;
  }
  .workshop {
    &__section {
      width: 732px;
      &__teams {
        width: 732px;
        &__list {
          &__expand {
            width: 600px;
            &__sec {
              width: 50px;
              img {
                width: 14px;
                height: 14px;
              }
              h5 {
                font-size: 12px;
              }
            }
          }
          &__cards {
            width: 352px;
            gap: 8px;
            grid-template-columns: repeat(3, 1fr);
            max-height: 50px;
          }
          &__card {
            &__icon {
              img {
                width: 24px;
                height: 24px;
              }
            }
            &__name {
              h2 {
                font-size: 12px;
                line-height: 16px;
                width: 90px;
              }
              h4 {
                font-size: 12px;
                line-height: 16px;
                width: 90px;
              }
            }
            &__options {
              &__sec {
                img {
                  width: 16px;
                  height: 16px;
                }
                h5 {
                  font-size: 12px;
                }
              }
            }
          }
          &__button {
            button {
              width: 115px;
              max-height: 50px;
              img {
                width: 20px;
                height: 20px;
              }
              span {
                font-size: 12px;
              }
            }
          }
        }
      }
      &__projects {
        height: calc(var(--vh, 1vh) * 100 - 158px);
        width: 732px;
        &__container {
          height: calc(100% - 48px);
          padding: 24px;
        }
        &__header {
          &__top {
            h2 {
              font-size: 18px;
            }
          }
          &__bottom {
            margin: 24px 0 0 0;
          }
          &__left {
            &__sec {
              margin: 0 18px 0 0;
              padding: 0 0 8px 0;
              &__icon {
                width: 20px;
                height: 20px;
                img {
                  width: 20px;
                  height: 20px;
                }
              }
              &__name {
                h4 {
                  font-size: 12px;
                }
              }
              &__search {
                input {
                  height: 32px;
                  padding: 0 18px 0 32px;
                  font-size: 12px;
                }
                img {
                  width: 16px;
                  height: 16px;
                }
              }
            }
          }
          &__right {
            &__sec {
              &__button {
                button {
                  width: 138px;
                  height: 34px;
                  img {
                    width: 16px;
                    height: 16px;
                  }
                  span {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
        &__list {
          grid-template-columns: repeat(4, 1fr);
          gap: 12px;
        }
        &__card {
          height: 162px;
          max-width: 150px;
          &__section {
            height: 114px;
            &__images {
              padding: 6px;
              gap: 4px;
              img {
                height: 49px;
              }
            }
            &__images.single {
              height: 102px;
            }
          }
          &__content {
            height: 38px;
            padding: 4px 8px !important;
            &__name {
              h4 {
                font-size: 12px;
              }
            }
            &__data {
              span {
                font-size: 10px;
              }
              &__right {
                width: 92px;
              }
              &__users {
                img {
                  width: 18px;
                  height: 18px;
                }
              }
              &__dots {
                width: 16px;
                height: 16px;
                img {
                  width: 16px;
                  height: 16px;
                }
                &__sec {
                  padding: 4px 8px;
                  img {
                    width: 16px;
                    height: 16px;
                  }
                  h5 {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
      &__main {
        width: 732px;
        &__privilege {
          &__details {
            &__heading {
              height: 48px;
              h2 {
                font-size: 14px;
              }
            }
            &__content {
              padding: 20px;
              &__sec {
                &__sub {
                  h3 {
                    font-size: 14px;
                  }
                  img {
                    padding: 4px 0 0 0;
                    width: 14px;
                    height: 14px;
                  }
                }
                h4 {
                  font-size: 18px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1250px) and (max-width: 1610px) {
  .workshop {
    &__section {
      width: 874px;
      &__teams {
        width: 874px;
        &__list {
          &__expand {
            width: 660px;
            &__sec {
              width: 72px;
              img {
                width: 16px;
                height: 16px;
              }
              h5 {
                font-size: 14px;
              }
            }
          }
          &__cards {
            width: 372px;
            gap: 8px;
            grid-template-columns: repeat(3, 1fr);
            max-height: 50px;
          }
          &__card {
            &__icon {
              img {
                width: 32px;
                height: 32px;
              }
            }
            &__name {
              h2 {
                font-size: 12px;
                line-height: 16px;
                width: 98px;
              }
              h4 {
                font-size: 12px;
                line-height: 16px;
                width: 98px;
              }
            }
            &__options {
              &__sec {
                img {
                  width: 16px;
                  height: 16px;
                }
                h5 {
                  font-size: 12px;
                }
              }
            }
          }
          &__button {
            button {
              width: 160px;
              max-height: 50px;
              img {
                width: 20px;
                height: 20px;
              }
              span {
                font-size: 14px;
              }
            }
          }
        }
      }
      &__projects {
        width: 874px;
        height: calc(var(--vh, 1vh)* 100 - 154px);
        &__container {
          height: calc(100% - 64px);
          padding: 32px;
        }
        &__header {
          &__top {
            h2 {
              font-size: 18px;
            }
          }
          &__bottom {
            margin: 32px 0 0 0;
          }
          &__left {
            &__sec {
              margin: 0 18px 0 0;
              padding: 0 0 8px 0;
              &__icon {
                width: 20px;
                height: 20px;
                img {
                  width: 20px;
                  height: 20px;
                }
              }
              &__name {
                h4 {
                  font-size: 14px;
                }
              }
              &__search {
                input {
                  height: 40px;
                  padding: 0 18px 0 40px;
                  font-size: 14px;
                }
                img {
                  width: 20px;
                  height: 20px;
                }
              }
            }
          }
          &__right {
            &__sec {
              &__button {
                button {
                  width: 138px;
                  height: 34px;
                  img {
                    width: 16px;
                    height: 16px;
                  }
                  span {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
        &__list {
          grid-template-columns: repeat(4, 1fr);
          gap: 8px;
        }
      }
      &__main {
        width: 874px;
        &__privilege {
          &__details {
            &__heading {
              h2 {
                font-size: 16px;
              }
            }
            &__content {
              padding: 20px;
              &__sec {
                &__sub {
                  h3 {
                    font-size: 16px;
                  }
                  img {
                    padding: 4px 0 0 0;
                  }
                }
                h4 {
                  font-size: 18px;
                }
              }
              &__sec:first-child {
                width: 204px;
              }
              &__sec:nth-child(2) {
                width: 136px;
                padding: 0 64px;
              }
              &__sec:nth-child(3) {
                width: 232px;
              }
            }
          }
        }
      }
    }
  }
}
