@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}
.atmosphere-swift {
  position: fixed;
  top: 48px;
  margin: 12px 0;
  left: 248px;
  width: 348px;
  height: -webkit-fill-available;
  z-index: 1000;
  display: flex;
  justify-content: center;
  background: $primary_white;
  border: 1px solid $primary_grey;
  border-radius: 8px;
  &__container {
    width: 100%;
  }
  &__main {
    height: 100%;
    position: relative;
    margin: 0 16px 0 0;
    &__heading {
      @include flexCenter;
      padding: 0 0 0 16px;
      width: 100%;
      &__tabs {
        margin: $font_xsmall 0 $font_verysmall 0;
        @include flexCenter;
        gap: 6px;
        height: 31px;
        border: 1px solid $primary_grey;
        background: $bg_grey_backround;
        padding: 0 4px;
        border-radius: 23px;
      }
      &__tab {
        padding: 0 16px;
        height: 25px;
        @include flexCenter;
        border-radius: 18px;
        cursor: pointer;
        &.active {
          background: $primary_blue;
          h3 {
            color: $primary_white;
          }
        }
        h3 {
          font-size: 12px;
          font-weight: 400;
          color: $primary_blue;
        }
      }

      img {
        cursor: pointer;
        width: $font_verysmall;
      }
    }
    &__section {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 0 2px 0 0;
      width: 100%;
      &__main-menus {
        width: 75%;
        display: flex;
        justify-content: space-between;
        &__sec {
          width: calc(33% - 5.3px);
          padding: 0 8px 8px 0;
          @include flexCenter;
          opacity: 0.4;
          img {
            width: $font_small;
          }
          h5 {
            font-size: 12px;
            padding: 0 0 0 2px;
          }
        }
        &__sec.active {
          opacity: 1;
        }
      }
      &__images {
        width: 100%;
        @include flexcolumn;
        justify-content: space-between;
        padding: $font_xsmall 0;
        &__side-menu {
          display: flex;
          align-items: center;
          width: 100%;
          .default {
            img {
              width: 16px;
              height: 16px;
            }
          }
          &__header {
            @include flexColumnACenter;
            width: 25%;
            opacity: 0.4;
            img {
              width: 20px;
              height: 20px;
            }
            h5 {
              font-size: 12px;
              padding: 2px 0 0 0;
            }
          }
          &__header.active {
            opacity: 1;
          }
          &__content {
            width: 75%;
            display: flex;
          }
        }
      }
      .default:hover {
        border: 1px solid $primary_black;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
      }
      .default.active {
        border: 1px solid $primary_black;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
      }
      .default {
        background: $primary_grey;
        width: 100%;
        height: 100%;
        @include flexCenter;
        flex-direction: column;
        border-radius: 5px;
        padding: 0;
        img {
          width: 16px;
          height: 16px;
        }
        img:hover {
          width: 16px;
          height: 16px;
        }
        .active {
          width: 16px;
          height: 16px;
        }
        h5 {
          padding: 8px 0 0 0;
          font-size: 12px;
          font-weight: 400;
        }
      }
      &__image {
        width: calc(33.3% - 5.3px);
        height: max-content;
        padding: 0 8px 8px 0;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }
        img:hover {
          border: 1px solid $primary_black;
          padding: 1px;
          width: calc(100% - 4px);
          height: calc(100% - 4px);
        }
        .active {
          border: 1px solid $primary_black;
          padding: 1px;
          width: calc(100% - 4px);
          height: calc(100% - 4px);
        }
      }
      &__image:nth-child(4n) {
        padding: 0 0 8px 0;
      }
      &__button {
        position: absolute;
        bottom: $font_xverysmall;
        right: $font_xverysmall;
        padding: 0 $font_xlarge 0 0;
        button {
          width: $font_top6;
          padding: $font_xverysmall 0;
          border-radius: $font_large;
          border: none;
          background: $primary_black;
          color: $primary_white;
          cursor: pointer;
        }
        button:disabled {
          cursor: not-allowed;
          background: #d9d9d9;
          color: #d9d9d9;
          border-radius: 0;
        }
      }
    }
  }
  ::-webkit-scrollbar {
    width: 10px !important;
    height: 0px !important;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: $secondary_grey;
    border-radius: $font_small;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: $secondary_grey;
  }
}

@media screen and (min-width: 1090px) and (max-width: 1250px) {
  .atmosphere {
    width: 310px;
  }
}

@media screen and (min-width: 600px) and (max-width: 767px) {
  .atmosphere {
    left: 24.5vw !important;
  }
}

@media screen and (min-width: 600px) and (max-width: 1090px) {
  .atmosphere {
    top: 48px;
    height: calc(100vh - 60px);
    left: 174px;
    margin: 0;
    width: 348px;
    &__container {
      padding: 12px 20px;
      ::-webkit-scrollbar {
        width: 4px !important;
        height: 0px !important;
      }
    }
    &__main {
      padding: 0;
      overflow: auto;
      &__heading {
        padding: 0;
        h4 {
          padding: 0;
          font-size: 12px;
        }
        img {
          width: 16px;
        }
      }
      &__section {
        padding: 12px 0 0 0;
        &__main-menus {
          &__sec {
            padding: 0 0 8px 0;
            img {
              width: 20px;
              height: 20px;
            }
            h5 {
              font-size: 11px;
            }
          }
        }
        &__images {
          &__side-menu {
            &__header {
              width: 50px;
              margin: 0 12px 0 0;
              img {
                width: 20px;
              }
              h5 {
                font-size: 11px;
              }
            }
          }
        }
        &__image {
          padding: 0;
          margin: 0 6px 6px 0;
          width: 80px;
          height: 80px;
          img {
            width: 80px;
            height: 80px;
            border-radius: 3px;
          }
          img:hover {
            width: 78px;
            height: 78px;
          }
        }
        &__image:nth-child(3n) {
          margin: 0 0 6px 0;
        }
        &__image .active {
          width: 78px;
          height: 78px;
        }
      }
    }
  }
}
