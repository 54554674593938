@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.confirm {
  background: #0000008a;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &__dialog {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__center-div {
      width: 480px;
      background: $primary_white;
      padding: 16px;
      border-radius: 8px;
      @include flexColumnACenter;
    }

    &__button:focus {
      outline: none;
    }

    &__main {
      width: 100%;
      @include flexcolumn;
      &__heading {
        width: 100%;
        @include flexrow;
        position: relative;
        padding: 0 0 24px 0;
        text-align: start;
        img {
          width: 20px;
          height: 20px;
          margin: 4px 0 0 0;
        }
        h3 {
          width: calc(100% - 52px);
          font-size: 18px;
          font-weight: 600;
          padding: 0 0 0 10px;
        }
        .close {
          cursor: pointer;
          position: absolute;
          top: 0px;
          right: 0px;
        }
      }
      &__heading.close {
        padding: 0 0 8px 0;
      }
      &__content {
        width: calc(100% - 28px);
        text-align: start;
        padding: 0 0 0 28px;
        p {
          font-size: 14px;
          color: $primary_blue;
          padding: 0 0 8px 0;
        }
      }
    }
    &__button {
      padding: 16px 0 0 0;
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
    &__buttons {
      padding: 16px 0 0 0;
      display: flex;
      justify-content: flex-end;
      width: 100%;
      &__main {
        min-width: 220px;
        @include flexbetween;
      }
      .button_link {
        min-width: 96px;
        border-radius: 15px;
        font-size: 14px;
      }
      .button {
        min-width: 96px;
        border-radius: 15px;
        font-size: 14px;
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 1090px) {
  .confirm {
    &__dialog {
      width: 100%;
      &__center-div {
        width: 320px;
        padding: 12px;
      }
      &__main {
        &__heading {
          padding: 0 0 18px 0;
          img {
            width: 14px;
            height: 14px;
          }
          h3 {
            font-size: 14px;
          }
        }
        &__content {
          width: calc(100% - 24px);
          padding: 0 0 0 24px;
          p {
            font-size: 12px;
          }
        }
      }
      &__buttons {
        &__main {
          width: 180px;
        }
        .button_link {
          min-width: 86px;
          font-size: 12px;
        }
        .button {
          min-width: 86px;
          font-size: 12px;
        }
      }
    }
  }
}

@media screen and (min-width: 1090px) and (max-width: 1250px) {
  .confirm {
    &__dialog {
      width: 100%;
      &__center-div {
        width: 364px;
        padding: 12px;
      }
      &__main {
        &__heading {
          padding: 0 0 18px 0;
          img {
            width: 16px;
            height: 16px;
          }
          h3 {
            font-size: 14px;
          }
        }
        &__content {
          width: calc(100% - 24px);
          padding: 0 0 0 24px;
          p {
            font-size: 12px;
          }
        }
      }
      &__buttons {
        &__main {
          min-width: 164px;
        }
        .button_link {
          min-width: 72px;
          font-size: 12px;
        }
        .button {
          min-width: 72px;
          font-size: 12px;
        }
      }
    }
  }
}
