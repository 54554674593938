@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.project-edit {
  background: #0000008a;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.5s forwards;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  &__main {
    width: 480px;
    background: $primary_white;
    border: 1px solid $primary_grey;
    border-radius: 8px;
    &__container {
      padding: 0 40px;
      &__heading {
        padding: 20px 0 0 0;
        h2 {
          font-size: 18px;
          font-family: $inter_semibold;
        }
      }
      &__content {
        padding: 40px 0;
        @include flexrow;
        h4 {
          @include flexrowacenter;
          font-size: 14px;
          font-family: $inter_regular;
          height: 32px;
        }
        &__input {
          width: 296px;
          padding: 0 0 0 16px;
          input {
            width: calc(100% - 32px);
            border-radius: 120px;
            height: 32px;
            padding: 0 16px;
            font-size: 14px;
            border: 1px solid $primary_grey;
            color: $primary_blue;
            outline: none;
          }
          h6 {
            text-align: end;
            font-weight: 400;
            font-size: 12px;
            margin: 8px 0 16px 0;
            span {
              font-size: 12px;
            }
          }
          p {
            font-size: 12px;
            color: red;
          }
        }
      }
      &__buttons {
        padding: 12px 0;
        @include flexJEnd;
        &__main {
          width: 166px;
          @include flexbetween;
        }
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .project-edit {
    &__main {
      width: 386px;
      &__container {
        padding: 0 32px;
        &__heading {
          padding: 16px 0 0 0;
          h2 {
            font-size: 16px;
          }
        }
        &__content {
          padding: 32px 0;
          h4 {
            font-size: 12px;
          }
          &__input {
            width: 232px;
            padding: 0 0 0 12px;
            input {
              width: calc(100% - 16px);
              font-size: 12px;
              padding: 0 8px;
            }
            h6 {
              font-size: 10px;
              span {
                font-size: 10px;
              }
            }
            p {
              font-size: 10px;
            }
          }
        }
        &__buttons {
          padding: 12px 0;
          &__main {
            width: 144px;
          }
        }
      }
    }
  }
}