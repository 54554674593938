@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.team-notifications {
  background: #0000008a;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__sections {
    position: relative;
    width: 640px;
    max-height: 85vh;
    overflow: auto;
    background: $primary_white;
    border-radius: 8px;
    border: 1px solid $primary_grey;
  }
  &__header {
    @include flexAcenter;
    justify-content: space-between;
    padding: 0 20px;
    &__left {
      height: 53px;
      @include flexAcenter;
      h3 {
        font-size: 18px;
        font-weight: 600;
        color: $primary_blue;
      }
    }
    &__right {
      @include flexAcenter;
      padding: 0 44px 0 0;
      .cross {
        position: absolute;
        width: 24px;
        height: 24px;
        top: 15px;
        right: 15px;
        cursor: pointer;
      }
    }
  }
  &__list {
    padding: 12px 0;
    &__sec {
      margin: 0 20px 12px 20px;
      padding: 12px;
      border-radius: 8px;
      background: $primary_grey;
      h4 {
        font-size: 16px;
        font-weight: 600;
      }
      &__content {
        padding: 4px;
        &__main {
          @include flexAcenter;
          padding: 12px 0 8px 0;
          h5 {
            font-size: 16px;
            padding: 0 0 0 8px;
          }
          img {
            width: 96px;
          }
        }
        p {
          font-size: 14px;
          color: $font_grey;
        }
      }
      &__buttons {
        padding: 4px;
        @include flexJEnd;
        &__main {
          @include flexbetween;
          min-width: 160px;
          .disable-button {
            min-width: 80px;
            padding: 6px 12px;
            border-radius: 15px;
            border: none;
            background: $grey_input;
            cursor: default;
            color: $primary_white;
            font-size: 12px;
          }
        }
      }
    }
    &__nodata {
      p {
        text-align: center;
        padding: 0 0 12px 0;
      }
    }
  }
}

.accept-popover {
  width: 220px !important;
  .ant-popover-inner-content {
    text-align: center !important;
  }
}

@media screen and (min-width: 600px) and (max-width: 1090px) {
  .team-notifications {
    &__sections {
      width: 560px;
    }
    &__list {
      &__sec {
        &__content {
          &__buttons {
            &__main {
              .disable-button {
                padding: 4px 8px;
                min-width: 72px;
                border-radius: 15px;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
