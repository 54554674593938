@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.tooltipr {
  position: absolute;
  width: 222px;
  top: -12px;
  left: -228px;
  @include flexCenter;
  flex-direction: row-reverse;
  &__container {
    width: 100%;
    background: $primary_white;
    border-radius: 4px;
    padding: 12px;
    z-index: 1000;
    box-shadow: 3px 2px 4px 0px #00000025, -4px 2px 4px 0px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    ul {
      width: 100%;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      list-style-position: inside;
      li {
        font-size: 14px;
        list-style-type: none;
        padding: 0 0 0 4px;
        width: fit-content;
        span {
          font-size: 14px;
        }
      }
    }
  }
  &__section {
    width: 100%;
    @include flexCenter;
  }
  &__arrow {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 7px solid $primary_white;
    transform: rotate(180deg);
    // box-shadow: 0px -4px 4px 0px #00000025;
    z-index: 10001;
  }
}

@media screen and (min-width: 768px) and (max-width: 1610px) {
  .tooltipr {
    &__container {
      padding: 10px;
      ul {
        li {
          font-size: 12px;
          span {
            font-size: 12px;
          }
        }
      }
    }
  }
}
