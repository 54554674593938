.payment {
    background: rgb(0 0 0 / 54%);
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &__container {
      width: 800px;
      img {
        width: 100%;
      }
    }
  }
  