@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.custom-popover {
  width: 64px;
  .ant-popover-inner-content {
    text-align: center !important;
  }
}
.ant-popover-inner {
  padding: 8px 0 !important;
  text-align: start !important;
}

.data-max-length-Username {
  max-width: 100px;
}

.team-member-details {
  width: 100%;
  height: 100%;
  &__container {
    height: 100%;
  }
  &__heading {
    padding: 0 12px 20px 12px;
    @include flexbetween;
    h2 {
      font-size: 18px;
      font-family: $inter_semibold;
    }
    &__name {
      &__search {
        display: flex;
        align-items: center;
        gap: 12px;
        input {
          width: 348px;
          border: 1px solid $primary_grey;
          height: 40px;
          border-radius: 23px;
          padding: 0 0 0 12px;
          font-size: 14px;
        }
        button {
          border: none;
          background: $primary_blue;
          color: $primary_white;
          border-radius: 23px;
          font-size: 14px;
          width: 86px;
          height: 32px;
          cursor: pointer;
          &:hover {
            opacity: 0.6;
          }
          &:disabled {
            opacity: 0.6;
            cursor: default
          }
        }
      }
    }
    &__buttons {
      @include flexrow;
      button {
        background: none;
        border: 1px solid $primary_blue;
        border-radius: 16px;
        @include flexrowacenter;
        padding: 8px 12px;
        cursor: pointer;
        font-size: 12px;
        &:first-child {
          margin: 0 12px 0 0;
        }
        img {
          width: 16px;
          height: 16px;
        }
        .active-icon {
          display: none;
        }
        span {
          font-size: 14px;
          padding: 0 0 0 4px;
        }
      }
      button:active {
        border: none;
        background: $primary_blue;
        .default-icon {
          display: none;
        }
        .active-icon {
          display: block;
        }
        span {
          color: $primary_white;
        }
      }
    }
    &__content {
      h5 {
        font-size: 18px;
        font-family: $inter_semibold;
      }
    }
  }
  &__section {
    height: calc(100% - 50px);
    &__heading {
      @include flexrow;
      padding: 0 16px 12px 16px;
      border-bottom: 1px solid $primary_grey;
      &__sec {
        width: 164px;
        h5 {
          font-size: 14px;
        }
      }
    }
    &__list {
      padding: 20px 16px 0 16px;
      height: calc(100% - 55px);
      overflow: auto;
      &__sec {
        @include flexrow;
        padding: 0 0 10px 0;
        &__data {
          min-width: 164px;
          width: 164px;
          @include flexrowacenter;
          &__icon {
            padding: 0 4px 0 0;
            @include flexrowacenter;
            img {
              width: 40px;
              height: 40px;
            }
          }
          &__content {
            @include flexColumnACenter;
            h4 {
              min-width: 100px;
              font-size: 14px;
              color: $font_grey;
            }
          }
          h4 {
            min-width: 100px;
            font-size: 14px;
            color: $font_grey;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          button {
            border: none;
            background: none;
            cursor: pointer;
            position: relative;
            padding: 0;
            img {
              width: 20px;
              height: 20px;
            }
          }
          button:hover {
            img {
              opacity: 0.7;
            }
          }
          .status-icon {
            width: 40px;
            height: 20px;
            @include flexJCenter;
          }
        }
        .data2 {
          width: 328px;
        }
        .data1 {
          width: 240px;
          @include flexJEnd;
        }
      }
    }
    &__loader {
      @include flexJCenter;
      padding: 40px 0 0 0;
      img {
        width: 120px;
      }
    }
  }
}

@media screen and (min-width: 1250px) and (max-width: 1610px) {
  .team-member-details {
    &__heading {
      padding: 0 8px 12px 8px;
      h2 {
        font-size: 16px;
      }
      &__name {
        &__search {
          gap: 8px;
          input {
            width: 228px;
            height: 24px;
            border-radius: 23px;
            padding: 0 0 0 12px;
          }
          button {
            border-radius: 13px;
            font-size: 12px;
            width: 56px;
            height: 24px;
          }
        }
      }
      &__content {
        h5 {
          font-size: 16px;
        }
      }
      &__buttons {
        button {
          padding: 4px 8px;
          img {
            width: 12px;
            height: 12px;
          }
          span {
            font-size: 12px;
          }
        }
      }
    }
    &__section {
      &__heading {
        &__sec {
          width: 130px;
          h5 {
            font-size: 12px;
          }
        }
      }
      &__list {
        &__sec {
          &__data {
            min-width: 130px;
            width: 130px;
            h4 {
              font-size: 12px;
            }
            &__icon {
              img {
                width: 32px;
                height: 32px;
              }
            }
            &__content {
              h4 {
                font-size: 12px;
              }
            }
          }
          .data1 {
            width: 124px;
          }
          .data2 {
            width: 208px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1090px) and (max-width: 1250px) {
  .data-max-length-Username {
    max-width: 90px;
    //padding-right: 20px;
  }
  .team-member-details {
    &__heading {
      padding: 0 8px 12px 8px;
      h2 {
        font-size: 14px;
      }
      &__name {
        &__search {
          gap: 8px;
          input {
            width: 148px;
            height: 24px;
            border-radius: 23px;
            padding: 0 0 0 12px;
          }
          button {
            border-radius: 13px;
            font-size: 12px;
            width: 56px;
            height: 24px;
          }
        }
      }
      &__content {
        h5 {
          font-size: 14px;
        }
      }
      &__buttons {
        button {
          padding: 4px 6px;
          img {
            width: 12px;
            height: 12px;
          }
          span {
            font-size: 11px;
          }
          &:first-child {
            margin: 0 4px 0 0;
          }
        }
      }
    }
    &__section {
      &__heading {
        padding: 0 12px 8px 12px;
        &__sec {
          width: 135px;
          &:nth-child(2) {
            width: 100px;
          }
          &:last-child {
            width: 100px;
          }
          h5 {
            font-size: 12px;
          }
        }
      }
      &__list {
        padding: 16px 12px 0 12px;
        &__sec {
          &__data {
            min-width: 135px;
            width: 135px;
            &:nth-child(2) {
              min-width: 100px;
              width: 100px;
            }
            &:last-child {
              min-width: 100px;
              width: 100px;
            }
            h4 {
              font-size: 12px;
              min-width: 90px;
            }
            &__icon {
              img {
                width: 32px;
                height: 32px;
              }
            }
            &__content {
              h4 {
                font-size: 12px;
              }
            }
          }
          .data1 {
            width: 80px;
          }
          .data2 {
            width: 134px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1090px) {
  .data-max-length-Username {
    max-width: 90px;
    //padding-right: 20px;
  }
  .team-member-details {
    &__heading {
      padding: 0 8px 12px 8px;
      h2 {
        font-size: 14px;
      }
      &__name {
        &__search {
          display: none;
        }
      }
      &__content {
        h5 {
          font-size: 14px;
        }
      }
      &__buttons {
        button {
          padding: 4px 8px;
          img {
            width: 12px;
            height: 12px;
          }
          span {
            font-size: 12px;
          }
        }
      }
    }
    &__section {
      &__heading {
        padding: 0 8px 12px 8px;
        &__sec {
          min-width: 120px;
          width: 120px;
          &:nth-child(2) {
            min-width: 90px;
            width: 90px;
          }
          &:last-child {
            min-width: 70px;
            width: 70px;
          }
          h5 {
            font-size: 12px;
          }
        }
      }
      &__list {
        padding: 12px 8px 0 8px;
        &__sec {
          &__data {
            min-width: 120px;
            width: 120px;
            &:nth-child(2) {
              min-width: 90px;
              width: 90px;
            }
            &:last-child {
              min-width: 70px;
              width: 70px;
            }
            h4 {
              font-size: 12px;
              min-width: 90px;
            }
            &__icon {
              img {
                width: 32px;
                height: 32px;
              }
            }
            &__content {
              h4 {
                font-size: 12px;
              }
            }
            .status-icon {
              width: 32px;
            }
          }
          .data1 {
            width: 70px;
          }
          .data2 {
            width: 120px;
          }
        }
      }
    }
  }
}
