@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.new-members {
  background: #0000008a;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.5s forwards;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  &__container {
    width: 480px;
    background: $primary_white;
    border: 1px solid $primary_grey;
    border-radius: 8px;
  }
  &__heading {
    h2 {
      font-size: 18px;
      font-weight: 600;
    }
    p {
      font-size: 14px;
      color: #2ba471;
    }
  }
  .no-data {
    min-height: 360px;
    justify-content: space-between;
  }
  &__section {
    padding: 40px;
    @include flexcolumn;
    &__invite {
      &__name {
        padding: 25px 0;
        h4 {
          font-size: 14px;
          font-family: $inter_semibold;
          color: $primary_blue;
          padding: 0 0 12px 0;
        }
        &__search {
          position: relative;
          @include flexAcenter;
          img {
            position: absolute;
            left: 25px;
            width: 20px;
            height: 20px;
          }
          input {
            width: 100%;
            height: 40px;
            padding: 0 25px 0 63px;
            border-radius: 32px;
            border: 1px solid $primary_grey;
            font-size: 14px;
          }
        }
        &__list {
          max-height: 40vh;
          overflow: auto;
          padding: 0 20px;
          &__sec {
            background: $primary_grey;
            height: 60px;
            @include flexAcenter;
            justify-content: space-between;
            margin: 12px 0 0 0;
            padding: 0 24px;
            border-radius: 8px;
            &__left {
              @include flexAcenter;
              img {
                width: 36px;
                height: 36px;
              }
              &__sec {
                padding: 0 0 0 12px;
                h4 {
                  font-size: 16px;
                  font-weight: 600;
                  color: $font_grey;
                  padding: 0;
                }
                .data-max-length-Username{
                  max-width: 160px;
                  text-overflow : ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                }
                h5 {
                  font-size: 14px;
                  font-weight: 600;
                  color: $primary_blue;
                  padding: 2px 0 0 0;
                }
              }
            }
            &__right {
              button {
                min-width: 80px;
                padding: 6px 12px;
                border-radius: 15px;
                border: none;
                background: #000000;
                color: #ffffff;
                cursor: pointer;
                span {
                  font-size: 12px;
                }
              }
              button:hover {
                opacity: 0.7;
              }
              .member {
                background: transparent;
                color: $primary_blue;
                font-size: 14px;
                padding: 6px 12px;
              }
            }
          }
          &__nodata {
            @include flexJCenter;
            img {
              width: 80px;
            }
            p {
              padding: 12px 0 0 0;
              font-size: 14px;
            }
          }
        }
      }
      &__number {
        padding: 0 20px;
        &__search {
          width: 100%;
          h5 {
            font-size: 14px;
            font-family: $inter_semibold;
            color: $primary_blue;
            padding: 0 0 12px 0;
          }
          &__input {
            padding: 8px 0;
            &__inputs {
              @include flexrow;
              .react-tel-input {
                width: 120px;
                .form-control {
                  padding: 0 0 0 44px !important;
                }
                .flag-dropdown {
                  border-radius: 20px 0 0 20px !important;
                  border: 1px solid $primary_grey !important;
                  background: $primary_white !important;
                }
                .flag-dropdown.open .selected-flag {
                  border-radius: 20px 0 0 20px !important;
                }
                .selected-flag:hover,
                :focus {
                  border-radius: 20px 0 0 20px !important;
                }
                input {
                  width: 100%;
                  border-radius: 60px 0 0 60px !important;
                  border: 1px solid $primary_grey !important;
                  font-size: 14px;
                  color: $grey_input;
                  height: 100%;
                }
              }
              input {
                width: 100%;
                padding: 0 0 0 12px;
                border-radius: 0 $font_xxlarge $font_xxlarge 0;
                border: 1px solid $primary_grey;
                border-left: none;
                font-size: 14px;
                color: $primary_black;
                outline: none;
                height: 40px;
              }
              input::placeholder {
                color: $grey_input;
              }
            }
            .error-p {
              p {
                font-size: 14px;
              }
            }
            h6 {
              font-size: 14px;
              color: $primary_blue;
              padding: 8px 0 0 0;
              font-weight: 400;
            }
          }
        }
        &__buttons {
          padding: 48px 0 0 0;
          @include flexJEnd;
          &__main {
            width: 160px;
            @include flexbetween;
          }
        }
      }
    }
    &__buttons {
      @include flexJEnd;
    }
  }
}

@media screen and (max-width: 1250px) {
  .new-members {
    &__container {
      width: 386px;
    }
    &__heading {
      h2 {
        font-size: 16px;
      }
      p {
        font-size: 14px;
      }
    }
    &__section {
      padding: 16px 32px;
      &__invite {
        &__name {
          padding: 16px 0;
          h4 {
            font-size: 12px;
          }
          &__search {
            input {
              height: 36px;
              padding: 0 16px 0 42px;
              font-size: 12px;
            }
            img {
              width: 16px;
              height: 16px;
              left: 16px;
            }
          }
          &__list {
            max-height: 35vh;
            padding: 0 12px;
            &__sec {
              height: 56px;
              padding: 0 16px;
              margin: 8px 0 0 0;
              &__left {
                img {
                  width: 32px;
                  height: 32px;
                }
                &__sec {
                  h4 {
                    font-size: 14px;
                  }
                  .data-max-length-Username{
                    max-width: 140px;
                  }
                  h5 {
                    font-size: 12px;
                  }
                }
              }
              &__right {
                button {
                  min-width: 64px;
                  padding: 4px 8px;
                  span {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
        &__number {
          padding: 0 12px;
          &__search {
            h5 {
              font-size: 12px;
            }
            &__input {
              &__inputs {
                input {
                  font-size: 12px;
                }
              }
            }
          }
          &__buttons {
            padding: 32px 0 0 0;
            &__main {
              width: 132px;
            }
          }
        }
      }
    }
  }
}
