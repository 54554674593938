@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.subscription {
  @include flexColumnACenter;
  &_container {
    width: 100%;
    height: calc(100vh - 100px);
    overflow: auto;
    @include flexJCenter;
  }
  &_sections {
    width: calc(100% - 40px);
    padding: 32px 0;
    @include flexcolumn;
    gap: 40px;
    max-width: 480px;
    p {
      font-size: 14px;
      color: #ff4d4f;
    }
  }
  &_heading {
    @include flexrowacenter;
    gap: 8px;
    img {
      width: 30px;
      height: 30px;
      border: 1px solid #d5d5d5;
      border-radius: 50%;
    }
    h4 {
      font-size: 14px;
      color: $primary_white;
      font-weight: 400;
    }
  }
  &_addons {
    &_list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 8px;
    }
    &_sec {
      background: white;
      border: 1px solid #ededed;
      padding: 16px 8px 16px 8px;
      border-radius: 8px;
      &.active {
        background: #d4d6d7;
      }
      &_data {
        text-align: center;
        @include flexColumnACenter;
        justify-content: space-between;
        height: 100%;
        gap: 40px;
        h4 {
          font-size: 16px;
          font-weight: 600;
        }
        button {
          border: 1px solid #ededed;
          color: $primary_white;
          background: $primary_blue;
          border-radius: 42px;
          min-width: 60px;
          height: 26px;
          font-size: 12px;
          @include flexCenter;
          cursor: pointer;
          &:hover {
            background: $onhover_grey;
          }
          &:active {
            background: $primary_white;
            color: $primary_blue;
          }
          &.active {
            background: $primary_white;
            color: $primary_blue;
          }
        }
      }
    }
  }
  &_redeem {
    @include flexcolumn;
    gap: 12px;
    &_section {
      @include flexrow;
      gap: 15px;
    }
    &_text {
      width: 80px;
      h5 {
        font-size: 14px;
        font-weight: 500;
        color: $primary_white;
      }
    }
    &_input {
      width: calc(100% - 109px);
      input {
        width: 100%;
        font-size: 12px;
        padding: 12px 0 12px 12px;
        border-radius: 42px;
        border: 1px solid #ededed;
      }
    }
    p {
      font-size: 14px;
      color: #ff4d4f;
    }
  }
  &_admin {
    @include flexcolumn;
    gap: 12px;
    &_tip {
      h3 {
        font-size: 14px;
        font-weight: 600;
        color: #e37318;
      }
    }
    &_section {
      @include flexrow;
      gap: 15px;
    }
    &_text {
      width: 80px;
      h5 {
        font-size: 14px;
        font-weight: 500;
        color: $primary_white;
      }
    }
    &_input {
      width: calc(100% - 109px);
      input {
        width: 100%;
        font-size: 12px;
        padding: 12px 0 12px 12px;
        border-radius: 42px;
        border: 1px solid #ededed;
      }
    }
    p {
      font-size: 14px;
      color: #ff4d4f;
    }
  }
  &_submit {
    @include flexcolumn;
    gap: 8px;
    p {
      font-size: 14px;
      color: #ff4d4f;
    }
    button {
      border: 1px solid #ededed;
      color: $primary_white;
      background: $primary_blue;
      border-radius: 42px;
      width: 100%;
      height: 36px;
      font-size: 12px;
      @include flexCenter;
      cursor: pointer;
      &:hover {
        background: $onhover_grey;
      }
      &:active {
        background: $primary_white;
        color: $primary_blue;
      }
      &.disable {
        cursor: default;
        color: $primary_blue;
        background: #d4d6d7;
      }
    }
  }
  &_success {
    padding: 120px 0 0 0;
    @include flexColumnACenter;
    gap: 40px;
    img {
      width: 64px;
      height: 64px;
    }
    p {
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      color: $primary_white;
    }
    button {
      border: 1px solid #ededed;
      color: $primary_white;
      background: $primary_blue;
      border-radius: 42px;
      width: 100%;
      height: 36px;
      font-size: 12px;
      @include flexCenter;
      &:hover {
        background: $onhover_grey;
      }
      &:active {
        background: $primary_white;
        color: $primary_blue;
      }
    }
  }
}
