@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.faq-airi {
  @include flexJCenter;
  align-items: flex-end;
  background: $bg_light_grey;
  &__container {
    @include flexJCenter;
  }

  &__sections {
    width: 952px;
    padding: 32px 0 0 0;
    .gif {
      width: 100%;
      min-height: calc(var(--vh, 1vh) * 100 - 48px);
      @include flexCenter;
      img {
        width: 120px;
      }
      p {
        font-size: 12px;
      }
    }
    .react-tabs {
      @include flexColumnACenter;
      &__tab-list {
        //   width: 290px;
        border: 1px solid $primary_grey !important;
        margin: 0 !important;
        padding: 4px !important;
        border-radius: 23px;
        display: flex !important;
        justify-content: space-between !important;
        margin: 0 0 12px 0 !important;
        gap: 40px;
      }
      &__tab:focus:after {
        display: none !important;
      }
      &__tab {
        bottom: 0;
        font-size: 18px;
      }
      &__tab-panel {
        width: 100%;
        margin: 40px 0;
      }
      &__tab--selected {
        border: none !important;
        background: $primary_blue !important;
        border-radius: 20px;
        color: $primary_white !important;
      }
    }
    &__expantion {
      @include flexcolumn;
      &__heading {
        padding: 20px 0 12px 8px;
        h3 {
          font-size: 22px;
          font-weight: 500;
          color: $primary_black;
        }
      }
    }
  }
}
.expand-panel {
  @include flexcolumn;
  background: $primary_white;
  border: 1px solid $primary_grey;
  border-radius: 8px;
  margin: 0 0 6px 0;
  padding: 0 16px;
  &__heading {
    height: 47px;
    @include flexbetween;
    align-items: center;
    cursor: pointer;
    h4 {
      font-size: 14px;
      color: $primary_black;
      font-weight: 400;
    }
    img {
        width: 16px;
        height: 16px;
    }
  }
  &__content {
    padding: 0 0 16px 0;
    h5 {
      font-size: 13px;
      font-weight: 400;
      color: $font_grey;
      ul {
        li {
          font-size: 13px;
        }
      }
    }
  }
}


@media screen and (min-width: 600px) and (max-width: 767px) {
  .faq-airi { 
    &__sections {
      width: 82vw;
      padding: 24px 0 0 0;
      .react-tabs {
        &__tab {
          font-size: 12px;
        }
      }
      &__expantion {
        &__heading  {
          padding: 12px 0 8px 6px;
          h3 {
            font-size: 16px;
          }
        }
      }
    }
  }
  .expand-panel {
    padding: 0 12px;
    border-radius: 6px;
    &__heading {
      height: 37px;
      h4 {
        font-size: 12px;
      }
      img  {
        width: 12px;
        height: 12px; 
      }
    }
    &__content {
      h5 {
        font-size: 11px;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1090px) {
  .faq-airi {
    &__sections {
      width: 650px;
      padding: 24px 0 0 0;
      .react-tabs {
        &__tab {
          font-size: 12px;
        }
      }
      &__expantion {
        &__heading  {
          padding: 12px 0 8px 6px;
          h3 {
            font-size: 16px;
          }
        }
      }
    }
  }
  .expand-panel {
    padding: 0 12px;
    border-radius: 6px;
    &__heading {
      height: 37px;
      h4 {
        font-size: 12px;
      }
      img  {
        width: 12px;
        height: 12px; 
      }
    }
    &__content {
      h5 {
        font-size: 11px;
      }
    }
  }
}